import React, { useState, useEffect } from 'react';
import { TextField, Grid, Button, InputAdornment } from '@mui/material';
import { Assignment, CalendarToday } from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

const AddLeaveSchedule = () => {
  const [reasonForLeave, setReasonForLeave] = useState('');
  const [leaveFromDate, setLeaveFromDate] = useState('');
  const [leaveToDate, setLeaveToDate] = useState('');
  const [totalLeaveDays, setTotalLeaveDays] = useState(0);
  const [notes, setNotes] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Calculate total leave days when leaveFromDate or leaveToDate changes
  useEffect(() => {
    if (leaveFromDate && leaveToDate) {
      const fromDate = new Date(leaveFromDate);
      const toDate = new Date(leaveToDate);
      const diffTime = Math.abs(toDate - fromDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) + 1; // +1 to include both start and end dates
      setTotalLeaveDays(diffDays);
    } else {
      setTotalLeaveDays(0);
    }
  }, [leaveFromDate, leaveToDate]);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setFormErrors((prev) => ({ ...prev, [event.target.name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!reasonForLeave) errors.reasonForLeave = 'Reason for leave is required';
    if (!leaveFromDate) errors.leaveFromDate = 'Leave from date is required';
    if (!leaveToDate) errors.leaveToDate = 'Leave to date is required';

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log({
        reasonForLeave,
        leaveFromDate,
        leaveToDate,
        totalLeaveDays,
        notes,
      });

      handleReset();
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setReasonForLeave('');
    setLeaveFromDate('');
    setLeaveToDate('');
    setTotalLeaveDays(0);
    setNotes('');
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add New Request</h2>
      </div>
      <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
        <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
          <CalendarToday className="text-customGreen mr-2" /> Leave Info:
        </h4>

        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Grid container spacing={2}>
            {/* Reason for Leave */}
            <Grid item xs={12}>
              <TextField
                label="Reason for Leave"
                variant="outlined"
                fullWidth
                name="reasonForLeave"
                value={reasonForLeave}
                onChange={handleInputChange(setReasonForLeave)}
                error={!!formErrors.reasonForLeave}
                helperText={formErrors.reasonForLeave}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Assignment className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>

            {/* Leave From Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Leave From Date"
                type="date"
                variant="outlined"
                fullWidth
                name="leaveFromDate"
                value={leaveFromDate}
                onChange={handleInputChange(setLeaveFromDate)}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.leaveFromDate}
                helperText={formErrors.leaveFromDate}
                className="mt-1"
              />
            </Grid>

            {/* Leave To Date */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Leave To Date"
                type="date"
                variant="outlined"
                fullWidth
                name="leaveToDate"
                value={leaveToDate}
                onChange={handleInputChange(setLeaveToDate)}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.leaveToDate}
                helperText={formErrors.leaveToDate}
                className="mt-1"
              />
            </Grid>

            {/* Total Leave Days */}
            <Grid item xs={12}>
              <TextField
                label="Total Leave Days"
                variant="outlined"
                fullWidth
                value={totalLeaveDays}
                InputProps={{
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <Assignment className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>

            {/* Notes */}
            <Grid item xs={12}>
              <TextField
                label="Notes"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                name="notes"
                value={notes}
                onChange={handleInputChange(setNotes)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Assignment className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>

            {/* Submit and Reset buttons */}
            <Grid item xs={12} container spacing={2} justifyContent="center">
              <Grid item>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className="px-4 py-2"
                >
                  Submit
                </Button>
              </Grid>
              <Grid item>
                <Button
                  type="reset"
                  variant="outlined"
                  className="px-4 py-2"
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>

      {/* Snackbar Notification */}
      <Notification
        open={openSnackbar}
        message="Form submitted successfully!"
        severity="success"
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default AddLeaveSchedule;
