import React, { useState, useEffect } from 'react';
import { Modal, Box, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import { categories } from '../../services/data';
import { flattenCategories } from '../../utils/categoryUtils';

function CategoryModal({ isOpen, handleClose }) {
  const [categoryName, setCategoryName] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [parentCategories, setParentCategories] = useState([]);

  useEffect(() => {
    const loadParentCategories = () => {
      const flatCategories = flattenCategories(categories);
      setParentCategories(flatCategories);
    };

    if (isOpen) {
      loadParentCategories();
    }
  }, [isOpen]);

  const handleSave = () => {
    // Add your save logic here
    console.log("New Category:", categoryName, "Parent Category:", parentCategory);
    // Close the modal after saving
    handleClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="add-category-modal-title"
      aria-describedby="add-category-modal-description"
    >
      <Box 
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <h2 id="add-category-modal-title" className="text-xl font-bold mb-4">Add New Category</h2>
        <TextField
          label="Category Name"
          variant="outlined"
          fullWidth
          margin="normal"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
        />
        <FormControl fullWidth margin="normal">
          <InputLabel id="parent-category-label">Parent Category</InputLabel>
          <Select
            labelId="parent-category-label"
            value={parentCategory}
            onChange={(e) => setParentCategory(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'Parent Category' }}
          >
            <MenuItem value="">
              <em> </em>
            </MenuItem>
            {parentCategories.map((cat) => (
              <MenuItem key={cat.id} value={cat.id}>
                {cat.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className="flex justify-end mt-4">
          <Button 
            variant="contained" 
            color="primary"
            onClick={handleSave}
          >
            Save
          </Button>
        </div>
      </Box>
    </Modal>
  );
}

export default CategoryModal;
