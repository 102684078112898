import React, { useState } from 'react';

function EmployeeProfile() {
  const [employee, setEmployee] = useState({
    id: '1',
    empId: 'EMP123',
    name: 'John Doe',
    mobile1: '123-456-7890',
    mobile2: '098-765-4321',
    dob: '1990-01-01',
    gender: 'Male',
    bloodGroup: 'O+',
    maritalStatus: 'Single',
    nid: '1234567890123',
    country: 'USA',
    role: 'Developer',
    dept: 'IT',
    location: 'New York',
  });

  const [profilePhoto, setProfilePhoto] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  const handlePhotoUpload = (e) => {
    setProfilePhoto(URL.createObjectURL(e.target.files[0]));
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setEmployee((prevEmployee) => ({
      ...prevEmployee,
      [id]: value,
    }));
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleUpdateClick = () => {
    setIsEditing(false);
    console.log('Updated employee data:', employee);
  };

  const printProfile = () => {
    const printWindow = window.open('', '', 'height=600,width=800');
    const profileHtml = `
      <html>
      <head>
        <title>Print Profile</title>
        <style>
          body { font-family: Arial, sans-serif; }
          .profile-container { padding: 20px; }
          .profile-photo { float: left; margin-right: 20px; }
          .profile-info { overflow: hidden; }
          .profile-info label { display: block; font-weight: bold; margin-top: 10px; }
          .profile-info input { border: 1px solid #ddd; padding: 8px; width: 100%; box-sizing: border-box; }
        </style>
      </head>
      <body>
        <div class="profile-container">
          <div class="profile-photo">
            <img src="${profilePhoto || 'https://via.placeholder.com/150'}" alt="Profile Photo" style="width: 150px; height: 150px; border-radius: 50%; border: 1px solid #ddd;" />
          </div>
          <div class="profile-info">
            ${Object.entries(employee).map(([key, value]) => `
              <div>
                <label for="${key}">${key.replace(/([A-Z])/g, ' $1')}</label>
                <input type="${key === 'dob' ? 'date' : 'text'}" id="${key}" value="${value}" readonly />
              </div>
            `).join('')}
          </div>
        </div>
      </body>
      </html>
    `;

    printWindow.document.write(profileHtml);
    printWindow.document.close();
    printWindow.focus();
    printWindow.print();
  };

  return (
    <div className="employee-profile p-4">
      <header className="flex items-center justify-between mb-4 p-4 bg-gray-100 border-b border-gray-200">
        <h2 className="text-xl font-bold">Employee Profile</h2>
        <div className="flex space-x-2">
          {!isEditing ? (
            <button
              className="p-2 bg-blue-500 text-white rounded-md flex items-center"
              onClick={handleEditClick}
            >
              <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 6h1v6h-1zm0 8h1v1h-1zm2 0h-1v-1h1zm-2-8h1v6h-1zm2 6h-1v1h1zm-2-7h1v6h-1zm2 7h-1v-1h1zm-2-7h1v6h-1z"></path>
              </svg>
              Edit
            </button>
          ) : (
            <button
              className="p-2 bg-green-500 text-white rounded-md flex items-center"
              onClick={handleUpdateClick}
            >
              <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6h6m-6 6v-6m0 0h6m-6 0H6"></path>
              </svg>
              Update
            </button>
          )}
          <button
            className="p-2 bg-red-500 text-white rounded-md flex items-center"
            // Implement delete functionality if needed
          >
            <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
            Delete
          </button>
          <button
            className="p-2 bg-green-500 text-white rounded-md flex items-center"
            onClick={printProfile}
          >
            <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10h3a2 2 0 01.2 3.998H15a2 2 0 010-3.998zM5 10h3a2 2 0 01.2 3.998H5a2 2 0 010-3.998zM5 17h14a2 2 0 01.2 3.998H5a2 2 0 010-3.998z"></path>
            </svg>
            Print
          </button>
          <button className="p-2 bg-yellow-500 text-white rounded-md flex items-center">
            <svg className="w-5 h-5 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 10h3a2 2 0 01.2 3.998H15a2 2 0 010-3.998zM5 10h3a2 2 0 01.2 3.998H5a2 2 0 010-3.998zM5 17h14a2 2 0 01.2 3.998H5a2 2 0 010-3.998z"></path>
            </svg>
            Share
          </button>
        </div>
      </header>

      <div className="flex">
        {/* Profile Photo Section */}
        <div className="flex-none w-1/3 p-4">
          <div className="relative">
            <img
              src={profilePhoto || 'https://via.placeholder.com/150'}
              alt="Profile"
              className="w-full h-auto rounded-full border border-gray-300"
            />
            <input
              type="file"
              accept="image/*"
              className="absolute inset-0 opacity-0 cursor-pointer"
              onChange={handlePhotoUpload}
            />
          </div>
          <p className="mt-2 text-sm text-gray-600 text-center">Click to upload photo</p>
        </div>

        {/* Profile Information Section */}
        <div className="flex-1 p-4">
          <div className="grid grid-cols-2 gap-4">
            {Object.entries(employee).map(([key, value]) => (
              <div key={key}>
                <label htmlFor={key} className="block text-gray-700 capitalize">
                  {key.replace(/([A-Z])/g, ' $1')}
                </label>
                <input
                  type={key === 'dob' ? 'date' : 'text'}
                  id={key}
                  value={value}
                  onChange={isEditing ? handleChange : undefined}
                  readOnly={!isEditing}
                  className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring focus:ring-opacity-50 focus:ring-green-500"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EmployeeProfile;
