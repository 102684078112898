import React, { useState } from 'react';
import { TextField, Button, InputAdornment, List, ListItem, Paper, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Search, Refresh } from '@mui/icons-material';

const OrderSearchFilter = ({ onFilterChange }) => {
  const [searchKey, setSearchKey] = useState('');
  const [searchType, setSearchType] = useState('');
  const [status, setStatus] = useState('');
  const [customersData, setCustomersData] = useState([]); // Added state variable
  const [filteredSearchKeys, setFilteredSearchKeys] = useState([]);
  const [filteredStatuses, setFilteredStatuses] = useState([]);

  // Sample demo data for suggestions and statuses
  const searchKeySuggestions = ['John Doe', 'Jane Smith', 'Product XYZ', 'Order 1234'];
  const statusOptions = ['Pending', 'Shipped', 'Delivered', 'Cancelled'];

  // Handle the search action
  const handleSearch = async () => {
    // Fetch customer data based on filters
    const fetchedCustomers = await fetchCustomersData({ searchKey, searchType, status });
    setCustomersData(fetchedCustomers); // Use setCustomersData here

    onFilterChange({
      searchKey,
      searchType,
      status,
    });
  };

  // Simulate fetching customer data
  const fetchCustomersData = async (filters) => {
    // Replace with actual API call or data fetching logic
    // For demo purposes, we return static data
    return [
      { id: '1', name: 'John Doe', phone: '555-1234', address: '123 Elm Street' },
      { id: '2', name: 'Jane Smith', phone: '555-5678', address: '456 Oak Avenue' }
    ];
  };

  // Handle clearing the filters
  const handleClearFilters = () => {
    setSearchKey('');
    setSearchType('');
    setStatus('');
    setFilteredSearchKeys([]);
    setFilteredStatuses([]);
    setCustomersData([]); // Clear customers data
    onFilterChange({
      searchKey: '',
      searchType: '',
      status: '',
    });
  };

  // Handle search key typing and suggestion filtering
  const handleSearchKeyChange = (e) => {
    const value = e.target.value;
    setSearchKey(value);
    if (value) {
      const filtered = searchKeySuggestions.filter((item) =>
        item.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredSearchKeys(filtered);
    } else {
      setFilteredSearchKeys([]);
    }
  };

  // Handle search type change
  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  // Handle status change
  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  return (
    <div className="mb-6 pb-4 border-b-2 border-gray-300">
      {/* Title and Icon */}
      <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
        <Search className="text-customGreen mr-2" /> Search Orders:
      </h4>

      {/* Filters Section */}
      <div className="flex flex-wrap gap-4">
        {/* Search Type Dropdown */}
        <FormControl variant="outlined" size="small" className="w-full sm:w-1/4">
          <InputLabel>Search Type</InputLabel>
          <Select
            value={searchType}
            onChange={handleSearchTypeChange}
            label="Search Type"
          >
            <MenuItem value="order">Order</MenuItem>
            <MenuItem value="product">Product</MenuItem>
            <MenuItem value="customer">Customer</MenuItem>
          </Select>
        </FormControl>

        {/* Search Key Input with suggestions */}
        <div className="relative w-full sm:w-1/4">
          <TextField
            label="Search Key"
            variant="outlined"
            size="small"
            value={searchKey}
            onChange={handleSearchKeyChange}
            placeholder="Enter search term"
            className="w-full"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          {filteredSearchKeys.length > 0 && (
            <Paper className="absolute z-10 w-full mt-1 max-h-60 overflow-y-auto">
              <List>
                {filteredSearchKeys.map((item, index) => (
                  <ListItem key={index} button onClick={() => { setSearchKey(item); setFilteredSearchKeys([]); }}>
                    {item}
                  </ListItem>
                ))}
              </List>
            </Paper>
          )}
        </div>

        {/* Status Dropdown */}
        <FormControl variant="outlined" size="small" className="w-full sm:w-1/4">
          <InputLabel>Status</InputLabel>
          <Select
            value={status}
            onChange={handleStatusChange}
            label="Status"
          >
            {statusOptions.map((option, index) => (
              <MenuItem key={index} value={option}>{option}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Search and Clear Buttons */}
        <div className="flex flex-col sm:flex-row sm:gap-4 mt-4 sm:mt-0">
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            startIcon={<Search />}
            className="w-full sm:w-auto"
          >
            Search
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            onClick={handleClearFilters}
            startIcon={<Refresh />}
            className="w-full sm:w-auto mt-2 sm:mt-0"
          >
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderSearchFilter;
