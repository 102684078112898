import React, { useState } from 'react';
import { ExpandMore as ExpandMoreIcon, ExpandLess as ExpandLessIcon, Visibility as VisibilityIcon, Edit as EditIcon, Delete as DeleteIcon, Add as AddIcon } from '@mui/icons-material';
import { IconButton, MenuItem as MuiMenuItem, Select, TextField } from '@mui/material';
import CategoryModal from './CategoryModal';


function Categories() {
  const [searchTerm, setSearchTerm] = useState('');
  const [expanded, setExpanded] = useState({});
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [anchorEl, setAnchorEl] = useState(null);

  // State to manage the modal visibility
  const [isModalOpen, setIsModalOpen] = useState(false);
  const categories = [
    { id: 1, name: 'Medicine', count: 0 },
    { id: 2, name: 'Supplier', count: 1, subcategories: [
      { id: 3, name: 'Sub Category', count: 1, subcategories: [
        { id: 4, name: 'Brand', count: 1, subcategories: [
          { id: 5, name: 'Sub Brand', count: 0 }
        ]}
      ]}
    ]},
    { id: 6, name: 'Product Category', count: 0, subcategories: [
      { id: 7, name: 'Product Type', count: 8 }
    ]}
    // Your category data goes here
  ];


  
  const toggleSubcategories = (id) => {
    setExpanded(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const sortedCategories = categories
    .filter(item => item.name.toLowerCase().includes(searchTerm))
    .sort((a, b) => {
      if (sortConfig.direction === 'asc') {
        return a[sortConfig.key] > b[sortConfig.key] ? 1 : -1;
      }
      if (sortConfig.direction === 'desc') {
        return a[sortConfig.key] < b[sortConfig.key] ? 1 : -1;
      }
      return 0;
    });

      
  // Function to open the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="container mt-5">
      <div className="panel ">
        <div className="flex justify-between mb-4">
          <h4 className="text-2xl font-semibold text-gray-800">Product Categories</h4>
         {/* Action Buttons */}
         <div className="flex space-x-4">
            {/* Button for Adding New Product */}
            <button 
              className="btn btn-success px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
              onClick={handleOpenModal}
            >
              <AddIcon className="mr-2" /> Add Category
            </button>
          </div>
        </div>

        {/* Search and Pagination Controls */}
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-gray-500">
            <span>Showing </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="w-15"
              size="small"
            >
              <MuiMenuItem value={5}>5</MuiMenuItem>
              <MuiMenuItem value={10}>10</MuiMenuItem>
              <MuiMenuItem value={25}>25</MuiMenuItem>
              <MuiMenuItem value={50}>50</MuiMenuItem>
            </Select>
            <span> entries</span>
          </div>
          <div className="flex items-center space-x-4">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearch}
              className="w-3/3"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('name')}
                >
                  Category Name
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'name' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'name' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('id')}
                >
                  Row ID
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'id' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'id' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('count')}
                >
                  Count
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'count' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'count' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedCategories.map(item => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                      {expanded[item.id] ? (
                        <ExpandLessIcon
                          className="mr-2 cursor-pointer text-customGreen"
                          onClick={() => toggleSubcategories(item.id)}
                        />
                      ) : (
                        <ExpandMoreIcon
                          className="mr-2 cursor-pointer text-customGreen"
                          onClick={() => toggleSubcategories(item.id)}
                        />
                      )}
                      {item.name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{item.id}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{item.count}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                      <IconButton>
                        <VisibilityIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                      </IconButton>
                      <IconButton>
                        <EditIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                      </IconButton>
                      <IconButton>
                        <DeleteIcon className="cursor-pointer text-gray-600 hover:text-gray-900" />
                      </IconButton>
                    </td>
                  </tr>
                  {item.subcategories && expanded[item.id] && item.subcategories.map(sub => (
                    <React.Fragment key={sub.id}>
                      <tr>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 pl-10">
                          {expanded[sub.id] ? (
                            <ExpandLessIcon
                              className="mr-2 cursor-pointer text-orange-500"
                              onClick={() => toggleSubcategories(sub.id)}
                            />
                          ) : (
                            <ExpandMoreIcon
                              className="mr-2 cursor-pointer text-orange-500"
                              onClick={() => toggleSubcategories(sub.id)}
                            />
                          )}
                          {sub.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{sub.id}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{sub.count}</td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                          <IconButton>
                            <VisibilityIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                          </IconButton>
                          <IconButton>
                            <EditIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                          </IconButton>
                          <IconButton>
                            <DeleteIcon className="cursor-pointer text-gray-600 hover:text-gray-900" />
                          </IconButton>
                        </td>
                      </tr>
                      {sub.subcategories && expanded[sub.id] && sub.subcategories.map(subSub => (
                        <tr key={subSub.id}>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 pl-20">
                            {expanded[subSub.id] ? (
                              <ExpandLessIcon
                                className="mr-2 cursor-pointer text-orange-500"
                                onClick={() => toggleSubcategories(subSub.id)}
                              />
                            ) : (
                              <ExpandMoreIcon
                                className="mr-2 cursor-pointer text-orange-500"
                                onClick={() => toggleSubcategories(subSub.id)}
                              />
                            )}
                            {subSub.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{subSub.id}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{subSub.count}</td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                            <IconButton>
                              <VisibilityIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                            </IconButton>
                            <IconButton>
                              <EditIcon className="mr-2 cursor-pointer text-gray-600 hover:text-gray-900" />
                            </IconButton>
                            <IconButton>
                              <DeleteIcon className="cursor-pointer text-gray-600 hover:text-gray-900" />
                            </IconButton>
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {/* Render the CategoryModal component */}
      <CategoryModal 
        isOpen={isModalOpen} 
        handleClose={handleCloseModal} 
      />
    </div>
  );
}

export default Categories;
