import React, { useState } from 'react';
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Snackbar,
  Alert
} from '@mui/material';
import {
  AccountCircle,
  Description,
  AttachFile,
  Delete,
  DocumentScanner
} from '@mui/icons-material';

function AddPrescription() {
  const [formValues, setFormValues] = useState({
    patientName: '',
    remarks: ''
  });
  const [documents, setDocuments] = useState([]);
  const [formErrors, setFormErrors] = useState({});
  const [fileErrors, setFileErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const MAX_FILE_SIZE_MB = 1;
  const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

  const handleAddDocument = () => {
    setDocuments([...documents, { file: null, error: '' }]);
  };

  const handleRemoveDocument = (index) => {
    setDocuments(documents.filter((_, i) => i !== index));
  };

  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    const newDocuments = [...documents];
    
    if (file) {
      if (file.size > MAX_FILE_SIZE_BYTES) {
        newDocuments[index] = { file: null, error: `File size exceeds ${MAX_FILE_SIZE_MB} MB.` };
        setSnackbarMessage(`File size exceeds ${MAX_FILE_SIZE_MB} MB.`);
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } else if (['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        newDocuments[index] = { file, error: '' };
      } else {
        newDocuments[index] = { file: null, error: 'Invalid file type. Please upload PDF, JPEG, JPG, or PNG.' };
      }
    } else {
      newDocuments[index] = { file: null, error: '' };
    }
    
    setDocuments(newDocuments);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let errors = {};
    if (!formValues.patientName.trim()) {
      errors.patientName = 'Patient name is required';
    }
    if (!formValues.remarks.trim()) {
      errors.remarks = 'Remarks are required';
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const validateFiles = () => {
    let fileErrors = {};
    documents.forEach((doc, index) => {
      if (doc.file && doc.error) {
        fileErrors[index] = doc.error;
      }
    });
    setFileErrors(fileErrors);
    return Object.keys(fileErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm() && validateFiles()) {
      console.log('Form submitted', { ...formValues, documents: documents.map(doc => doc.file) });

      // Clear the form
      handleReset();

      // Show success notification
      setSnackbarMessage('Form submitted successfully!');
      setSnackbarSeverity('success');
      setOpenSnackbar(true);
    } else {
      setSnackbarMessage('Please fix the errors in the form.');
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setFormValues({ patientName: '', remarks: '' });
    setDocuments([]);
    setFormErrors({});
    setFileErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Prescription Details</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="flex flex-col lg:flex-row gap-6">
              {/* Patient Info Section */}
              <div className="w-full lg:w-1/2">
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                  <AccountCircle className="text-customGreen mr-2" /> Patient Info:
                </h4>
                <div className="space-y-4">
                  <TextField
                    label="Patient Name"
                    variant="outlined"
                    fullWidth
                    name="patientName"
                    value={formValues.patientName}
                    onChange={handleInputChange}
                    error={!!formErrors.patientName}
                    helperText={formErrors.patientName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1"
                  />
                  
                  <TextField
                    label="Remarks"
                    variant="outlined"
                    multiline
                    rows={2}
                    fullWidth
                    name="remarks"
                    value={formValues.remarks}
                    onChange={handleInputChange}
                    error={!!formErrors.remarks}
                    helperText={formErrors.remarks}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Description className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1"
                  />
                </div>
              </div>

              {/* Documents Upload Section */}
              <div className="w-full lg:w-1/2">
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                  <DocumentScanner className="text-customGreen mr-2" /> Documents Upload:
                </h4>
                <div className="space-y-4">
                  {documents.map((doc, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      <input
                        type="file"
                        accept=".pdf, image/jpeg, image/jpg, image/png"
                        onChange={(event) => handleFileChange(event, index)}
                        className="mr-2"
                      />
                      {doc.file && !doc.error && <span className="text-gray-600"></span>}
                      {doc.error && <span className="text-red-600">{doc.error}</span>}
                      <IconButton
                        onClick={() => handleRemoveDocument(index)}
                        className="text-red-500"
                      >
                        <Delete />
                      </IconButton>
                    </div>
                  ))}
                  <Button
                    onClick={handleAddDocument}
                    variant="outlined"
                    startIcon={<AttachFile />}
                    className="mt-2"
                  >
                    Add Document
                  </Button>
                </div>
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex gap-4 mt-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Snackbar Notification */}
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddPrescription;
