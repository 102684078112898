import { Typography } from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import EventIcon from '@mui/icons-material/Event';
import { AccountCircle } from '@mui/icons-material';

function UserInfoSection() {
  return (
    <div className="w-full md:w-1/2 p-6 bg-gray-100 rounded-lg">
      <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
        <AccountCircle className="text-customGreen mr-2" /> User Information:
      </h4>
      <div className="bg-white p-6 shadow-md rounded-lg">
        {/* User Information Grid */}
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          {/* User ID */}
          <div className="flex items-center">
            <PersonIcon className="text-gray-600 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">User ID:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800 text-right">USR12345</Typography>

          {/* Login Status */}
          <div className="flex items-center">
            <VerifiedUserIcon className="text-green-600 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Login Status:</Typography>
          </div>
          <Typography variant="body2" className="text-green-700 text-right">Active</Typography>

          {/* Login Type */}
          <div className="flex items-center">
            <LockOpenIcon className="text-blue-600 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Login Type:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800 text-right">Single Sign-On</Typography>

          {/* Expiry Date */}
          <div className="flex items-center">
            <EventIcon className="text-orange-600 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Expiry Date:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800 text-right">2024-01-15</Typography>
        </div>
      </div>
    </div>
  );
}

export default UserInfoSection;
