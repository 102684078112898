import React from 'react';
import EditBtn from '../../components/common/EditBtn';

function PromoCategoryList() {
    const categories = [
        { id: 1, name: 'Inventory Manager', description: 'Responsible for managing stock levels and ensuring availability of products.', count: 15 },
        { id: 2, name: 'Quality Assurance Manager', description: 'Ensures that all medicines meet quality standards and regulatory requirements.', count: 10 },
        { id: 3, name: 'Logistics Coordinator', description: 'Manages the distribution and delivery of medicines to various locations.', count: 25 },
        { id: 4, name: 'Customer Service Representative', description: 'Provides support and assistance to customers regarding their orders and inquiries.', count: 8 },
        { id: 5, name: 'Marketing Manager', description: 'Develops and implements marketing strategies to promote the dealership\'s medicines.', count: 12 },
        { id: 6, name: 'Account Manager', description: 'Manages relationships with key accounts and clients.', count: 6 },
        { id: 7, name: 'Financial Analyst', description: 'Analyzes financial data and provides insights for decision-making.', count: 5 },
        // Add more categories as needed
    ];

    return (
        <>
            <h2 className="text-xl font-bold mb-4">List of Promo Categories</h2>
            <table className="min-w-full bg-white">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Name</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Description</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Count</th>
                        <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Action</th>
                    </tr>
                </thead>
                <tbody>
                    {categories.map((category) => (
                        <tr key={category.id} className='hover:bg-gray-100'>
                            <td className="py-2 px-4 border-b border-gray-300">{category.name}</td>
                            <td className="py-2 px-4 border-b border-gray-300">{category.description}</td>
                            <td className="py-2 px-4 border-b border-gray-300">{category.count}</td>
                            <td className='py-2 px-4 border-b border-gray-300'>
                                <EditBtn Name="Edit" Path="#" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default PromoCategoryList;
