import React, { useState } from 'react';
import { Visibility, Cancel, CheckBox, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const Leave = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'leaveFromDate', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentLeave, setCurrentLeave] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const leaves = [
        { id: 1, employeeName: 'John Doe', reason: 'Medical', leaveFromDate: '2024-08-10', leaveToDate: '2024-08-15', totalDays: 5, status: 'Pending' },
        { id: 2, employeeName: 'Jane Smith', reason: 'Vacation', leaveFromDate: '2024-08-20', leaveToDate: '2024-08-25', totalDays: 5, status: 'Approved' },
        { id: 3, employeeName: 'Emily Davis', reason: 'Personal', leaveFromDate: '2024-08-05', leaveToDate: '2024-08-08', totalDays: 3, status: 'Rejected' },
        { id: 4, employeeName: 'Michael Johnson', reason: 'Family Emergency', leaveFromDate: '2024-08-01', leaveToDate: '2024-08-03', totalDays: 2, status: 'Pending' },
        // Add more demo data here
    ];

    const filteredLeaves = leaves.filter(leave =>
        leave.employeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        leave.reason.toLowerCase().includes(searchQuery.toLowerCase()) ||
        leave.leaveFromDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
        leave.leaveToDate.toLowerCase().includes(searchQuery.toLowerCase()) ||
        leave.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredLeaves.length / rowsPerPage);
    const paginatedLeaves = filteredLeaves.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedLeaves = [...paginatedLeaves].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, leave) => {
        setAnchorEl(event.currentTarget);
        setCurrentLeave(leave);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentLeave(null);
    };

    const handleView = () => {
        console.log(`Viewing leave request with id: ${currentLeave.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleApprove = () => {
        console.log(`Approved leave request with id: ${currentLeave.id}`);
        handleMenuClose();
        // Add your approve logic here
    };

    const handleReject = () => {
        console.log(`Rejected leave request with id: ${currentLeave.id}`);
        handleMenuClose();
        // Add your reject logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                {/* Title Area */}
                <h2 className="text-2xl font-semibold text-gray-800">Leave Requests</h2>
                {/* Action Buttons */}
                <div className="flex space-x-4">
                    {/* Button for Adding New Schedule */}
                    <Link to="/add-leave">
                        <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                            New Request
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card-block">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('employeeName')}
                                >
                                    Employee Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('reason')}
                                >
                                    Reason
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'reason' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'reason' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('leaveFromDate')}
                                >
                                    Leave From
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'leaveFromDate' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'leaveFromDate' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('leaveToDate')}
                                >
                                    Leave To
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'leaveToDate' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'leaveToDate' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('totalDays')}
                                >
                                    Total Days
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'totalDays' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'totalDays' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('status')}
                                >
                                    Status
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedLeaves.map((leave) => (
                                <tr key={leave.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{leave.employeeName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{leave.reason}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{leave.leaveFromDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{leave.leaveToDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{leave.totalDays}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{leave.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-center text-sm font-medium">
                                        <IconButton onClick={(event) => handleMenuClick(event, leave)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MuiMenuItem onClick={handleView}>
                                                <Visibility className="mr-2" /> View
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleApprove}>
                                                <CheckBox className="mr-2" /> Approve
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleReject}>
                                                <Cancel className="mr-2" /> Reject
                                            </MuiMenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="mt-4 flex justify-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                        variant="outlined"
                    />
                    </div>
            </div>
        </div>
    );
};

export default Leave;
