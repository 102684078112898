import React from 'react';
import { Card, CardContent, CardHeader, CardActions, Typography, Avatar, List, ListItem, ListItemText, ListItemAvatar, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const DoctorsCardView = () => {
  // Sample data for doctors
  const doctors = [
    { id: 1, name: 'John Doe', designation: 'MBBS, MD', photo: 'assets/img/user.jpg', status: 'online' },
    { id: 2, name: 'Richard Miles', designation: 'MD', photo: 'assets/img/user.jpg', status: 'offline' },
    { id: 3, name: 'Alice Johnson', designation: 'BMBS', photo: 'assets/img/user.jpg', status: 'away' },
    { id: 4, name: 'Michael Brown', designation: 'MS, MD', photo: 'assets/img/user.jpg', status: 'online' },
    { id: 5, name: 'Anna Smith', designation: 'MBBS', photo: 'assets/img/user.jpg', status: 'offline' },
    { id: 6, name: 'Emily Davis', designation: 'MBBS, MD', photo: 'assets/img/user.jpg', status: 'away' },
  ];

  return (
    <Card className="w-full member-panel">
      <CardHeader
        className="bg-white"
        title={<Typography variant="h6" component="div" className="font-semibold text-gray-800">Doctors</Typography>}
      />
      <CardContent className="card-body" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <List className="contact-list">
          {doctors.slice(0, 5).map((doctor) => (
            <ListItem key={doctor.id} className="contact-cont flex items-center border-b border-gray-200">
              {/* Left: Doctor Photo */}
              <ListItemAvatar className="float-left user-img m-r-10">
                <a href="profile.html" title={doctor.name}>
                  <Avatar
                    alt={doctor.name}
                    src={doctor.photo}
                    className={`w-40 rounded-circle status ${doctor.status}`}
                  />
                </a>
              </ListItemAvatar>
              {/* Right: Doctor Name and Designation */}
              <ListItemText
                primary={<span className="contact-name text-ellipsis">{doctor.name}</span>}
                secondary={<span className="contact-date">{doctor.designation}</span>}
                className="contact-info"
              />
            </ListItem>
          ))}
        </List>
      </CardContent>
      <CardActions className="text-center bg-white justify-center">
        <Button component={Link} to="/list-doctor" variant="text" className="text-muted">
          View all Doctors
        </Button>
      </CardActions>
    </Card>
  );
};

export default DoctorsCardView;
