import React, { useState } from 'react';
import { FormControl, InputLabel, Select, TextField, IconButton, Autocomplete, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import { AddCircle, RemoveCircle } from '@mui/icons-material';

const ProductList = ({ productsData, categoriesData }) => {
  const [products, setProducts] = useState([]);
  const [currentProduct, setCurrentProduct] = useState({
    category: '',
    id: '',
    name: '',
    qty: '',
    unitType: '',
    unitPrice: '',
    vat: '',
    discount: '',
    amount: ''
  });

  // Function to calculate the total amount based on qty, unit price, vat, and discount
  const calculateAmount = (quantity, unitPrice, vat, discount) => {
    const qty = parseFloat(quantity) || 0;
    const price = parseFloat(unitPrice) || 0;
    const vatPercentage = parseFloat(vat) || 0;
    const discountPercentage = parseFloat(discount) || 0;

    const subtotal = qty * price;
    const total = subtotal * (1 + vatPercentage / 100) * (1 - discountPercentage / 100);
    return total.toFixed(2);
  };

  // Handle change for product details
  const handleInputChange = (field, value) => {
    const updatedProduct = { ...currentProduct, [field]: value };

    // Calculate amount if relevant fields change
    if (['qty', 'unitPrice', 'vat', 'discount'].includes(field)) {
      updatedProduct.amount = calculateAmount(
        updatedProduct.qty,
        updatedProduct.unitPrice,
        updatedProduct.vat,
        updatedProduct.discount
      );
    }

    setCurrentProduct(updatedProduct);
  };

  // Handle product selection from Autocomplete
  const handleProductSelect = (event, newValue) => {
    if (newValue) {
      setCurrentProduct(prevState => ({
        ...prevState,
        id: newValue.id || '',
        name: newValue.name || '',
        unitType: newValue.unitType || ''
      }));
    }
  };

  // Add product to the list
  const addProduct = () => {
    if (!currentProduct.category || !currentProduct.id || !currentProduct.name || !currentProduct.qty || !currentProduct.unitPrice) {
      console.log("Please fill in Category, Product, Quantity, and Rate before adding.");
      return;
    }

    setProducts([...products, currentProduct]);
    setCurrentProduct({
      category: '',
      id: '',
      name: '',
      qty: '',
      unitType: '',
      unitPrice: '',
      vat: '',
      discount: '',
      amount: ''
    });
  };

  // Remove a product from the list
  const removeProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  return (
    <div>
      {/* Header Section - Input Fields */}
      <div className="mb-6 pb-4 border-b-2 border-gray-300 w-full">
        <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
          Order Details
        </h4>

        {/* Input Section */}
        <div className="flex items-end space-x-2 mb-4 w-full">
          {/* Category */}
          <div className="w-1/5">
            <FormControl fullWidth>
              <InputLabel>Category</InputLabel>
              <Select
                value={currentProduct.category || ''}
                onChange={(e) => handleInputChange('category', e.target.value)}
                displayEmpty
              >
                {categoriesData.map((category, i) => (
                  <MenuItem key={i} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          {/* Name */}
          <div className="w-2/5">
            <Autocomplete
              options={productsData.filter(p => p.category === currentProduct.category)}
              getOptionLabel={(option) => `${option.id} - ${option.name} (${option.group})`}
              renderInput={(params) => (
                <TextField {...params} label="Product" variant="outlined" fullWidth />
              )}
              onChange={handleProductSelect}
              value={productsData.find((p) => p.id === currentProduct.id) || null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
          </div>

          {/* Quantity */}
          <div className="w-1/20">
            <TextField
              label="Quantity"
              variant="outlined"
              fullWidth
              value={currentProduct.qty || ''}
              onChange={(e) => handleInputChange('qty', e.target.value)}
              inputProps={{ pattern: "\\d{1,5}", title: "Enter up to 5 digits" }}
            />
          </div>

          {/* Unit Type */}
          <div className="w-1/20">
            <TextField
              label="Unit Type"
              variant="outlined"
              fullWidth
              value={currentProduct.unitType || ''}
              InputProps={{
                readOnly: true,
              }}
              inputProps={{ maxLength: 3 }}
            />
          </div>

          {/* Unit Price */}
          <div className="w-1/20">
            <TextField
              label="Rate"
              variant="outlined"
              fullWidth
              value={currentProduct.unitPrice || ''}
              onChange={(e) => handleInputChange('unitPrice', e.target.value)}
              inputProps={{ step: '0.01', min: '0', pattern: "\\d+(\\.\\d{1,2})?", title: "Enter up to 2 decimal places" }}
            />
          </div>

          {/* VAT */}
          <div className="w-1/20">
            <TextField
              label="VAT (%)"
              variant="outlined"
              fullWidth
              value={currentProduct.vat || ''}
              onChange={(e) => handleInputChange('vat', e.target.value)}
              inputProps={{ step: '0.01', min: '0', pattern: "\\d+(\\.\\d{1,2})?", title: "Enter up to 2 decimal places" }}
            />
          </div>

          {/* Discount */}
          <div className="w-1/20">
            <TextField
              label="Discount (%)"
              variant="outlined"
              fullWidth
              value={currentProduct.discount || ''}
              onChange={(e) => handleInputChange('discount', e.target.value)}
              inputProps={{ pattern: "\\d+(\\.\\d{1,2})?", title: "Enter up to 5 digits" }}
            />
          </div>

          {/* Amount */}
          <div className="w-1/5">
            <TextField
              label="Total (TK)"
              variant="outlined"
              fullWidth
              value={currentProduct.amount || ''}
              InputProps={{
                readOnly: true,
                inputProps: { step: '0.01' }
              }}
            />
          </div>

          {/* Add Product Button */}
          <div className="w-1/5">
            <Button
              variant="contained"
              color="primary"
              onClick={addProduct}
              startIcon={<AddCircle />}
              fullWidth
            >
              Add
            </Button>
          </div>
        </div>
      </div>

      {/* Table Section - Display Added Products */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>SL</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Item/Product</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Unit Type</TableCell>
              <TableCell>Rate</TableCell>
              <TableCell>VAT (%)</TableCell>
              <TableCell>Discount (%)</TableCell>
              <TableCell>Total</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{product.category}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.qty}</TableCell>
                <TableCell>{product.unitType}</TableCell>
                <TableCell>{product.unitPrice}</TableCell>
                <TableCell>{product.vat}</TableCell>
                <TableCell>{product.discount}</TableCell>
                <TableCell>{product.amount}</TableCell>
                <TableCell>
                  <IconButton color="secondary" onClick={() => removeProduct(index)}>
                    <RemoveCircle />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProductList;
