import React, { useState } from 'react';
import { Visibility, Cancel,CheckBox,MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const PrescriptionList = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'dateTime', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentPrescription, setCurrentPrescription] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const prescriptions = [
        { id: 1, dateTime: '2024-08-10 10:00 AM', patientName: 'Alice Johnson', employeeName: 'John Doe', city: 'New York' ,  status: 'Scheduled' },
        { id: 2, dateTime: '2024-08-11 11:00 AM', patientName: 'Bob Smith', employeeName: 'Jane Smith', city: 'Los Angeles' ,  status: 'Completed' },
        { id: 3, dateTime: '2024-08-12 09:00 AM', patientName: 'Charlie Brown', employeeName: 'Emily Davis', city: 'Chicago' , status: 'Pending' },
        { id: 4, dateTime: '2024-08-13 01:00 PM', patientName: 'David Wilson', employeeName: 'Michael Johnson', city: 'Houston' , status: 'Cancelled' },
        // Add more demo data here
    ]; // 

    const filteredPrescription = prescriptions.filter(prescription =>
        prescription.dateTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prescription.patientName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prescription.employeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prescription.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        prescription.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredPrescription.length / rowsPerPage);
    const paginatedPrescriptions = filteredPrescription.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedPrescriptions = [...paginatedPrescriptions].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, prescription) => {
        setAnchorEl(event.currentTarget);
        setCurrentPrescription(prescription);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentPrescription(null);
    };

    const handleView = () => {
        console.log(`Viewing prescription with id: ${currentPrescription.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleApproved = () => {
        console.log(`Approved prescription with id: ${currentPrescription.id}`);
        handleMenuClose();
        // Add your logic here
    };

    const handleCancel = () => {
        console.log(`Cancel prescription with id: ${currentPrescription.id}`);
        handleMenuClose();
        // Add your logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                {/* Title Area */}
                <h2 className="text-2xl font-semibold text-gray-800">Prescription List</h2>
                
                {/* Action Buttons */}
                <div className="flex space-x-4">
                    {/* Button for Adding New Prescription */}
                    <Link to="/add-prescription">
                        <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                            Add Prescription
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card-block">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('dateTime')}
                                >
                                    Date and Time
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'dateTime' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'dateTime' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('patientName')}
                                >
                                    Patient Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'patientName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'patientName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('employeeName')}
                                >
                                    Employee Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('city')}
                                >
                                    City
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'city' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'city' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('status')}
                                >
                                    Status
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedPrescriptions.map((prescription) => (
                                <tr key={prescription.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{prescription.dateTime}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prescription.patientName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prescription.employeeName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prescription.city}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{prescription.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <IconButton onClick={(event) => handleMenuClick(event, prescription)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MuiMenuItem onClick={handleView}>
                                                <Visibility /> View
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleApproved}>
                                                <CheckBox /> Approved
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleCancel}>
                                                <Cancel /> Cancel
                                            </MuiMenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-4 flex justify-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                        variant="outlined"
                    />
                    </div>
            </div>
        </div>
    );
};

export default PrescriptionList;
