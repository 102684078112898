// src/components/layout/Header.js
import React, { useState, useRef, useEffect } from 'react';
import { Menu as MenuIcon, Logout as LogoutIcon, Person as PersonIcon } from '@mui/icons-material';
import Logo from '../../logo.png'; // Ensure this path is correct
import Modal from '../Modal/ProfileCard'; // Import the Modal component

function Header({ onLogout, onToggleSidebar, sidebarVisible, user }) {
  const [isProfileCardVisible, setIsProfileCardVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const profileCardRef = useRef(null); // Ref for the profile card container
  const profileButtonRef = useRef(null); // Ref for the profile button container

  const handleProfileClick = () => {
    setIsProfileCardVisible(!isProfileCardVisible);
  };

  const handleClickOutside = (event) => {
    if (
      profileCardRef.current && !profileCardRef.current.contains(event.target) &&
      profileButtonRef.current && !profileButtonRef.current.contains(event.target)
    ) {
      setIsProfileCardVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <header
      className="bg-customGreen text-white p-4 flex items-center fixed top-0 left-0 w-full z-50"
      style={{
        height: '64px',
        zIndex: 99999,
        boxShadow: 'rgba(0, 0, 0, 0.37) 0px 4px 8px' // Add this line for the drop shadow
      }}
    >
      <div className="flex items-center">
        <div className={`flex items-center justify-center w-full ${sidebarVisible ? 'flex' : 'hidden'}`} style={{ width: '260px' }}>
          <img
            src={Logo}
            width="150px"
            height="auto"
            alt="Zuventure Corporation"
          />
        </div>
        <button
          onClick={onToggleSidebar}
          className="ml-4 text-3xl"
        >
          <MenuIcon style={{ fontSize: '2.5rem' }} />
        </button>
      </div>
      <div className="flex items-center ml-6 flex-grow">
        <span className="flex text-2xl flex-grow">
          Zuventure Corporation
        </span>
        <div className="relative flex items-center ml-auto" ref={profileButtonRef}>
          {user && (
            <div className="flex items-center text-black p-2 cursor-pointer" onClick={handleProfileClick}>
              <img
                src={user.avatar}
                alt="User Avatar"
                className="w-10 h-10 rounded-full border border-gray-300"
              />
              <div className="ml-2 text-white">
                <span className="block font-semibold">{user.name}</span>
                <span className="block text-sm">{user.designation}</span>
              </div>
            </div>
          )}
          {isProfileCardVisible && (
            <div
              ref={profileCardRef}
              className="absolute top-full right-0 mt-2 w-80 bg-white text-black border border-gray-300 rounded-lg shadow-lg"
            >
              <div className="h-24 bg-gray-200 rounded-t-lg"></div>
              <div className="p-4 flex flex-col items-center">
                <div className="flex items-center justify-center mb-4">
                  <img
                    src={user.avatar}
                    alt="User Avatar"
                    className="w-24 h-24 rounded-full border border-gray-300"
                  />
                </div>
                <div className="text-center">
                  <span className="block text-lg font-semibold">{user.designation}</span>
                  <span className="block text-sm text-gray-600">{user.address}</span>
                </div>
                <div className="flex flex-col items-center mt-4">
                  <span className="text-sm text-gray-600">{user.role}</span>
                  <span className="text-sm text-gray-600">{user.roleTitle}</span>
                </div>
              </div>
              <div className="flex border-t border-gray-300">
                <button
                  className="flex-1 py-2 text-green-500 hover:bg-blue-50 border-r border-gray-300"
                  onClick={() => setIsModalVisible(true)}
                >
                  <PersonIcon className="w-5 h-5 mr-2" />
                  Profile
                </button>
                <button
                  onClick={onLogout}
                  className="flex-1 py-2 text-red-600 hover:bg-red-50"
                >
                  <LogoutIcon className="w-5 h-5 mr-2" />
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      <Modal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        user={user}
      />
    </header>

  );
}

export default Header;
