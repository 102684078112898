// RowLimit.js
import React from 'react';
import { Select, MenuItem as MuiMenuItem } from '@mui/material';

const RowLimit = ({ rowsPerPage, onRowsPerPageChange }) => {
  return (
    <div className="text-sm text-gray-500 flex items-center">
      <span>Showing </span>
      <Select
        value={rowsPerPage}
        onChange={onRowsPerPageChange}
        className="w-15"
        size="small"
      >
        <MuiMenuItem value={15}>15</MuiMenuItem>
        <MuiMenuItem value={25}>25</MuiMenuItem>
        <MuiMenuItem value={50}>50</MuiMenuItem>
        <MuiMenuItem value={100}>100</MuiMenuItem>
      </Select>
      <span> entries</span>
    </div>
  );
};

export default RowLimit;
