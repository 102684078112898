import axios from 'axios';

// Create Axios instances for different base URLs
const localApi = axios.create({
  baseURL: 'http://192.168.0.222:3000',
  headers: {
    'Content-Type': 'application/json',
  },
});

const externalApi = axios.create({
  baseURL: 'https://dummyjson.com',
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to handle user login
export const loginData = async (userId, password) => {
  try {
    const response = await localApi.post('/login', { userId, password });
    return response.data; // Returns the token and message
  } catch (error) {
    console.error('Error logging in:', error);
    throw error.response ? error.response.data : new Error('Network Error');
  }
};

// Function to get the list of users with pagination
export const getUsers = async (page, limit) => {
  try {
    const response = await localApi.get('/users', {
      params: {
        skip: (page - 1) * limit, // Adjusting for pagination
        limit: limit,
      },
    });
    const totalUsers = response.data.total; // Assuming total is returned in the response body
    return { users: response.data.users, totalUsers };
  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};

// Function to add a new user
export const addUser = async (user) => {
  try {
    const response = await localApi.post('/users/add', user); // Adjusted endpoint for adding a user
    return response.data;
  } catch (error) {
    console.error('Error adding user:', error);
    throw error;
  }
};

// Function to update a user
export const updateUser = async (userId, user) => {
  try {
    const response = await localApi.put(`/users/${userId}`, user);
    return response.data;
  } catch (error) {
    console.error('Error updating user:', error);
    throw error;
  }
};

// Function to delete a user
export const deleteUser = async (userId) => {
  try {
    const response = await localApi.delete(`/users/${userId}`);
    return response.data;
  } catch (error) {
    console.error('Error deleting user:', error);
    throw error;
  }
};

// Function to get the list of products with pagination from the external API
export const getProducts = async (page, limit) => {
  try {
    const response = await externalApi.get('/products', {
      params: {
        skip: (page - 1) * limit, // Adjusting for pagination
        limit: limit,
      },
    });
    const totalProducts = response.data.total; // Assuming total is returned in the response body
    return { products: response.data.products, totalProducts };
  } catch (error) {
    console.error('Error fetching products:', error);
    throw error;
  }
};
