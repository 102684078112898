import React, { useState } from 'react'
import Button from '../../components/common/Button';
import DesignationList from './DesignationList';

function AddDesignation() {
    const initialDesignationState = {
        designationName : "",
        description : "",
    };
    const [designation, setDesignation] = useState(initialDesignationState);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setDesignation({
            ...designation,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to add designation
        console.log('Designation added:', designation)
        // Clear the form
        setDesignation(initialDesignationState);
    }

  return (
    <div className='add-designation flex'>
        <div className="w-full lg:w-1/3 p-4">
            <h2 className="text-xl font-bold mb-4">Add Designation</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 gap-6">
                    <div>
                    <label className="block text-gray-700">Designation Name</label>
                    <input
                        type="text"
                        name="designationName"
                        value={designation.designationName}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter designation name"
                        required
                    />
                    </div>
                    <div>
                    <label className="block text-gray-700">Description</label>
                    <textarea
                        name="description"
                        value={designation.description}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter description"
                    />
                    </div>
                </div>
                <div className="mt-4">
                    <Button Name="Add Designation" />
                </div>
            </form>
        </div>
        <div className="w-full lg:w-2/3 p-4">
            <DesignationList /> 
        </div>
    </div>
  )
}

export default AddDesignation