import React from 'react';
import { Button } from '@mui/material';

function FormActions({ clearForm, handleOrderConfirm }) {
  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
      <Button variant="contained" color="primary" onClick={handleOrderConfirm} style={{ marginRight: '8px' }}>
        Confirm Order
      </Button>
      <Button variant="outlined" color="secondary" onClick={clearForm}>
        Clear
      </Button>
    </div>
  );
}

export default FormActions;
