import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  InputAdornment,
  IconButton,
} from '@mui/material';
import {
  Person,
  CardGiftcard,
  LocalOffer,
  CalendarToday,
  AddCircleOutline,
  RemoveCircleOutline,
} from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

const NewGiftPlan = () => {
  const [formValues, setFormValues] = useState({
    doctorInfo: '',
    gifts: [{ giftName: '', type: '', qty: '' }],
    visitWith: '',
    visitDate: '',
    visitTime: '',
    place: '',
    remarks: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedGifts = formValues.gifts.map((gift, i) =>
      i === index ? { ...gift, [name]: value } : gift
    );
    setFormValues((prev) => ({ ...prev, gifts: updatedGifts }));
    setFormErrors((prev) => ({
      ...prev,
      gifts: prev.gifts.map((error, i) =>
        i === index ? { ...error, [name]: '' } : error
      ),
    }));
  };

  const handleGeneralChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const addGiftRow = () => {
    setFormValues((prev) => ({
      ...prev,
      gifts: [...prev.gifts, { giftName: '', type: '', qty: '' }],
    }));
  };

  const removeGiftRow = (index) => {
    const updatedGifts = formValues.gifts.filter((_, i) => i !== index);
    setFormValues((prev) => ({ ...prev, gifts: updatedGifts }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.doctorInfo.trim()) {
      errors.doctorInfo = 'Doctor info is required';
    }

    const giftErrors = formValues.gifts.map((gift) => {
      let giftError = {};
      if (!gift.giftName.trim()) {
        giftError.giftName = 'Gift/Product name is required';
      }
      if (!gift.type.trim()) {
        giftError.type = 'Type is required';
      }
      if (!gift.qty.trim()) {
        giftError.qty = 'Quantity is required';
      } else if (isNaN(gift.qty) || Number(gift.qty) <= 0) {
        giftError.qty = 'Quantity must be a positive number';
      }
      return giftError;
    });

    if (giftErrors.some((error) => Object.keys(error).length > 0)) {
      errors.gifts = giftErrors;
    }

    if (!formValues.visitWith.trim()) {
      errors.visitWith = 'Visit with is required';
    }
    if (!formValues.visitDate.trim()) {
      errors.visitDate = 'Visit date is required';
    }
    if (!formValues.visitTime.trim()) {
      errors.visitTime = 'Visit time is required';
    }
    if (!formValues.place.trim()) {
      errors.place = 'Place is required';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0 && !errors.gifts;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Gift Plan Submitted:', formValues);
      setOpenSnackbar(true);
      handleReset();
    }
  };

  const handleReset = () => {
    setFormValues({
      doctorInfo: '',
      gifts: [{ giftName: '', type: '', qty: '' }],
      visitWith: '',
      visitDate: '',
      visitTime: '',
      place: '',
      remarks: '',
    });
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add Gift Plan</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <Person className="text-customGreen mr-2" /> Doctor Info
              </h4>
              <TextField
                label="Doctor Info"
                variant="outlined"
                fullWidth
                name="doctorInfo"
                value={formValues.doctorInfo}
                onChange={handleGeneralChange}
                error={!!formErrors.doctorInfo}
                helperText={formErrors.doctorInfo}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </div>

            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <CardGiftcard className="text-customGreen mr-2" /> Gifts/Products
              </h4>
              {formValues.gifts.map((gift, index) => (
                <div
                  key={index}
                  className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-4"
                >
                  <TextField
                    label="Gift/Product Name"
                    variant="outlined"
                    fullWidth
                    name="giftName"
                    value={gift.giftName}
                    onChange={(e) => handleInputChange(index, e)}
                    error={!!(formErrors.gifts && formErrors.gifts[index]?.giftName)}
                    helperText={
                      formErrors.gifts && formErrors.gifts[index]?.giftName
                    }
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CardGiftcard className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1"
                  />
                  <FormControl
                    fullWidth
                    variant="outlined"
                    className="mt-1 mb-4"
                    error={!!(formErrors.gifts && formErrors.gifts[index]?.type)}
                  >
                    <InputLabel>Type</InputLabel>
                    <Select
                      label="Type"
                      name="type"
                      value={gift.type}
                      onChange={(e) => handleInputChange(index, e)}
                      className="text-customGreen"
                    >
                      <MenuItem value="gift">Gift</MenuItem>
                      <MenuItem value="sample">Sample</MenuItem>
                    </Select>
                    {formErrors.gifts && formErrors.gifts[index]?.type && (
                      <p className="text-red-600 text-sm mt-1">
                        {formErrors.gifts[index]?.type}
                      </p>
                    )}
                  </FormControl>
                  <TextField
                    label="Quantity"
                    variant="outlined"
                    fullWidth
                    name="qty"
                    value={gift.qty}
                    onChange={(e) => handleInputChange(index, e)}
                    error={!!(formErrors.gifts && formErrors.gifts[index]?.qty)}
                    helperText={formErrors.gifts && formErrors.gifts[index]?.qty}
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalOffer className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                    className="mt-1"
                  />
                  <div className="flex items-center justify-center mt-1">
                    <IconButton
                      color="secondary"
                      onClick={() => removeGiftRow(index)}
                      disabled={formValues.gifts.length === 1}
                    >
                      <RemoveCircleOutline />
                    </IconButton>
                  </div>
                </div>
              ))}
              <Button
                variant="outlined"
                color="primary"
                startIcon={<AddCircleOutline />}
                onClick={addGiftRow}
                className="mt-4"
              >
                Add Another Gift/Product
              </Button>
            </div>

            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <CalendarToday className="text-customGreen mr-2" /> Visit Details
              </h4>
              <div className="flex flex-wrap gap-4 mb-4">
                <FormControl
                  fullWidth
                  variant="outlined"
                  className="flex-1"
                  error={!!formErrors.visitWith}
                >
                  <InputLabel>Visit With</InputLabel>
                  <Select
                    label="Visit With"
                    name="visitWith"
                    value={formValues.visitWith}
                    onChange={handleGeneralChange}
                  >
                    <MenuItem value="QA">QA</MenuItem>
                    <MenuItem value="QC">QC</MenuItem>
                    <MenuItem value="HR">HR</MenuItem>
                  </Select>
                  {formErrors.visitWith && (
                    <p className="text-red-600 text-sm mt-1">{formErrors.visitWith}</p>
                  )}
                </FormControl>
                <TextField
                  label="Visit Date"
                  variant="outlined"
                  fullWidth
                  name="visitDate"
                  value={formValues.visitDate}
                  onChange={handleGeneralChange}
                  error={!!formErrors.visitDate}
                  helperText={formErrors.visitDate}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  className="flex-1"
                />
                <TextField
                  label="Visit Time"
                  variant="outlined"
                  fullWidth
                  name="visitTime"
                  value={formValues.visitTime}
                  onChange={handleGeneralChange}
                  error={!!formErrors.visitTime}
                  helperText={formErrors.visitTime}
                  type="time"
                  InputLabelProps={{ shrink: true }}
                  className="flex-1"
                />
                <TextField
                  label="Place"
                  variant="outlined"
                  fullWidth
                  name="place"
                  value={formValues.place}
                  onChange={handleGeneralChange}
                  error={!!formErrors.place}
                  helperText={formErrors.place}
                  className="flex-1"
                />
              </div>
            </div>

            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <Person className="text-customGreen mr-2" /> Remarks
              </h4>
              <TextField
                label="Remarks"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                name="remarks"
                value={formValues.remarks}
                onChange={handleGeneralChange}
                className="mt-1"
              />
            </div>

            <div className="flex justify-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                startIcon={<AddCircleOutline />}
                className="bg-customGreen text-white hover:bg-customGreen-dark"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Notification
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message="Gift Plan submitted successfully!"
        type="success"
      />
    </div>
  );
};

export default NewGiftPlan;
