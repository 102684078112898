import React from 'react'
import EditBtn from '../../components/common/EditBtn'

function DesignationList() {
    const designations = [
        { id: 1, name: 'Inventory Manager', description: 'This is a example fo description for test..!', count: 15},
        { id: 2, name: 'Quality Assurance Manager', description: 'This is a example fo description for test..!', count: 10},
        { id: 3, name: 'Logistics Coordinator', description: 'This is a example fo description for test..!', count: 25},
        { id: 4, name: 'Customer Service Representative', description: 'This is a example fo description for test..!', count: 8},
        { id: 5, name: 'Marketing Manager', description: 'This is a example fo description for test..!', count: 12},
        { id: 6, name: 'Account Manager', description: 'This is a example fo description for test..!', count: 6},
        { id: 7, name: 'Financial Analyst', description: 'This is a example fo description for test..!', count: 5},
        // Add more designation as needed
      ];
      
  return (
    <>
      <h2 className="text-xl font-bold mb-4">List of designations</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Name</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Description</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Count</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
            {designations.map((designation) => (
                <tr key={designation.id} className='hover:bg-gray-100'>
                    <td className="py-2 px-4 border-b border-gray-300">{designation.name}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{designation.description}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{designation.count}</td>
                    <td className='py-2 px-4 border-b border-gray-300'>
                        <EditBtn Name="Edit" Path="#" />
                    </td>
                </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default DesignationList