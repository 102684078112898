import { Typography } from '@mui/material';
import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import WorkIcon from '@mui/icons-material/Work';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { AccountCircle } from '@mui/icons-material';

function UserListSection() {
  return (
    <div className='w-full md:w-1/2 p-6 bg-gray-100 rounded-lg'>
      <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
        <AccountCircle className="text-customGreen mr-2" /> Employee Information:
      </h4>
      <div className="bg-white p-6 shadow-md rounded-lg">
        {/* User Information Grid */}
        <div className="grid grid-cols-2 gap-x-6 gap-y-4">
          {/* Full Name */}
          <div className="flex items-center">
            <PersonIcon className="text-blue-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Full Name:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">John Doe</Typography>

          {/* Designation */}
          <div className="flex items-center">
            <WorkIcon className="text-green-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Designation:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">Software Engineer</Typography>

          {/* Contact Email */}
          <div className="flex items-center">
            <EmailIcon className="text-purple-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Contact Email:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">john.doe@example.com</Typography>

          {/* Contact Phone */}
          <div className="flex items-center">
            <PhoneIcon className="text-teal-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Contact Phone:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">+1234567890</Typography>

          {/* Employee ID */}
          <div className="flex items-center">
            <BadgeIcon className="text-orange-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Employee ID:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">EMP12345</Typography>

          {/* Join Date */}
          <div className="flex items-center">
            <CalendarTodayIcon className="text-gray-500 mr-3 text-lg" />
            <Typography variant="body1" className="font-semibold text-gray-700">Join Date:</Typography>
          </div>
          <Typography variant="body2" className="text-gray-800">2023-01-15</Typography>
        </div>
      </div>
    </div>
  );
}

export default UserListSection;
