import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './AppRoutes'; // Import the new routes component
import './App.css';


const theme = createTheme({
  palette: {
    primary: {
      main: '#00A67D', // Example primary color
    },
    secondary: {
      main: '#4caf50', // Example secondary color
    },
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
    <Router>
      <AppRoutes />
    </Router>
    </ThemeProvider>
  );
}

export default App;
