import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';

const ProductTransactionList = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'invoiceDate', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTransaction, setCurrentTransaction] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const transactions = [
        { id: 1, invoiceNumber: 'INV001', invoiceDate: '2024-01-01', productName: 'Product A', quantitySold: 10, salePrice: 100, customerName: 'Customer X', status: 'Completed' },
        { id: 2, invoiceNumber: 'INV002', invoiceDate: '2024-01-02', productName: 'Product B', quantitySold: 5, salePrice: 50, customerName: 'Customer Y', status: 'Pending' },
        // Add more demo data here
    ];

    const filteredTransactions = transactions.filter(transaction =>
        transaction.invoiceNumber.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        transaction.customerName.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredTransactions.length / rowsPerPage);
    const paginatedTransactions = filteredTransactions.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedTransactions = [...paginatedTransactions].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, transaction) => {
        setAnchorEl(event.currentTarget);
        setCurrentTransaction(transaction);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentTransaction(null);
    };

    const handleView = () => {
        console.log(`Viewing transaction with id: ${currentTransaction.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleEdit = () => {
        console.log(`Editing transaction with id: ${currentTransaction.id}`);
        handleMenuClose();
        // Add your edit logic here
    };

    const handleDelete = () => {
        console.log(`Deleting transaction with id: ${currentTransaction.id}`);
        handleMenuClose();
        // Add your delete logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                {/* Title Area */}
                <h2 className="text-2xl font-semibold text-gray-800">Product Transaction List</h2>
            </div>
            <div className="card-block">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('invoiceNumber')}
                                >
                                    Invoice Number
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'invoiceNumber' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'invoiceNumber' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('invoiceDate')}
                                >
                                    Invoice Date
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'invoiceDate' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'invoiceDate' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('productName')}
                                >
                                    Product Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'productName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'productName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('quantitySold')}
                                >
                                    Quantity Sold
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'quantitySold' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'quantitySold' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('salePrice')}
                                >
                                    Sale Price
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'salePrice' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'salePrice' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Customer Name</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedTransactions.map((transaction) => (
                                <tr key={transaction.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.invoiceNumber}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.invoiceDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.productName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.quantitySold}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.salePrice}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.customerName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{transaction.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <IconButton onClick={(event) => handleMenuClick(event, transaction)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && currentTransaction && currentTransaction.id === transaction.id}
                                                onClose={handleMenuClose}
                                            >
                                                <MuiMenuItem onClick={handleView}>
                                                    <Visibility className="mr-2" /> View
                                                </MuiMenuItem>
                                                <MuiMenuItem onClick={handleEdit}>
                                                    <Edit className="mr-2" /> Edit
                                                </MuiMenuItem>
                                                <MuiMenuItem onClick={handleDelete}>
                                                    <Delete className="mr-2" /> Delete
                                                </MuiMenuItem>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-between items-center mt-4">
                    <div className="text-sm text-gray-500">
                        Showing {Math.min((page - 1) * rowsPerPage + 1, filteredTransactions.length)} to{' '}
                        {Math.min(page * rowsPerPage, filteredTransactions.length)} of {filteredTransactions.length} entries
                    </div>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                </div>
            </div>
        </div>
    );
};

export default ProductTransactionList;
