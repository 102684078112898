import React from 'react';
import { Typography, TableContainer, Paper, } from '@mui/material';
import UserInfoSection from './UserInfoSection';
import UserListSection from './UserListSection';
import PermissionsSection from './PermissionsSection';
import { Shield } from '@mui/icons-material';

const UserPermissions = () => {

  return (
    <div className="p-6 bg-gray-100 min-h-screen">
      <div className="flex justify-between items-start mb-6">
        {/* User List Section */}
        <UserListSection />

        {/* User Info Section */}
        <UserInfoSection />
      </div>

      {/* Permissions Section */}
      
      <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
            <Shield className="text-customGreen mr-2" /> Permissions:
        </h4>
      <TableContainer component={Paper} className="shadow-lg rounded-lg w-full">
        <PermissionsSection PageTitle="Dashboard" />
        <PermissionsSection PageTitle="Sales" />
        <PermissionsSection PageTitle="Product" />
        <PermissionsSection PageTitle="Purchase" />
        </TableContainer>
    </div>
  );
};

export default UserPermissions;
