import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon, Email, EmailOutlined, EmailRounded, Send } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';

const DoctorList = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'name', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentDoctor, setCurrentDoctor] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const doctors = [
        { id: 1, photo: '/path/to/photo1.jpg', name: 'Dr. John Doe', phone: '123-456-7890', city: 'City A', district: 'District A', doctorType: 'Doctor', email: 'john.doe@example.com', emailStatus: 'sent' },
        { id: 2, photo: '/path/to/photo2.jpg', name: 'Dr. Jane Smith', phone: '987-654-3210', city: 'City B', district: 'District B', doctorType: 'Chemist', email: 'jane.smith@example.com', emailStatus: 'failed' },
        { id: 3, photo: '/path/to/photo3.jpg', name: 'Dr. Emily Johnson', phone: '456-789-0123', city: 'City C', district: 'District C', doctorType: 'Doctor', email: 'emily.johnson@example.com', emailStatus: 'pending' },
        { id: 4, photo: '/path/to/photo4.jpg', name: 'Dr. Michael Brown', phone: '654-321-0987', city: 'City D', district: 'District D', doctorType: 'Chemist', email: 'michael.brown@example.com', emailStatus: 'sent' },
        // Add more demo data here
    ];

    const filteredDoctors = doctors.filter(doctor =>
        doctor.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.city.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.district.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.doctorType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        doctor.email.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredDoctors.length / rowsPerPage);
    const paginatedDoctors = filteredDoctors.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedDoctors = [...paginatedDoctors].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, doctor) => {
        setAnchorEl(event.currentTarget);
        setCurrentDoctor(doctor);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentDoctor(null);
    };

    const handleView = () => {
        console.log(`Viewing doctor with id: ${currentDoctor.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleEdit = () => {
        console.log(`Editing doctor with id: ${currentDoctor.id}`);
        handleMenuClose();
        // Add your edit logic here
    };

    const handleSendEmail = (doctorId) => {
        // Logic to send email goes here
        console.log(`Sending email to doctor with id: ${doctorId}`);
        // Update the email status in the state or backend
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">Record</h2>
                <div className="flex space-x-4">
                    <Link to="/add-doctor">
                        <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                            Add Record
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card-block">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Photo
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('name')}
                                >
                                    Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'name' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'name' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('phone')}
                                >
                                    Phone
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'phone' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'phone' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('city')}
                                >
                                    City
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'city' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'city' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('district')}
                                >
                                    District
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'district' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'district' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('doctorType')}
                                >
                                    Type
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'doctorType' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'doctorType' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('email')}
                                >
                                    Email
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'email' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'email' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: 'auto', minWidth: '120px' }}>
                                    Email Status
                                </th>
                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider" style={{ width: 'auto', minWidth: '100px' }}>
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedDoctors.map((doctor) => (
                                <tr key={doctor.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                        <Avatar src={doctor.photo} alt={doctor.name} />
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.name}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.phone}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.city}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.district}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.doctorType}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{doctor.email}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 flex items-center space-x-2" style={{ width: 'auto' }}>
                                        {doctor.emailStatus === 'sent' && <EmailRounded color="success" />}
                                        {doctor.emailStatus === 'failed' && <Email color="error" />}
                                        {doctor.emailStatus === 'pending' && <EmailOutlined color="action" />}
                                        <IconButton onClick={() => handleSendEmail(doctor.id)} size="small" color="primary">
                                            <Send fontSize="small" />
                                        </IconButton>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" style={{ width: 'auto' }}>
                                        <IconButton onClick={(event) => handleMenuClick(event, doctor)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl) && currentDoctor?.id === doctor.id}
                                            onClose={handleMenuClose}
                                            getContentAnchorEl={null}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'right',
                                            }}
                                        >
                                            <MuiMenuItem onClick={handleView}>View</MuiMenuItem>
                                            <MuiMenuItem onClick={handleEdit}>Edit</MuiMenuItem>
                                            <MuiMenuItem onClick={handleMenuClose}>Delete</MuiMenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="mt-4 flex justify-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                        variant="outlined"
                    />
                    </div>
            </div>
        </div>
    );
};

export default DoctorList;
