import React, { useState } from 'react';
import { Visibility, Cancel,CheckBox,MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const MeetingSchedule = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'dateTime', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentSchedule, setCurrentSchedule] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const schedules = [
        { id: 1, dateTime: '2024-08-10 10:00 AM', doctorName: 'Dr. Alice Johnson', employeeName: 'John Doe', meetingPurpose: 'Follow-up', status: 'Scheduled' },
        { id: 2, dateTime: '2024-08-11 11:00 AM', doctorName: 'Dr. Bob Smith', employeeName: 'Jane Smith', meetingPurpose: 'Consultation', status: 'Completed' },
        { id: 3, dateTime: '2024-08-12 09:00 AM', doctorName: 'Dr. Charlie Brown', employeeName: 'Emily Davis', meetingPurpose: 'Check-up', status: 'Pending' },
        { id: 4, dateTime: '2024-08-13 01:00 PM', doctorName: 'Dr. David Wilson', employeeName: 'Michael Johnson', meetingPurpose: 'Initial Meeting', status: 'Cancelled' },
        // Add more demo data here
    ];

    const filteredSchedules = schedules.filter(schedule =>
        schedule.dateTime.toLowerCase().includes(searchQuery.toLowerCase()) ||
        schedule.doctorName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        schedule.employeeName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        schedule.meetingPurpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
        schedule.status.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredSchedules.length / rowsPerPage);
    const paginatedSchedules = filteredSchedules.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedSchedules = [...paginatedSchedules].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, schedule) => {
        setAnchorEl(event.currentTarget);
        setCurrentSchedule(schedule);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentSchedule(null);
    };

    const handleView = () => {
        console.log(`Viewing schedule with id: ${currentSchedule.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleApproved = () => {
        console.log(`Approved schedule with id: ${currentSchedule.id}`);
        handleMenuClose();
        // Add your logic here
    };

    const handleCancel = () => {
        console.log(`Cancel schedule with id: ${currentSchedule.id}`);
        handleMenuClose();
        // Add your logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                {/* Title Area */}
                <h2 className="text-2xl font-semibold text-gray-800">Meeting List</h2>
                
                {/* Action Buttons */}
                <div className="flex space-x-4">
                    {/* Button for Adding New Schedule */}
                    <Link to="/add-meeting">
                        <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                            Add Schedule
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card-block">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('dateTime')}
                                >
                                    Date and Time
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'dateTime' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'dateTime' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('doctorName')}
                                >
                                    Doctor Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'doctorName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'doctorName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('employeeName')}
                                >
                                    Employee Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'employeeName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('meetingPurpose')}
                                >
                                    Meeting Purpose
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'meetingPurpose' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'meetingPurpose' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('status')}
                                >
                                    Status
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650; {/* Unicode for up arrow */}
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660; {/* Unicode for down arrow */}
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedSchedules.map((schedule) => (
                                <tr key={schedule.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{schedule.dateTime}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.doctorName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.employeeName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.meetingPurpose}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{schedule.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        <IconButton onClick={(event) => handleMenuClick(event, schedule)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            keepMounted
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MuiMenuItem onClick={handleView}>
                                                <Visibility /> View
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleApproved}>
                                                <CheckBox /> Approved
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleCancel}>
                                                <Cancel /> Cancel
                                            </MuiMenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                
                {/* Pagination */}
                <div className="mt-4 flex justify-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                        variant="outlined"
                    />
                </div>
            </div>
        </div>
    );
};

export default MeetingSchedule;
