import React from 'react';
import { TextField, Paper, Grid } from '@mui/material';
import { Person } from '@mui/icons-material';

const CustomerInfo = ({ selectedCustomer }) => {
  // Demo profile image URL
  const profileImageUrl = 'https://via.placeholder.com/180';

  return (
    <div className="mb-6 pb-4 border-b-2 border-gray-300">
      <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
        <Person className="text-customGreen mr-2" /> Customer Info:
      </h4>
      <div className="flex flex-col md:flex-row gap-4">
        {/* User Profile Section */}
        <div className="flex-shrink-0 w-full md:w-1/3 flex items-center justify-center">
          {/* Placeholder for user profile image */}
          <Paper className="p-2 rounded-full flex items-center justify-center bg-gray-200 w-320 h-320">
            <img
              src={profileImageUrl}
              alt="Profile"
              className="w-full h-full object-cover rounded-full"
            />
          </Paper>
        </div>

        {/* Customer Details Section */}
        <div className="flex-grow w-full md:w-2/3">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                variant="outlined"
                fullWidth
                value={selectedCustomer?.name || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Phone"
                variant="outlined"
                fullWidth
                value={selectedCustomer?.phone || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Address"
                variant="outlined"
                fullWidth
                multiline
                rows={2}
                value={selectedCustomer?.address || ''}
                InputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default CustomerInfo;
