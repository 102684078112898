import React, { useState } from 'react';
import { Button, IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const SalesList = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'orderInvoice', direction: 'asc' });
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const Orders = [
    { id: 1, orderInvoice: 'INV001', orderDate: '2024-07-01', orderBy: 'Saidur', numberOfProduct: '5', status: 'Pending' },
    { id: 2, orderInvoice: 'INV002', orderDate: '2024-07-02', orderBy: 'Sumon', numberOfProduct: '15', status: 'Completed' },
    // Add more demo data here
  ];

  const filteredOrders = Orders.filter(order =>
    [order.orderInvoice, order.orderDate, order.orderBy, order.numberOfProduct, order.status].some(field =>
      field.toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  const totalPages = Math.ceil(filteredOrders.length / rowsPerPage);
  const paginatedOrders = filteredOrders.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const sortedOrders = [...paginatedOrders].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    const direction = (sortConfig.key === key && sortConfig.direction === 'asc') ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when rows per page changes
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Sales List</h2>
      </div>

      <div className="card-block">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-gray-500">
            <span>Showing </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="w-20"
              size="small"
            >
              <MuiMenuItem value={5}>5</MuiMenuItem>
              <MuiMenuItem value={10}>10</MuiMenuItem>
              <MuiMenuItem value={25}>25</MuiMenuItem>
              <MuiMenuItem value={50}>50</MuiMenuItem>
            </Select>
            <span> entries</span>
          </div>

          <div className="flex items-center space-x-4">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-full"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('orderInvoice')}
                >
                  Invoice ID
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderInvoice' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort ascending"
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderInvoice' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort descending"
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('orderDate')}
                >
                  Order Date
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderDate' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort ascending"
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderDate' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort descending"
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('numberOfProduct')}
                >
                  Number of Product
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'numberOfProduct' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort ascending"
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'numberOfProduct' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort descending"
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('orderBy')}
                >
                  Order by
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderBy' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort ascending"
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'orderBy' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort descending"
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('status')}
                >
                  Status
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort ascending"
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      aria-label="Sort descending"
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {sortedOrders.map(order => (
                <tr key={order.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.orderInvoice}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.orderDate}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.numberOfProduct}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.orderBy}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <Link to={`/generate-sales/${order.id}`}>
                      <Button variant="contained" color="primary">
                        Generate Sales
                      </Button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        <div className="mt-4 flex justify-end">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesList;
