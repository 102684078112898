import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon, Check as CheckIcon, HourglassEmpty as HourglassEmptyIcon, Edit as EditIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const MonthlyTourPlan = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'asc' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Updated tourPlans data with status
  const tourPlans = [
    { id: 1, date: '2024-09-05', time: '10:00 AM', purpose: 'Meeting', workingStatus: 'Morning', place: 'Chandina', visitType: 'HQ', status: 'Draft' },
    { id: 2, date: '2024-09-06', time: '02:00 PM', purpose: 'Inspection', workingStatus: 'Evening', place: 'Laksam', visitType: 'EX-HQ', status: 'Pending' },
    { id: 3, date: '2024-09-07', time: '09:00 AM', purpose: 'Training', workingStatus: 'Morning', place: 'Comilla', visitType: 'HQ', status: 'Complete' },
    // Add more demo data here
  ];

  const filteredPlans = tourPlans.filter(plan =>
    plan.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.time.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.purpose.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.workingStatus.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.place.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.visitType.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.status.toLowerCase().includes(searchQuery.toLowerCase()) // Include status in search
  );

  const totalPages = Math.ceil(filteredPlans.length / rowsPerPage);
  const paginatedPlans = filteredPlans.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const sortedPlans = [...paginatedPlans].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleMenuClick = (event, plan) => {
    setAnchorEl(event.currentTarget);
    setCurrentPlan(plan);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentPlan(null);
  };

  const handleView = () => {
    console.log(`Viewing plan with id: ${currentPlan.id}`);
    handleMenuClose();
  };

  const handleEdit = () => {
    console.log(`Editing plan with id: ${currentPlan.id}`);
    handleMenuClose();
  };

  // New status action handlers
  const handleDraft = () => {
    console.log(`Setting plan with id: ${currentPlan.id} to Draft`);
    handleMenuClose();
  };

  const handlePending = () => {
    console.log(`Setting plan with id: ${currentPlan.id} to Pending`);
    handleMenuClose();
  };

  const handleComplete = () => {
    console.log(`Setting plan with id: ${currentPlan.id} to Complete`);
    handleMenuClose();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Tour Plan</h2>
        <div className="flex space-x-4">
          <Link to="/new-plan">
            <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
              New Tour Plan
            </button>
          </Link>
        </div>
      </div>
      <div className="card-block">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-gray-500">
            <span>Showing </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="w-15"
              size="small"
            >
              <MuiMenuItem value={5}>5</MuiMenuItem>
              <MuiMenuItem value={10}>10</MuiMenuItem>
              <MuiMenuItem value={25}>25</MuiMenuItem>
              <MuiMenuItem value={50}>50</MuiMenuItem>
            </Select>
            <span> entries</span>
          </div>
          <div className="flex items-center space-x-4">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-3/3"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('date')}
                >
                  Date
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'date' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'date' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('time')}
                >
                  Time
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'time' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'time' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('purpose')}
                >
                  Purpose
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'purpose' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'purpose' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('workingStatus')}
                >
                  Working Status
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'workingStatus' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'workingStatus' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('place')}
                >
                  Place
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'place' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'place' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('visitType')}
                >
                  Visit Type
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'visitType' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'visitType' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('status')}
                >
                  Status
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedPlans.map((plan) => (
                <tr key={plan.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{plan.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.time}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.purpose}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.workingStatus}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.place}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.visitType}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <IconButton onClick={(event) => handleMenuClick(event, plan)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && currentPlan?.id === plan.id}
                      onClose={handleMenuClose}
                    >
                      <MuiMenuItem onClick={handleView}>
                        <Visibility fontSize="small" />
                        View
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleEdit}>
                        <Edit fontSize="small" />
                        Edit
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleDraft}>
                        <EditIcon fontSize="small" />
                        Draft
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handlePending}>
                        <HourglassEmptyIcon fontSize="small" />
                        Pending
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleComplete}>
                        <CheckIcon fontSize="small" />
                        Complete
                      </MuiMenuItem>
                      {/* <MuiMenuItem onClick={handleDelete}>
                        <Delete fontSize="small" />
                        Delete
                      </MuiMenuItem> */}
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        
          {/* Pagination */}
          <div className="mt-4 flex justify-end">
              <Pagination
                  count={totalPages}
                  page={page}
                  onChange={handlePageChange}
                  shape="rounded"
                  variant="outlined"
              />
          </div>
      </div>
    </div>
  );
};

export default MonthlyTourPlan;
