import React from 'react';
import { 
  TextField, 
  InputLabel, 
  MenuItem, 
  Select, 
  FormControl, 
  InputAdornment 
} from '@mui/material';
import { 
  AccountCircle, 
  Phone, 
  Email, 
  Language, 
  LocationOn, 
  Description, 
  Tag, 
  Link, 
  WhatsApp
} from '@mui/icons-material';

function AddCustomer() {
  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        {/* Title Area */}
        <h2 className="text-2xl font-semibold text-gray-800">Customer Details</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form>
            {/* Basic Info Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Basic Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="Customer Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Customer Type</InputLabel>
                  <Select
                    label="Customer Type"
                    defaultValue=""
                    className="text-customGreen"
                  >
                    <MenuItem value="internal">Internal</MenuItem>
                    <MenuItem value="external">External</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Country"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="ZIP"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Address"
                  variant="outlined"
                  multiline
                  rows={2}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={2}
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Description className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              </div>
            </div>

            {/* Contact Info Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <Phone className="text-customGreen mr-2" /> Contact Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="Contact Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Contact Number"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="WhatsApp Number"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <WhatsApp className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Website"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Language className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              </div>
            </div>

            {/* Social Media Info Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <Tag className="text-customGreen mr-2" /> Social Media Info:
              </h4>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <TextField
                  label="Social Media Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Tag className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Social Media URL"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Link className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <div className="flex items-end">
                  <button
                    type="button"
                    className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
                  >
                    + Add Link
                  </button>
                </div>
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddCustomer;
