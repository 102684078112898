import React, { useState, useMemo } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';
import RowLimit from './RowLimit';
import SearchSummary from './SearchSummary'

const OrderList = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'orderId', direction: 'asc' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50); // Set default to 50

  const Order = [
    { 
      id: 1, 
      orderId: 'ORD001', 
      date: '2024-07-01', 
      customerName: 'Saidur', 
      productName: 'Product A', 
      quantity: '5', 
      amount: '500', 
      status: 'Pending' 
    },
    { 
      id: 2, 
      orderId: 'ORD002', 
      date: '2024-07-01', 
      customerName: 'Sumon', 
      productName: 'Product B', 
      quantity: '15', 
      amount: '5000', 
      status: 'Completed' 
    },
    { 
      id: 3, 
      orderId: 'ORD003', 
      date: '2024-07-02', 
      customerName: 'Ayesha', 
      productName: 'Product C', 
      quantity: '7', 
      amount: '700', 
      status: 'Shipped' 
    },
    { 
      id: 4, 
      orderId: 'ORD004', 
      date: '2024-07-03', 
      customerName: 'Rashid', 
      productName: 'Product D', 
      quantity: '20', 
      amount: '2000', 
      status: 'Delivered' 
    },
    { 
      id: 5, 
      orderId: 'ORD005', 
      date: '2024-07-04', 
      customerName: 'Fatima', 
      productName: 'Product E', 
      quantity: '12', 
      amount: '1200', 
      status: 'Pending' 
    },
    { 
      id: 6, 
      orderId: 'ORD006', 
      date: '2024-07-05', 
      customerName: 'Ali', 
      productName: 'Product F', 
      quantity: '8', 
      amount: '800', 
      status: 'Completed' 
    },
    { 
      id: 7, 
      orderId: 'ORD007', 
      date: '2024-07-06', 
      customerName: 'Sara', 
      productName: 'Product G', 
      quantity: '6', 
      amount: '600', 
      status: 'Shipped' 
    },
    { 
      id: 8, 
      orderId: 'ORD008', 
      date: '2024-07-07', 
      customerName: 'Jamal', 
      productName: 'Product H', 
      quantity: '18', 
      amount: '1800', 
      status: 'Delivered' 
    },
    { 
      id: 9, 
      orderId: 'ORD009', 
      date: '2024-07-08', 
      customerName: 'Mina', 
      productName: 'Product I', 
      quantity: '10', 
      amount: '1000', 
      status: 'Pending' 
    },
    { 
      id: 10, 
      orderId: 'ORD010', 
      date: '2024-07-09', 
      customerName: 'Khalid', 
      productName: 'Product J', 
      quantity: '25', 
      amount: '2500', 
      status: 'Completed' 
    },
    { 
      id: 11, 
      orderId: 'ORD011', 
      date: '2024-07-10', 
      customerName: 'Laila', 
      productName: 'Product K', 
      quantity: '9', 
      amount: '900', 
      status: 'Shipped' 
    },
    { 
      id: 12, 
      orderId: 'ORD012', 
      date: '2024-07-11', 
      customerName: 'Omar', 
      productName: 'Product L', 
      quantity: '14', 
      amount: '1400', 
      status: 'Delivered' 
    },
    { 
      id: 13, 
      orderId: 'ORD013', 
      date: '2024-07-12', 
      customerName: 'Zara', 
      productName: 'Product M', 
      quantity: '11', 
      amount: '1100', 
      status: 'Pending' 
    },
    { 
      id: 14, 
      orderId: 'ORD014', 
      date: '2024-07-13', 
      customerName: 'Ahmed', 
      productName: 'Product N', 
      quantity: '16', 
      amount: '1600', 
      status: 'Completed' 
    },
    { 
      id: 15, 
      orderId: 'ORD015', 
      date: '2024-07-14', 
      customerName: 'Nadia', 
      productName: 'Product O', 
      quantity: '22', 
      amount: '2200', 
      status: 'Shipped' 
    },
    { 
      id: 16, 
      orderId: 'ORD016', 
      date: '2024-07-15', 
      customerName: 'Hassan', 
      productName: 'Product P', 
      quantity: '13', 
      amount: '1300', 
      status: 'Delivered' 
    },
    { 
      id: 17, 
      orderId: 'ORD017', 
      date: '2024-07-16', 
      customerName: 'Amina', 
      productName: 'Product Q', 
      quantity: '17', 
      amount: '1700', 
      status: 'Pending' 
    },
    { 
      id: 18, 
      orderId: 'ORD018', 
      date: '2024-07-17', 
      customerName: 'Yasir', 
      productName: 'Product R', 
      quantity: '19', 
      amount: '1900', 
      status: 'Completed' 
    },
    { 
      id: 19, 
      orderId: 'ORD019', 
      date: '2024-07-18', 
      customerName: 'Reem', 
      productName: 'Product S', 
      quantity: '8', 
      amount: '800', 
      status: 'Shipped' 
    },
    { 
      id: 20, 
      orderId: 'ORD020', 
      date: '2024-07-19', 
      customerName: 'Samir', 
      productName: 'Product T', 
      quantity: '21', 
      amount: '2100', 
      status: 'Delivered' 
    }
  ];
  

  const filteredOrder = useMemo(() => Order.filter(order =>
    order.orderId.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.date.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.quantity.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
    order.status.toLowerCase().includes(searchQuery.toLowerCase())
  ), [searchQuery, Order]);

  const totalPages = Math.ceil(filteredOrder.length / rowsPerPage);
  const paginatedOrder = useMemo(() => filteredOrder.slice((page - 1) * rowsPerPage, page * rowsPerPage), [filteredOrder, page, rowsPerPage]);

//   Search Summary
  const totalBill = 1500.00;
  const totalReceived = 1200.00;
  const totalSettlement = 1000.00;
  const totalDue = totalBill - totalReceived - totalSettlement;
  const conversionRate = 107.50; // Example conversion rate from USD to BDT

  const sortedOrder = useMemo(() => [...paginatedOrder].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  }), [paginatedOrder, sortConfig]);

  const handleSort = (key) => {
    const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key, direction });
  };

  const handleMenuClick = (event, order) => {
    setAnchorEl(event.currentTarget);
    setCurrentOrder(order);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentOrder(null);
  };

  const handleView = () => {
    console.log(`Viewing order with id: ${currentOrder.id}`);
    handleMenuClose();
  };

  const handleEdit = () => {
    console.log(`Editing order with id: ${currentOrder.id}`);
    handleMenuClose();
  };

  const handleDelete = () => {
    console.log(`Deleting order with id: ${currentOrder.id}`);
    handleMenuClose();
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when rows per page changes
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        {/* Title Area */}
        <h2 className="text-2xl font-semibold text-gray-800">Manage Order</h2>
        
        {/* Action Buttons */}
        <div className="flex space-x-4">
          {/* Button for Adding New Order */}
          <Link to="/new-order">
            <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
              Create Order
            </button>
          </Link>
        </div>
      </div>

      <div className="card-block">
        {/* Header */}
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-gray-500">
            <RowLimit rowsPerPage={rowsPerPage} onRowsPerPageChange={handleRowsPerPageChange} />
          </div>
          <div className="flex items-center space-x-4">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-3/3"
            />
          </div>
        </div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                {['orderId', 'date', 'customerName', 'productName', 'quantity', 'amount', 'status'].map((key) => (
                  <th
                    key={key}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                    onClick={() => handleSort(key)}
                  >
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                      <span
                        className={`text-gray-500 ${sortConfig.key === key && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                      >
                        &#9650; {/* Unicode for up arrow */}
                      </span>
                      <span
                        className={`text-gray-500 ${sortConfig.key === key && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                      >
                        &#9660; {/* Unicode for down arrow */}
                      </span>
                    </div>
                  </th>
                ))}
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>

            <tbody className="bg-white divide-y divide-gray-200">
              {sortedOrder.map((order) => (
                <tr key={order.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{order.orderId}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{order.date}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.customerName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.productName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.quantity}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{order.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <IconButton onClick={(event) => handleMenuClick(event, order)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleMenuClose}
                    >
                      <MuiMenuItem onClick={handleView}>
                        <Visibility /> View
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleEdit}>
                        <Edit /> Edit
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleDelete}>
                        <Delete /> Delete
                      </MuiMenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Pagination */}
        <div className="mt-4 flex justify-end">
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            shape="rounded"
            variant="outlined"
          />
        </div>

        <SearchSummary
            totalBill={totalBill}
            totalReceived={totalReceived}
            totalSettlement={totalSettlement}
            totalDue={totalDue}
            conversionRate={conversionRate}
        />
      </div>
    </div>
  );
};

export default OrderList;
