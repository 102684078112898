import React from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Box } from '@mui/material';

const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'BDT' }).format(value);
};

const OrderPreview = ({ selectedCustomer, products, totalAmount, discount, vat, tax, grandTotal, onClose, onConfirm }) => {
  const displayProducts = products || [];

  return (
    <Card>
      <CardContent>
        <Button
          onClick={onClose}
          variant="text"
          color="secondary"
          style={{ position: 'absolute', top: 16, right: 16 }}
        >
          &times;
        </Button>
        <Typography variant="h5" gutterBottom>
          Order Preview
        </Typography>

        <Box marginBottom={4}>
          <Typography variant="h6" gutterBottom>
            Customer Information
          </Typography>
          {selectedCustomer ? (
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Typography variant="body1"><strong>Name:</strong> {selectedCustomer.name}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1"><strong>Phone:</strong> {selectedCustomer.phone}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body1"><strong>Address:</strong> {selectedCustomer.address}</Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography>No customer selected</Typography>
          )}
        </Box>

        <Box marginBottom={4}>
          <Typography variant="h6" gutterBottom>
            Products
          </Typography>
          {displayProducts.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Group</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Quantity</TableCell>
                    <TableCell>Unit Price</TableCell>
                    <TableCell>VAT</TableCell>
                    <TableCell>Tax</TableCell>
                    <TableCell>Amount</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayProducts.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.group}</TableCell>
                      <TableCell>{product.category}</TableCell>
                      <TableCell>{product.qty}</TableCell>
                      <TableCell>{formatCurrency(product.unitPrice)}</TableCell>
                      <TableCell>{product.vat ? `${product.vat}%` : '0%'}</TableCell>
                      <TableCell>{product.tax ? `${product.tax}%` : '0%'}</TableCell>
                      <TableCell>{formatCurrency(product.amount)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Typography>No products added</Typography>
          )}
        </Box>

        <Box marginTop={4}>
          <Typography variant="h6" gutterBottom textAlign="right">
            Summary
          </Typography>
          <Box textAlign="right">
            <Box display="flex" justifyContent="space-between" padding="4px 0">
              <Typography variant="body1"><strong>Total Amount:</strong></Typography>
              <Typography variant="body1">{formatCurrency(totalAmount)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="4px 0">
              <Typography variant="body1"><strong>Discount:</strong></Typography>
              <Typography variant="body1">{formatCurrency(discount)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="4px 0">
              <Typography variant="body1"><strong>VAT:</strong></Typography>
              <Typography variant="body1">{formatCurrency(vat)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="4px 0">
              <Typography variant="body1"><strong>Tax:</strong></Typography>
              <Typography variant="body1">{formatCurrency(tax)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" padding="4px 0">
              <Typography variant="h6"><strong>Grand Total:</strong></Typography>
              <Typography variant="h6">{formatCurrency(grandTotal)}</Typography>
            </Box>
          </Box>
        </Box>

        <Box marginTop={4} textAlign="right">
          <Button
            onClick={onConfirm}
            variant="contained"
            color="primary"
          >
            Confirm Order
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
};

OrderPreview.propTypes = {
  selectedCustomer: PropTypes.object,
  products: PropTypes.array,
  totalAmount: PropTypes.number,
  discount: PropTypes.number,
  vat: PropTypes.number,
  tax: PropTypes.number,
  grandTotal: PropTypes.number,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired
};

export default OrderPreview;
