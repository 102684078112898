import React, { useState } from 'react';
import {
  TextField,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  Button,
  Grid,
  InputAdornment,
} from '@mui/material';
import { AccountCircle, ConfirmationNumber, LocalShipping, LocationOn } from '@mui/icons-material';
import AddStockItem from '../inventory/AddStockItem';

function NewPurchase() {
  const [stockEntries, setStockEntries] = useState([
    {
      productType: '',
      productName: '',
      manufactureDate: '',
      expireDate: '',
      batchNumber: '',
      qty: '',
      unitPrice: '',
      amount: '',
    },
  ]);

  const [formData, setFormData] = useState({
    referenceNo: '',
    supplierName: '',
    supplierType: '',
    territoryCode: '',
    identifyNumber: '',
  });

  const [errors, setErrors] = useState({});
  const [discountAmount, setDiscountAmount] = useState('');
  const [netAmount, setNetAmount] = useState('');

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const values = [...stockEntries];
    values[index][name] = value;

    // Update amount if qty and unitPrice are present
    if (name === 'qty' || name === 'unitPrice') {
      const qty = parseFloat(values[index].qty) || 0;
      const unitPrice = parseFloat(values[index].unitPrice) || 0;
      values[index].amount = (qty * unitPrice).toFixed(2);
    }

    setStockEntries(values);
    calculateTotals(); // Recalculate totals on stock entry change
  };

  const handleFormChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleAddEntry = () => {
    setStockEntries([
      ...stockEntries,
      {
        productType: '',
        productName: '',
        manufactureDate: '',
        expireDate: '',
        batchNumber: '',
        qty: '',
        unitPrice: '',
        amount: '',
      },
    ]);
  };

  const handleRemoveEntry = (index) => {
    const values = [...stockEntries];
    values.splice(index, 1);
    setStockEntries(values);
    calculateTotals(); // Recalculate totals on stock entry removal
  };

  const calculateTotalAmount = () => {
    return stockEntries.reduce((total, entry) => total + parseFloat(entry.amount || 0), 0).toFixed(2);
  };

  const calculateTotals = () => {
    const totalAmount = parseFloat(calculateTotalAmount());
    const discount = parseFloat(discountAmount) || 0;
    const net = (totalAmount - discount).toFixed(2);
    setNetAmount(net);
  };

  const handleDiscountChange = (event) => {
    setDiscountAmount(event.target.value);
    calculateTotals();
  };

  const validateForm = () => {
    let formErrors = {};

    // Validate required fields
    if (!formData.referenceNo.trim()) {
      formErrors.referenceNo = 'Reference No is required';
    }
    if (!formData.supplierName.trim()) {
      formErrors.supplierName = 'Supplier Name is required';
    }
    if (!formData.supplierType) {
      formErrors.supplierType = 'Supplier Type is required';
    }
    if (!formData.territoryCode.trim()) {
      formErrors.territoryCode = 'Territory Code is required';
    }
    if (!formData.identifyNumber.trim()) {
      formErrors.identifyNumber = 'Identify Number is required';
    }

    // Validate stock entries
    stockEntries.forEach((entry, index) => {
      if (!entry.productName.trim()) {
        formErrors[`productName-${index}`] = 'Product Name is required';
      }
      if (!entry.qty || isNaN(entry.qty)) {
        formErrors[`qty-${index}`] = 'Quantity must be a number';
      }
      if (!entry.unitPrice || isNaN(entry.unitPrice)) {
        formErrors[`unitPrice-${index}`] = 'Unit Price must be a number';
      }
    });

    setErrors(formErrors);

    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Submit the form if valid
      console.log('Form submitted:', formData, stockEntries);
    }
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        {/* Title Area */}
        <h2 className="text-2xl font-semibold text-gray-800">New Purchase</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit}>
            {/* Purchase Info Section */}
            <div className="mb-8 pb-6 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                Purchase Info
              </h4>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Reference No"
                    name="referenceNo"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.referenceNo}
                    onChange={handleFormChange}
                    error={!!errors.referenceNo}
                    helperText={errors.referenceNo}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocalShipping className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Supplier Name"
                    name="supplierName"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.supplierName}
                    onChange={handleFormChange}
                    error={!!errors.supplierName}
                    helperText={errors.supplierName}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AccountCircle className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <FormControl fullWidth variant="outlined" margin="normal" error={!!errors.supplierType}>
                    <InputLabel>Supplier Type</InputLabel>
                    <Select
                      label="Supplier Type"
                      name="supplierType"
                      value={formData.supplierType}
                      onChange={handleFormChange}
                    >
                      <MenuItem value="type1">Type 1</MenuItem>
                      <MenuItem value="type2">Type 2</MenuItem>
                    </Select>
                    {errors.supplierType && <p className="text-red-500 text-sm">{errors.supplierType}</p>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Territory Code"
                    name="territoryCode"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.territoryCode}
                    onChange={handleFormChange}
                    error={!!errors.territoryCode}
                    helperText={errors.territoryCode}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LocationOn className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                  <TextField
                    label="Identify Number (Lot or Screen)"
                    name="identifyNumber"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={formData.identifyNumber}
                    onChange={handleFormChange}
                    error={!!errors.identifyNumber}
                    helperText={errors.identifyNumber}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ConfirmationNumber className="text-customGreen" />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </div>

            {/* Product Info Section */}
            <AddStockItem
              stockEntries={stockEntries}
              handleChange={handleChange}
              handleAddEntry={handleAddEntry}
              handleRemoveEntry={handleRemoveEntry}
              errors={errors} // Pass errors to handle product validation
            />

            {/* Total Amount Section */}
            <div className="mt-8 flex justify-end">
              <div className="w-full max-w-3xl border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">
                  Total Amount
                </h4>
                <TextField
                  label="Total Amount"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={calculateTotalAmount()}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  label="Discount Amount"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={discountAmount}
                  onChange={handleDiscountChange}
                />
                <TextField
                  label="Net Amount"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={netAmount}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>

            {/* Buttons */}
            <div className="flex space-x-4 mt-5">
              <Button
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setFormData({
                    referenceNo: '',
                    supplierName: '',
                    supplierType: '',
                    territoryCode: '',
                    identifyNumber: '',
                  });
                  setStockEntries([
                    {
                      productType: '',
                      productName: '',
                      manufactureDate: '',
                      expireDate: '',
                      batchNumber: '',
                      qty: '',
                      unitPrice: '',
                      amount: '',
                    },
                  ]);
                  setDiscountAmount('');
                  setNetAmount('');
                }}
              >
                Clear
              </Button>
              <Button variant="outlined" color="info">
                Preview
              </Button>
              <Button variant="outlined" color="success">
                Print
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default NewPurchase;
