import React, { useState } from 'react'
import Button from '../../components/common/Button';
import DepartmentList from './DepartmentList';

function AddDepartment() {
  const initialDepartmentState = {
    departmentName: '',
    description: '',
  };
  const [department, setDepartment] = useState(initialDepartmentState);


  const handleChange = (e) => {
    const {name, value} = e.target;
    setDepartment({
      ...department,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to add department
    console.log('Department added:', department)
    // Clear the form
    setDepartment(initialDepartmentState)
  };

  return (
    <dev className='add-department flex'>
        <div className="w-full lg:w-1/3 p-4">
        <h2 className="text-xl font-bold mb-4">Add Department</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-gray-700">Department Name</label>
              <input
                type="text"
                name="departmentName"
                value={department.departmentName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder="Enter department name"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Description</label>
              <textarea
                name="description"
                value={department.description}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder="Enter description"
              />
            </div>
          </div>
          <div className="mt-4">
            <Button Name = "Add Department"/>
          </div>
        </form>
      </div>
      <div className="w-full lg:w-2/3 p-4">
        <DepartmentList /> 
      </div>
    </dev>
  )
}

export default AddDepartment