import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Typography, Paper, Divider, Grid, List, ListItem, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// Sample data for demonstration
const sampleOrderData = {
  orderInvoice: 'INV001',
  orderDate: '2024-07-01',
  orderBy: 'Saidur',
  numberOfProduct: '5',
  status: 'Pending',
  customer: {
    name: 'Saidur Rahman',
    email: 'saidur@example.com',
    phone: '123-456-7890',
    address: '123 Main St, Cityville, Country'
  },
  items: [
    { id: 1, name: 'Product 1', quantity: 2, price: 100 },
    { id: 2, name: 'Product 2', quantity: 3, price: 150 }
  ]
};

const formatCurrency = (amount) => {
  return `৳${amount.toLocaleString('en-BD')}`;
};

const GenerateSales = () => {
  const { orderId } = useParams(); // Get the orderId from the URL
  const [order, setOrder] = useState(null);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogType, setDialogType] = useState(''); // 'approve' or 'reject'
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate fetching the order details
    try {
      // In real application, replace this with API call
      if (orderId) {
        // Simulate an API call delay
        setTimeout(() => {
          setOrder(sampleOrderData);
        }, 1000);
      } else {
        throw new Error('Order ID not provided');
      }
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    }
  }, [orderId]);

  const handleDialogOpen = (type) => {
    setDialogType(type);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleApprove = () => {
    console.log('Order approved:', orderId);
    handleDialogClose();
    // Add your approve logic here
    navigate('/list-sales');
  };

  const handleReject = () => {
    console.log('Order rejected:', orderId);
    handleDialogClose();
    // Add your reject logic here
    navigate('/list-sales');
  };

  if (error) {
    return (
      <div style={{ padding: '20px' }}>
        <Typography variant="h6" color="error">
          Error: {error}
        </Typography>
      </div>
    ); // Handle error state
  }

  if (!order) {
    return (
      <div style={{ padding: '20px' }}>
        <Typography variant="h6">Loading...</Typography>
      </div>
    ); // Handle loading state
  }

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        Sales Details
      </Typography>
      <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Order Information
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <Typography variant="body1"><strong>Invoice ID:</strong> {order.orderInvoice}</Typography>
            <Typography variant="body1"><strong>Order Date:</strong> {order.orderDate}</Typography>
            <Typography variant="body1"><strong>Ordered By:</strong> {order.orderBy}</Typography>
            <Typography variant="body1"><strong>Number of Products:</strong> {order.numberOfProduct}</Typography>
            <Typography variant="body1"><strong>Status:</strong> {order.status}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="h6" gutterBottom>
              Customer Details
            </Typography>
            <Typography variant="body1"><strong>Name:</strong> {order.customer.name}</Typography>
            <Typography variant="body1"><strong>Email:</strong> {order.customer.email}</Typography>
            <Typography variant="body1"><strong>Phone:</strong> {order.customer.phone}</Typography>
            <Typography variant="body1"><strong>Address:</strong> {order.customer.address}</Typography>
          </Grid>
        </Grid>
        <Divider style={{ margin: '20px 0' }} />
        <Typography variant="h6" gutterBottom>
          Items
        </Typography>
        <List>
          {order.items.map(item => (
            <ListItem key={item.id}>
              <ListItemText
                primary={item.name}
                secondary={`Quantity: ${item.quantity} - Price: ${formatCurrency(item.price)}`}
              />
            </ListItem>
          ))}
        </List>
      </Paper>
      <div style={{ marginTop: '20px' }}>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleDialogOpen('approve')}
          style={{ marginRight: '10px' }}
        >
          Approve
        </Button>
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDialogOpen('reject')}
        >
          Reject
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => navigate('/list-sales')}
          style={{ marginLeft: '10px' }}
        >
          Back to Sales List
        </Button>
      </div>

      {/* Dialog for confirmation */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogType === 'approve' ? 'Approve Order' : 'Reject Order'}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Are you sure you want to {dialogType} this order?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={dialogType === 'approve' ? handleApprove : handleReject}
            color={dialogType === 'approve' ? 'success' : 'error'}
          >
            {dialogType === 'approve' ? 'Approve' : 'Reject'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GenerateSales;
