import React from 'react'
import EditBtn from '../../components/common/EditBtn'

function DepartmentList() {
    const departments = [
        { id: 1, name: 'Sales', description: 'This is a example fo description for test..!', count: 15},
        { id: 2, name: 'Marketing', description: 'This is a example fo description for test..!', count: 10},
        { id: 3, name: 'Distribution', description: 'This is a example fo description for test..!', count: 25},
        { id: 4, name: 'IT', description: 'This is a example fo description for test..!', count: 8},
        { id: 5, name: 'Finance', description: 'This is a example fo description for test..!', count: 12},
        { id: 6, name: 'Medical', description: 'This is a example fo description for test..!', count: 6},
        { id: 7, name: 'HQ', description: 'This is a example fo description for test..!', count: 5},
        { id: 8, name: 'Legal', description: 'This is a example fo description for test..!', count: 12},
        // Add more department as needed
      ];
      
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Department List</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Name</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Description</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Count</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
            {departments.map((department) => (
                <tr key={department.id} className='hover:bg-gray-100'>
                    <td className="py-2 px-4 border-b border-gray-300">{department.name}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{department.description}</td>
                    <td className="py-2 px-4 border-b border-gray-300">{department.count}</td>
                    <td className='py-2 px-4 border-b border-gray-300'>
                        <EditBtn Name="Edit" Path="#" />
                    </td>
                </tr>
            ))}
        </tbody>
      </table>
    </>
  )
}

export default DepartmentList