import React, { useState } from 'react';
import { Avatar, Button, Grid, Paper, Tab, Tabs, Typography, Box } from '@mui/material';
import { 
  AccountCircle, 
  Phone, 
  Wc, 
  Bloodtype, 
  Event, 
  Badge, 
  Settings,
  TrendingUp,
  Info
} from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ViewEmployee() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Sample employee data
  const employee = {
    id: 'E12345',
    name: 'John Doe',
    mobile1: '123-456-7890',
    mobile2: '098-765-4321',
    dob: '1990-01-01',
    joinDate: '1990-01-01',
    gender: 'Male',
    bloodGroup: 'O+',
    maritalStatus: 'Single',
    nid: '1234567890',
    country: 'USA',
    district: 'California',
    role: 'Developer',
    department: 'IT',
    location: 'San Francisco',
    avatar: 'https://via.placeholder.com/200',
    about: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum.',
    salesTarget: {
      monthly: 100000,
      achieved: 75000,
      pending: 25000,
    },
  };

  // Bar chart data
  const chartData = {
    labels: ['Monthly Target', 'Achieved', 'Pending'],
    datasets: [
      {
        label: 'Sales Target',
        data: [employee.salesTarget.monthly, employee.salesTarget.achieved, employee.salesTarget.pending],
        backgroundColor: ['#ff6347', '#4caf50', '#ffa500'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: $${value.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div className="mt-5 px-4">
      <div className="flex justify-between items-center mb-6">
        {/* Title Area */}
        <h2 className="text-3xl font-semibold text-gray-800">Employee Profile</h2>
        <Button variant="contained" color="primary">Edit Profile</Button>
      </div>

      <Grid container spacing={4}>
        {/* Left Side: Profile Card and About Us */}
        <Grid item xs={12} md={4}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
            <div className="flex items-center mb-4">
              <Avatar src={employee.avatar} alt={employee.name} className="w-48 h-48 mr-4" />
              <div>
                <Typography variant="h5" className="font-semibold">{employee.name}</Typography>
                <Typography variant="body1" className="text-gray-600">ID: {employee.id}</Typography>
                <Typography variant="body1" className="text-gray-600">Join Date: {employee.joinDate}</Typography>
                <Typography variant="body1" className="text-gray-600">Designation: {employee.role}</Typography>
              </div>
            </div>
          </Paper>
          
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">Sales Target</Typography>
              <div className="mb-4">
                <Bar data={chartData} options={chartOptions} />
              </div>
            </Paper>
            <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">About Us</Typography>
              <Typography variant="body1" className="text-gray-700">{employee.about}</Typography>
            </Paper>
        </Grid>

        {/* Right Side: Tabs */}
        <Grid item xs={12} md={8}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
            <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab label="Activity" icon={<AccountCircle />} />
              <Tab label="Timeline" icon={<Event />} />
              <Tab label="Profile Info" icon={<Info />} />
              <Tab label="Settings" icon={<Settings />} />
            </Tabs>
            <Box className="mt-4 p-4">
              {tabIndex === 0 && <Typography>Activity Content</Typography>}
              {tabIndex === 1 && <Typography>Timeline Content</Typography>}
              {tabIndex === 2 && (
                <div className="mb-4">
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Badge className="text-customGreen mr-2" /> Employee ID: {employee.id}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Phone className="text-customGreen mr-2" /> Mobile 1: {employee.mobile1}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Phone className="text-customGreen mr-2" /> Mobile 2: {employee.mobile2}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Event className="text-customGreen mr-2" /> Date of Birth: {employee.dob}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Wc className="text-customGreen mr-2" /> Gender: {employee.gender}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Bloodtype className="text-customGreen mr-2" /> Blood Group: {employee.bloodGroup}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Badge className="text-customGreen mr-2" /> Marital Status: {employee.maritalStatus}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Badge className="text-customGreen mr-2" /> NID: {employee.nid}
                  </Typography>
                </div>
              )}
              {tabIndex === 3 && <Typography>Settings Content</Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewEmployee;
