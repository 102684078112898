import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  InputAdornment,
} from '@mui/material';
import { AccountCircle, Phone, LocationOn, PhotoCamera } from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

function AddDoctor() {
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    surname: '',
    address: '',
    city: '',
    district: '',
    mobile: '',
    phone: '',
    gender: '',
    doctorType: '',
    description: ''
  });
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleAvatarChange = (e) => {
    const file = e.target.files[0];
    if (file && ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
      setAvatar(file.name);
      setAvatarPreview(URL.createObjectURL(file));
      setFormErrors((prev) => ({ ...prev, avatar: '' }));
    } else {
      setAvatar(null);
      setAvatarPreview(null);
      setFormErrors((prev) => ({ ...prev, avatar: 'Invalid file type. Please upload JPEG, JPG, or PNG.' }));
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    setFormErrors((prev) => ({ ...prev, [name]: '' }));
  };

  const validateForm = () => {
    let errors = {};

    if (!formValues.firstName.trim()) {
      errors.firstName = 'First name is required';
    }
    if (!formValues.lastName.trim()) {
      errors.lastName = 'Last name is required';
    }
    if (!formValues.mobile.trim()) {
      errors.mobile = 'Mobile number is required';
    } else if (!/^\+880\d{10}$/.test(formValues.mobile)) {
      errors.mobile = 'Invalid mobile number format. Example: +8801700000121';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Form submitted', { ...formValues, avatar });

      // Clear the form
      handleReset();

      // Show success notification
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setFormValues({
      firstName: '',
      lastName: '',
      surname: '',
      address: '',
      city: '',
      district: '',
      mobile: '',
      phone: '',
      gender: '',
      doctorType: '',
      description: ''
    });
    setAvatar(null);
    setAvatarPreview(null);
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add New Record</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit} onReset={handleReset}>
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Basic Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  value={formValues.firstName}
                  onChange={handleInputChange}
                  error={!!formErrors.firstName}
                  helperText={formErrors.firstName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  name="lastName"
                  value={formValues.lastName}
                  onChange={handleInputChange}
                  error={!!formErrors.lastName}
                  helperText={formErrors.lastName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Surname"
                  variant="outlined"
                  fullWidth
                  name="surname"
                  value={formValues.surname}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />

                <TextField
                  label="Address"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  name="address"
                  value={formValues.address}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="col-span-2 lg:col-span-3 mt-1"
                />
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  name="city"
                  value={formValues.city}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="District"
                  variant="outlined"
                  fullWidth
                  name="district"
                  value={formValues.district}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Mobile"
                  variant="outlined"
                  fullWidth
                  name="mobile"
                  value={formValues.mobile}
                  onChange={handleInputChange}
                  error={!!formErrors.mobile}
                  helperText={formErrors.mobile}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={formValues.phone}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    name="gender"
                    value={formValues.gender}
                    onChange={handleInputChange}
                    className="text-customGreen"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    name="doctorType"
                    value={formValues.doctorType}
                    onChange={handleInputChange}
                    className="text-customGreen"
                  >
                    <MenuItem value="doctor">Doctor</MenuItem>
                    <MenuItem value="chemist">Chemist</MenuItem>
                  </Select>
                </FormControl>

                <TextField
                  label="Short Description"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  name="description"
                  value={formValues.description}
                  onChange={handleInputChange}
                  inputProps={{ maxLength: 1000 }}
                  helperText="Max 1000 characters"
                  className="col-span-2 lg:col-span-3 mt-1"
                />

                <div className="mt-1">
                  <label htmlFor="avatar-upload" className="w-full cursor-pointer">
                    <div className="border-2 border-dashed border-gray-300 rounded-md p-4 w-64 flex flex-col items-center justify-center hover:border-customGreen">
                      {avatarPreview ? (
                        <img src={avatarPreview} alt="Avatar Preview" className="w-full h-auto rounded-md mb-2" />
                      ) : (
                        <>
                          <PhotoCamera className="text-customGreen mb-2" />
                          <span className="text-customGreen text-center">Upload Avatar (JPEG, JPG, PNG)</span>
                        </>
                      )}
                      <span className="text-gray-600 mt-2 text-center">
                        {avatar ? `Selected file: ${avatar}` : 'No file selected'}
                      </span>
                    </div>
                  </label>
                  <input
                    id="avatar-upload"
                    type="file"
                    accept=".jpeg, .jpg, .png"
                    className="hidden"
                    onChange={handleAvatarChange}
                  />
                  {formErrors.avatar && <p className="text-red-600 text-sm mt-1">{formErrors.avatar}</p>}
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>

      {/* Snackbar Notification */}
      <Notification
        open={openSnackbar}
        message="Form submitted successfully!"
        severity="success"
        onClose={handleCloseSnackbar}
      />
    </div>
  );
}

export default AddDoctor;
