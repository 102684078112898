import React, { useState } from 'react';
import { Avatar, Button, Grid, Paper, Tab, Tabs, Typography, Box, TextField } from '@mui/material';
import { 
  AccountCircle, 
  Phone, 
  Wc, 
  Bloodtype, 
  Event, 
  Badge, 
  Settings,
  Info,
  MedicalServices,
  CalendarToday
} from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  
function DoctorProfile() {
  const [tabIndex, setTabIndex] = useState(0);
  const [schedules, setSchedules] = useState([
    { day: 'Monday', startTime: '09:00', endTime: '17:00' },
    { day: 'Tuesday', startTime: '09:00', endTime: '17:00' },
    { day: 'Wednesday', startTime: '09:00', endTime: '17:00' },
    { day: 'Thursday', startTime: '09:00', endTime: '17:00' },
    { day: 'Friday', startTime: '09:00', endTime: '17:00' },
  ]);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  
  const handleScheduleChange = (index, field, value) => {
    const newSchedules = [...schedules];
    newSchedules[index][field] = value;
    setSchedules(newSchedules);
  };

  // Sample doctor data
  const doctor = {
    id: 'D12345',
    name: 'Dr. Jane Smith',
    mobile: '123-456-7890',
    dob: '1980-05-15',
    gender: 'Female',
    bloodGroup: 'A+',
    specialization: 'Cardiologist',
    department: 'Cardiology',
    location: 'New York',
    avatar: 'https://via.placeholder.com/200',
    about: 'Dr. Jane Smith is a renowned Cardiologist with over 20 years of experience in heart diseases and treatment.',
    workSchedule: [
      { day: 'Monday', startTime: '09:00', endTime: '17:00' },
      { day: 'Tuesday', startTime: '09:00', endTime: '17:00' },
      { day: 'Wednesday', startTime: '09:00', endTime: '17:00' },
      { day: 'Thursday', startTime: '09:00', endTime: '17:00' },
      { day: 'Friday', startTime: '09:00', endTime: '17:00' },
    ],
    patientStats: {
      monthly: 200,
      seen: 150,
      pending: 50,
    },
  };

  
  // Bar chart data
  const chartData = {
    labels: ['Monthly Patients', 'Seen Patients', 'Pending Patients'],
    datasets: [
      {
        label: 'Patient Statistics',
        data: [doctor.patientStats.monthly, doctor.patientStats.seen, doctor.patientStats.pending],
        backgroundColor: ['#4caf50', '#2196f3', '#ff9800'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: ${value}`;
          }
        }
      }
    }
  };

  return (
    <div className="mt-5 px-4">
      <div className="flex justify-between items-center mb-6">
        {/* Title Area */}
        <h2 className="text-3xl font-semibold text-gray-800">Doctor Profile</h2>
        <Button variant="contained" color="primary">Edit Profile</Button>
      </div>

      <Grid container spacing={4}>
        {/* Left Side: Profile Card and About Us */}
        <Grid item xs={12} md={4}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
            <div className="flex items-center mb-4">
              <Avatar src={doctor.avatar} alt={doctor.name} className="w-48 h-48 mr-4" />
              <div>
                <Typography variant="h5" className="font-semibold">{doctor.name}</Typography>
                <Typography variant="body1" className="text-gray-600">ID: {doctor.id}</Typography>
                <Typography variant="body1" className="text-gray-600">Specialization: {doctor.specialization}</Typography>
                <Typography variant="body1" className="text-gray-600">Department: {doctor.department}</Typography>
                <Typography variant="body1" className="text-gray-600">Location: {doctor.location}</Typography>
              </div>
            </div>
            <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">Patient Statistics</Typography>
              <div className="mb-4">
                <Bar data={chartData} options={chartOptions} />
              </div>
            </Paper>
            <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">About Us</Typography>
              <Typography variant="body1" className="text-gray-700">{doctor.about}</Typography>
            </Paper>
          </Paper>
        </Grid>

      
        {/* Right Side: Tabs and Work Schedule */}
        <Grid item xs={12} md={8}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
            <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab label="Activity" icon={<AccountCircle />} />
              <Tab label="Timeline" icon={<Event />} />
              <Tab label="Profile Info" icon={<Info />} />
              <Tab label="Settings" icon={<Settings />} />
            </Tabs>
            <Box className="mt-4 p-4">
              {tabIndex === 0 && <Typography>Activity Content</Typography>}
              {tabIndex === 1 && (
                <div>
                  <Typography variant="h6" className="text-customGreen mb-4">Work Schedule</Typography>
                  {schedules.map((schedule, index) => (
                    <div key={index} className="mb-4">
                      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-2">
                        <TextField
                          label="Day"
                          variant="outlined"
                          fullWidth
                          value={schedule.day}
                          onChange={(e) => handleScheduleChange(index, 'day', e.target.value)}
                          className="mt-1"
                        />
                        <TextField
                          label="Start Time"
                          type="time"
                          variant="outlined"
                          fullWidth
                          value={schedule.startTime}
                          onChange={(e) => handleScheduleChange(index, 'startTime', e.target.value)}
                          className="mt-1"
                        />
                        <TextField
                          label="End Time"
                          type="time"
                          variant="outlined"
                          fullWidth
                          value={schedule.endTime}
                          onChange={(e) => handleScheduleChange(index, 'endTime', e.target.value)}
                          className="mt-1"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {tabIndex === 2 && (
                <Typography>
                  <div className="mb-4">
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Badge className="text-customGreen mr-2" /> Doctor ID: {doctor.id}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Phone className="text-customGreen mr-2" /> Mobile 1: {doctor.mobile1}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Phone className="text-customGreen mr-2" /> Mobile 2: {doctor.mobile2}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Event className="text-customGreen mr-2" /> Date of Birth: {doctor.dob}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Wc className="text-customGreen mr-2" /> Gender: {doctor.gender}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Bloodtype className="text-customGreen mr-2" /> Blood Group: {doctor.bloodGroup}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Badge className="text-customGreen mr-2" /> Marital Status: {doctor.maritalStatus}
                    </Typography>
                    <Typography variant="body2" className="flex items-center mb-2">
                      <Badge className="text-customGreen mr-2" /> NID: {doctor.nid}
                    </Typography>
                  </div>
                </Typography>
              )}
              {tabIndex === 3 && <Typography>Settings Content</Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default DoctorProfile;
