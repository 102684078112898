import React from 'react'

function AddNewUser() {
  return (
    <div className='user-list p-4'>
      <h2 className='text-xl font-bold mb-4'>Add New User</h2>
    </div>
  )
}

export default AddNewUser