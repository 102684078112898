import React from 'react';
import { Card, CardContent, Typography, Grid, LinearProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Divider } from '@mui/material';
import { MedicalServices, People, CalendarToday, ShoppingCart, CheckCircle } from '@mui/icons-material';
import CountUp from 'react-countup';
import DoctorsCardView from '../Doctor/DoctorsCardView';
import UpcomingAppointmentsCardView from '../Doctor/UpcomingAppointmentsCardView';

const Dashboard = () => {
  // Sample data for the cards
  const cardData = [
    {
      title: 'Doctors',
      number: 123,
      icon: (
        <MedicalServices
          fontSize="large"
          className="text-white bg-blue-500 rounded-full"
        />
      ),
      color: 'bg-blue-500',
    },
    {
      title: 'Employees',
      number: 456,
      icon: (
        <People
          fontSize="large"
          className="text-white bg-green-500 rounded-full"
        />
      ),
      color: 'bg-green-500',
    },
    {
      title: 'Schedules',
      number: 789,
      icon: (
        <CalendarToday
          fontSize="large"
          className="text-white bg-purple-500 rounded-full"
        />
      ),
      color: 'bg-purple-500',
    },
    {
      title: 'Products',
      number: 101,
      icon: (
        <ShoppingCart
          fontSize="large"
          className="text-white bg-red-500 rounded-full"
        />
      ),
      color: 'bg-red-500',
    },
  ];

  // Sample data for progress and sales
  const targetData = {
    totalTarget: 500000, // in BDT
    done: 320000, // in BDT
    due: 180000, // in BDT
  };

  const salesData = {
    totalSales: 1500000, // in BDT
    todaySales: 50000, // in BDT
    monthlyOrders: 120,
    orderAmount: 600000, // in BDT
  };

  const progress = (targetData.done / targetData.totalTarget) * 100;

  // Sample data for today's work plan
  const workPlanData = [
    {
      date: '2024-09-04',
      time: '09:00 AM',
      purpose: 'Complete monthly sales report',
      place: 'Office Meeting Room',
    },
    {
      date: '2024-09-04',
      time: '11:00 AM',
      purpose: 'Review upcoming appointments for the day',
      place: 'Office Conference Room',
    },
    {
      date: '2024-09-04',
      time: '02:00 PM',
      purpose: 'Check and restock inventory levels',
      place: 'Warehouse',
    },
    {
      date: '2024-09-04',
      time: '04:00 PM',
      purpose: 'Plan and schedule team meetings',
      place: 'Office',
    },
  ];

  return (
    <>
      <div className="p-6">
        {/* Card Data */}
        <Grid container spacing={4}>
          {cardData.map((card, index) => (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Card className="w-full">
                <CardContent className="flex items-center justify-between">
                  <div className="flex items-center">
                    <div className={`rounded-full p-4 ${card.color}`}>
                      {card.icon}
                    </div>
                  </div>
                  <div className="text-right">
                    <Typography variant="h4" component="div" className="font-semibold text-gray-800">
                      <CountUp end={card.number} duration={2.75} />
                    </Typography>
                    <Typography variant="body2" className={`p-2 rounded-xl text-white ${card.color}`}>
                      {card.title} <CheckCircle />
                    </Typography>
                  </div>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Progress Bar Section */}
        <div className="p-6 mt-6 border border-gray-300 rounded-md">
          <Typography variant="h6" className="font-semibold text-gray-800 mb-4">
            Monthly Target Progress
          </Typography>
          <div className="flex items-center justify-between mb-2">
            <Typography variant="body1" className="text-gray-600">
              Total Target: ৳{targetData.totalTarget.toLocaleString()}
            </Typography>
            <Typography variant="body1" className="text-gray-600">
              Done: ৳{targetData.done.toLocaleString()}
            </Typography>
            <Typography variant="body1" className="text-gray-600">
              Due: ৳{targetData.due.toLocaleString()}
            </Typography>
          </div>
          <LinearProgress
            variant="determinate"
            value={progress}
            className="mb-2"
            sx={{
              height: 10,
              borderRadius: 5,
              backgroundColor: '#e0e0e0', // Light gray background for the progress bar
              '& .MuiLinearProgress-bar': {
                backgroundColor: '#4caf50', // Green color for the progress bar
              },
            }}
          />
          <Typography variant="body2" className="text-gray-600 text-right">
            {Math.round(progress)}% Completed
          </Typography>
        </div>

        {/* Sales and Orders Section */}
        <div className="p-6 mt-6 border border-gray-300 rounded-md">
          <Typography variant="h6" className="font-semibold text-gray-800 mb-4">
            Sales and Orders
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="w-full bg-blue-100">
                <CardContent>
                  <Typography variant="h6" className="font-semibold text-blue-800">
                    Total Sales
                  </Typography>
                  <Typography variant="h5" className="text-blue-600">
                    ৳{salesData.totalSales.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="w-full bg-green-100">
                <CardContent>
                  <Typography variant="h6" className="font-semibold text-green-800">
                    Today's Sales
                  </Typography>
                  <Typography variant="h5" className="text-green-600">
                    ৳{salesData.todaySales.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="w-full bg-yellow-100">
                <CardContent>
                  <Typography variant="h6" className="font-semibold text-yellow-800">
                    Monthly Orders
                  </Typography>
                  <Typography variant="h5" className="text-yellow-600">
                    {salesData.monthlyOrders}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <Card className="w-full bg-red-100">
                <CardContent>
                  <Typography variant="h6" className="font-semibold text-red-800">
                    Order Amount
                  </Typography>
                  <Typography variant="h5" className="text-red-600">
                    ৳{salesData.orderAmount.toLocaleString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </div>

        {/* Today's Work Plan Table */}
        <div className="p-6 mt-6 border border-gray-300 rounded-md">
          <Typography variant="h6" className="font-semibold text-gray-800 mb-4">
            Today's Work Plan
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Time</TableCell>
                  <TableCell>Purpose</TableCell>
                  <TableCell>Place</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workPlanData.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.time}</TableCell>
                    <TableCell>{item.purpose}</TableCell>
                    <TableCell>{item.place}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      <div className="p-6 flex">
        {/* Left section (3/4) */}
        <div className="w-3/4 pr-4">
          <UpcomingAppointmentsCardView />
        </div>

        {/* Right section (1/4) */}
        <div className="w-1/4 pl-4">
          <DoctorsCardView />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
