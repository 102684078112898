import React, { useState } from 'react';
import { TextField, FormControl, InputLabel, MenuItem, Select, InputAdornment, Button } from '@mui/material';
import { AccountCircle, Phone, Email, Public, Home, LocationOn } from '@mui/icons-material';

function AddSupplier() {
  const [avatar, setAvatar] = useState(null);

  const handleAvatarChange = (e) => {
    if (e.target.files.length > 0) {
      setAvatar(e.target.files[0].name);
    }
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        {/* Title Area */}
        <h2 className="text-2xl font-semibold text-gray-800">Supplier Details</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form>
            {/* Basic Info Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Basic Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Type</InputLabel>
                  <Select
                    label="Type"
                    defaultValue=""
                    className="text-customGreen"
                  >
                    <MenuItem value="local">Local</MenuItem>
                    <MenuItem value="international">International</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />

              <TextField
              label="Address"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOn className="text-customGreen" />
                  </InputAdornment>
                ),
              }}
              className="col-span-2 lg:col-span-3 mt-1"
            />

                <TextField
                  label="Mobile 1"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Mobile 2"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Web"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Public className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="District"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
               
              </div>
            </div>


            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Billing Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">

                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              
              <TextField
                  label="Mobile 1"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />

              <TextField
              label="Address"
              variant="outlined"
              multiline
              rows={3}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LocationOn className="text-customGreen" />
                  </InputAdornment>
                ),
              }}
              className="col-span-2 lg:col-span-3 mt-1"
            />



              </div>
            </div>

            {/* Contact Person Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Contact Person:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Mobile"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Email className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddSupplier;
