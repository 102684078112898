import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TextField, Button } from '@mui/material';

function AddStockItem({ stockEntries, handleChange, handleAddEntry, handleRemoveEntry }) {
  return (
    <div className="overflow-x-auto">
      <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>SL</TableCell>
                <TableCell>Product Type or Group</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Manufacture Date</TableCell>
                <TableCell>Expire Date</TableCell>
                <TableCell>Batch Number</TableCell>
                <TableCell>Qty</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stockEntries.map((entry, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <TextField
                      name="productType"
                      value={entry.productType}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="productName"
                      value={entry.productName}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="manufactureDate"
                      type="date"
                      value={entry.manufactureDate}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="expireDate"
                      type="date"
                      value={entry.expireDate}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="batchNumber"
                      value={entry.batchNumber}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="qty"
                      type="number"
                      value={entry.qty}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="unitPrice"
                      type="number"
                      value={entry.unitPrice}
                      onChange={(e) => handleChange(index, e)}
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      name="amount"
                      type="number"
                      value={entry.amount}
                      readOnly
                      fullWidth
                      size="small"
                      variant="outlined"
                    />
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveEntry(index)}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddEntry}
          className="mt-4"
        >
          Add New Entry
        </Button>
      </div>
    </div>
  );
}

export default AddStockItem;
