// utils/categoryUtils.js

export const flattenCategories = (categories) => {
    let result = [];
    
    const traverse = (cats) => {
      cats.forEach(cat => {
        result.push(cat);
        if (cat.subcategories) {
          traverse(cat.subcategories);
        }
      });
    };
    
    traverse(categories);
    return result;
  };
  