import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment
} from '@mui/material';
import { AccountCircle, LocationOn, VideoCall, Assignment, Event, CalendarToday } from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

const AddMeetingSchedule = () => {
  const [doctor, setDoctor] = useState('');
  const [schedulePerson, setSchedulePerson] = useState('');
  const [meetingPurpose, setMeetingPurpose] = useState('');
  const [startDateTime, setStartDateTime] = useState('');
  const [location, setLocation] = useState('');
  const [description, setDescription] = useState('');
  const [meetingType, setMeetingType] = useState('');
  const [meetingLink, setMeetingLink] = useState('');
  const [meetingStatus, setMeetingStatus] = useState('draft');
  const [meetingSummary, setMeetingSummary] = useState('');
  const [formErrors, setFormErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false);

  // Example list of doctors
  const doctors = [
    { id: 1, name: 'Dr. John Doe', mobile: '123-456-7890', email: 'example1@example.com' },
    { id: 2, name: 'Dr. K Khan', mobile: '987-654-3210', email: 'example2@example.com' },
    // Add more doctors as needed
  ];

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
    setFormErrors((prev) => ({ ...prev, [event.target.name]: '' }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = {};

    if (!doctor) errors.doctor = 'Doctor selection is required';
    if (!meetingPurpose) errors.meetingPurpose = 'Meeting purpose is required';
    if (!startDateTime) errors.startDateTime = 'Start time is required';
    if (!location) errors.location = 'Location is required';
    if (!meetingType) errors.meetingType = 'Meeting type is required';
    if (meetingType === 'Virtual' && !meetingLink) errors.meetingLink = 'Meeting link is required';
    if (meetingStatus === 'complete' && !meetingSummary) errors.meetingSummary = 'Meeting summary is required';

    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      console.log({
        doctor,
        schedulePerson,
        meetingPurpose,
        startDateTime,
        location,
        description,
        meetingType,
        meetingLink,
        meetingStatus,
        meetingSummary,
      });

      handleReset();
      setOpenSnackbar(true);
    }
  };

  const handleReset = () => {
    setDoctor('');
    setSchedulePerson('');
    setMeetingPurpose('');
    setStartDateTime('');
    setLocation('');
    setDescription('');
    setMeetingType('');
    setMeetingLink('');
    setMeetingStatus('draft');
    setMeetingSummary('');
    setFormErrors({});
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Add Meeting Schedule</h2>
      </div>
      <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
            <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
              <CalendarToday className="text-customGreen mr-2" /> Meeting Info:
            </h4>

        <form onSubmit={handleSubmit} onReset={handleReset}>
          <Grid container spacing={2}>
            {/* Title and Doctor on the same line */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Title"
                variant="outlined"
                fullWidth
                name="schedulePerson"
                value={schedulePerson}
                onChange={handleInputChange(setSchedulePerson)}
                error={!!formErrors.title}
                helperText={formErrors.title}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AccountCircle className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Doctor</InputLabel>
                <Select
                  label="Doctor"
                  value={doctor}
                  onChange={handleInputChange(setDoctor)}
                  name="doctor"
                  error={!!formErrors.doctor}
                  className="text-customGreen"
                >
                  {doctors.map((doc) => (
                    <MenuItem key={doc.id} value={doc.name}>
                      {doc.name} (Mobile: {doc.mobile})
                    </MenuItem>
                  ))}
                </Select>
                {formErrors.doctor && <p className="text-red-600 text-sm mt-1">{formErrors.doctor}</p>}
              </FormControl>
            </Grid>

            {/* Location and Start Time on the same line */}
            <Grid item xs={12} sm={6}>
              <TextField
                label="Location"
                variant="outlined"
                fullWidth
                name="location"
                value={location}
                onChange={handleInputChange(setLocation)}
                error={!!formErrors.location}
                helperText={formErrors.location}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                label="Start Time"
                type="datetime-local"
                variant="outlined"
                fullWidth
                name="startDateTime"
                value={startDateTime}
                onChange={handleInputChange(setStartDateTime)}
                InputLabelProps={{ shrink: true }}
                error={!!formErrors.startDateTime}
                helperText={formErrors.startDateTime}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Event className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}
                className="mt-1"
              />
            </Grid>

            {/* Purpose and Meeting Type on the same line */}
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Meeting Purpose</InputLabel>
                <Select
                  label="Meeting Purpose"
                  value={meetingPurpose}
                  onChange={handleInputChange(setMeetingPurpose)}
                  name="meetingPurpose"
                  error={!!formErrors.meetingPurpose}
                  className="text-customGreen"
                >
                  <MenuItem value="Consultation">Consultation</MenuItem>
                  <MenuItem value="Follow-up">Follow-up</MenuItem>
                  <MenuItem value="Review">Review</MenuItem>
                  <MenuItem value="Other">Other</MenuItem>
                </Select>
                {formErrors.meetingPurpose && <p className="text-red-600 text-sm mt-1">{formErrors.meetingPurpose}</p>}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Meeting Type</InputLabel>
                <Select
                  label="Meeting Type"
                  value={meetingType}
                  onChange={handleInputChange(setMeetingType)}
                  name="meetingType"
                  error={!!formErrors.meetingType}
                  className="text-customGreen"
                >
                  <MenuItem value="Physical">Physical</MenuItem>
                  <MenuItem value="Virtual">Virtual</MenuItem>
                </Select>
                {formErrors.meetingType && <p className="text-red-600 text-sm mt-1">{formErrors.meetingType}</p>}
              </FormControl>
            </Grid>

            {/* Conditionally render meeting link if Virtual is selected */}
            {meetingType === 'Virtual' && (
              <Grid item xs={12}>
                <TextField
                  label="Meeting Link"
                  variant="outlined"
                  fullWidth
                  name="meetingLink"
                  value={meetingLink}
                  onChange={handleInputChange(setMeetingLink)}
                  error={!!formErrors.meetingLink}
                  helperText={formErrors.meetingLink}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <VideoCall className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              </Grid>
            )}

            {/* Description on one line */}
            <Grid item xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                multiline
                rows={4}
                fullWidth
                name="description"
                value={description}
                onChange={handleInputChange(setDescription)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Assignment className="text-customGreen" />
                    </InputAdornment>
                  ),
                }}               
                className="mt-1"
              />
            </Grid>

            {/* Meeting Summary on one line if status is Complete */}
            {meetingStatus === 'complete' && (
              <Grid item xs={12}>
                <TextField
                  label="Meeting Summary"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  name="meetingSummary"
                  value={meetingSummary}
                  onChange={handleInputChange(setMeetingSummary)}
                  error={!!formErrors.meetingSummary}
                  helperText={formErrors.meetingSummary}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Assignment className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
              </Grid>
            )}

            {/* Meeting Status, Submit, and Reset buttons */}
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth variant="outlined" className="mt-1">
                <InputLabel>Meeting Status</InputLabel>
                <Select
                  label="Meeting Status"
                  value={meetingStatus}
                  onChange={handleInputChange(setMeetingStatus)}
                  name="meetingStatus"
                  className="text-customGreen"
                >
                  <MenuItem value="draft">Draft</MenuItem>
                  <MenuItem value="complete">Pending</MenuItem>
                  <MenuItem value="complete">Complete</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={8} container spacing={2} alignItems="center">
              <Grid item>
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              </Grid>
              <Grid item>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>

      {/* Snackbar Notification */}
      <Notification
        open={openSnackbar}
        message="Form submitted successfully!"
        severity="success"
        onClose={handleCloseSnackbar}
      />
    </div>
  );
};

export default AddMeetingSchedule;
