import React, { useState } from 'react'
import Button from '../../components/common/Button';
import RoleList from './RoleList';

function AddRole() {
  const initialRoleState = {
    roleName: '',
    description: '',
  };
  const [ role, setRole ] = useState(initialRoleState);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRole({
      ...role,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Logic to add role
    console.log('Role added:', role);
    // Clear the form
    setRole(initialRoleState);
  };

    
  return (
    <div className='flex'>
      <div className="w-full lg:w-1/3 p-4">
        <h2 className="text-xl font-bold mb-4">Add Role</h2>
        
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 gap-6">
            <div>
              <label className="block text-gray-700">Role Name</label>
              <input
                type="text"
                name="roleName"
                value={role.roleName}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder="Enter role name"
                required
              />
            </div>
            <div>
              <label className="block text-gray-700">Description</label>
              <textarea
                name="description"
                value={role.description}
                onChange={handleChange}
                className="w-full px-4 py-2 border rounded-lg"
                placeholder="Enter description"
              />
            </div>
          </div>
          <div className="mt-4">
            <Button Name = "Add Role"/>
          </div>
        </form>
      </div>
      <div className="w-full lg:w-2/3 p-4">
       <RoleList /> 
    </div>
  </div>
  )
}

export default AddRole