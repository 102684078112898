import React from 'react';
import { TextField } from '@mui/material';

const OrderSummary = ({ totalAmount, discount, vat, tax, grandTotal, setDiscount, setVat, setTax }) => (
  <div className="w-full lg:w-1/3 ml-auto mb-6">
    <div className="border border-gray-300 p-4 rounded-md">
      <h4 className="text-xl font-semibold text-customGreen mb-4">Order Summary</h4>

      <TextField
        label="Total Amount"
        variant="outlined"
        fullWidth
        value={totalAmount.toFixed(2)} // Ensure the amount is formatted to two decimal places
        InputProps={{ readOnly: true }}
        className="mb-3"
      />
      <TextField
        label="Discount (%)"
        variant="outlined"
        fullWidth
        type="number"
        value={discount}
        onChange={(e) => setDiscount(parseFloat(e.target.value) || 0)}
        className="mb-3"
        inputProps={{ min: 0, step: "0.01" }} // Allow decimal input
      />
      <TextField
        label="VAT (%)"
        variant="outlined"
        fullWidth
        type="number"
        value={vat}
        onChange={(e) => setVat(parseFloat(e.target.value) || 0)}
        className="mb-3"
        inputProps={{ min: 0, step: "0.01" }} // Allow decimal input
      />
      <TextField
        label="Tax (%)"
        variant="outlined"
        fullWidth
        type="number"
        value={tax}
        onChange={(e) => setTax(parseFloat(e.target.value) || 0)}
        className="mb-3"
        inputProps={{ min: 0, step: "0.01" }} // Allow decimal input
      />
      <TextField
        label="Grand Total"
        variant="outlined"
        fullWidth
        value={grandTotal.toFixed(2)} // Ensure the total is formatted to two decimal places
        InputProps={{ readOnly: true }}
        className="mb-3"
      />
    </div>
  </div>
);

export default OrderSummary;
