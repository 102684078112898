import React, { useState } from 'react';
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  IconButton,
} from '@mui/material';
import {
  CalendarToday,
  AccessTime,
  Place,
  Event,
  AddCircleOutline,
  RemoveCircleOutline,
} from '@mui/icons-material';
import Notification from '../../components/common/Notification/Notification';

const AddTourPlan = () => {
  const [tourPlans, setTourPlans] = useState([
    { date: '', time: '', purpose: '', workingStatus: '', place: '', visitType: '' },
  ]);
  const [formErrors, setFormErrors] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPlans = tourPlans.map((plan, i) =>
      i === index ? { ...plan, [name]: value } : plan
    );
    setTourPlans(updatedPlans);
  };

  const addTourPlan = () => {
    setTourPlans([
      ...tourPlans,
      { date: '', time: '', purpose: '', workingStatus: '', place: '', visitType: '' },
    ]);
  };

  const removeTourPlan = (index) => {
    setTourPlans(tourPlans.filter((_, i) => i !== index));
  };

  const validateForm = () => {
    const errors = tourPlans.map((plan) => {
      let error = {};
      if (!plan.date.trim()) error.date = 'Date is required';
      if (!plan.time.trim()) error.time = 'Time is required';
      if (!plan.purpose.trim()) error.purpose = 'Purpose is required';
      if (!plan.workingStatus.trim()) error.workingStatus = 'Working Status is required';
      if (!plan.place.trim()) error.place = 'Place is required';
      if (!plan.visitType.trim()) error.visitType = 'Visit Type is required';
      return error;
    });

    setFormErrors(errors);
    return errors.every((error) => Object.keys(error).length === 0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Tour Plans Submitted:', tourPlans);
      setOpenSnackbar(true);
      setTourPlans([{ date: '', time: '', purpose: '', workingStatus: '', place: '', visitType: '' }]);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Monthly Tour Plan</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit}>
            <table className="w-full border-collapse">
              <thead>
                <tr>
                  <th className="border-b px-4 py-2 text-left">Date</th>
                  <th className="border-b px-4 py-2 text-left">Time</th>
                  <th className="border-b px-4 py-2 text-left">Purpose</th>
                  <th className="border-b px-4 py-2 text-left">Place</th>
                  <th className="border-b px-4 py-2 text-left">Visit Type</th>
                  <th className="border-b px-4 py-2 text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                {tourPlans.map((plan, index) => (
                  <tr key={index}>
                    <td className="border-b px-4 py-2">
                      <TextField
                        label="Date"
                        variant="outlined"
                        fullWidth
                        name="date"
                        value={plan.date}
                        onChange={(e) => handleInputChange(index, e)}
                        error={!!formErrors[index]?.date}
                        helperText={formErrors[index]?.date}
                        type="date"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <CalendarToday className="text-customGreen" />
                          ),
                        }}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <TextField
                        label="Time"
                        variant="outlined"
                        fullWidth
                        name="time"
                        value={plan.time}
                        onChange={(e) => handleInputChange(index, e)}
                        error={!!formErrors[index]?.time}
                        helperText={formErrors[index]?.time}
                        type="time"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          startAdornment: (
                            <AccessTime className="text-customGreen" />
                          ),
                        }}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <TextField
                        label="Purpose"
                        variant="outlined"
                        fullWidth
                        name="purpose"
                        value={plan.purpose}
                        onChange={(e) => handleInputChange(index, e)}
                        error={!!formErrors[index]?.purpose}
                        helperText={formErrors[index]?.purpose}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <TextField
                        label="Place"
                        variant="outlined"
                        fullWidth
                        name="place"
                        value={plan.place}
                        onChange={(e) => handleInputChange(index, e)}
                        error={!!formErrors[index]?.place}
                        helperText={formErrors[index]?.place}
                        InputProps={{
                          startAdornment: (
                            <Place className="text-customGreen" />
                          ),
                        }}
                      />
                    </td>
                    <td className="border-b px-4 py-2">
                      <FormControl
                        fullWidth
                        variant="outlined"
                        error={!!formErrors[index]?.visitType}
                      >
                        <InputLabel>Visit Type</InputLabel>
                        <Select
                          label="Visit Type"
                          name="visitType"
                          value={plan.visitType}
                          onChange={(e) => handleInputChange(index, e)}
                        >
                          <MenuItem value="HQ">HQ</MenuItem>
                          <MenuItem value="EX-HQ">EX-HQ</MenuItem>
                          <MenuItem value="Self">Self</MenuItem>
                        </Select>
                        {formErrors[index]?.visitType && (
                          <p className="text-red-600 text-sm mt-1">{formErrors[index]?.visitType}</p>
                        )}
                      </FormControl>
                    </td>
                    <td className="border-b px-4 py-2 text-center">
                      <IconButton
                        color="secondary"
                        onClick={() => removeTourPlan(index)}
                        disabled={tourPlans.length === 1}
                      >
                        <RemoveCircleOutline />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddCircleOutline />}
              onClick={addTourPlan}
              className="mt-4"
            >
              Add Another Plan
            </Button>
            <div className="flex justify-end mt-6">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="bg-customGreen text-white hover:bg-customGreen-dark"
              >
                Submit
              </Button>
            </div>
          </form>
        </div>
      </div>

      <Notification
        open={openSnackbar}
        onClose={handleCloseSnackbar}
        message="Tour Plan submitted successfully!"
        type="success"
      />
    </div>
  );
};

export default AddTourPlan;
