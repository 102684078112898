import React, { useState } from 'react';
import { TextField, InputLabel, MenuItem, Select, FormControl, InputAdornment } from '@mui/material';
import { AccountCircle, Phone, LocationOn, Badge, Upload, PhotoCamera } from '@mui/icons-material';

function AddEmployee() {
  const [avatar, setAvatar] = useState(null);
  const [avatarPreview, setAvatarPreview] = useState(null);  // For image preview
  const [documents, setDocuments] = useState([]);  // For multiple documents

  // Form fields state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [surname, setSurname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [mobile1, setMobile1] = useState('');
  const [mobile2, setMobile2] = useState('');
  const [nid, setNid] = useState('');
  const [gender, setGender] = useState('');
  const [documentType, setDocumentType] = useState('');
  const [referenceName, setReferenceName] = useState('');
  const [referenceMobile1, setReferenceMobile1] = useState('');
  const [referenceMobile2, setReferenceMobile2] = useState('');
  const [referenceAddress, setReferenceAddress] = useState('');

  // Error state for validation
  const [errors, setErrors] = useState({});

  const handleAvatarChange = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      setAvatar(file.name);

      // Optional: Preview the avatar image
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarPreview(reader.result);  // Image preview
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDocumentsChange = (e) => {
    const files = Array.from(e.target.files);
    setDocuments(files.map(file => file.name));  // Store only the filenames
  };

  // Validation function
  const validateForm = () => {
    let formErrors = {};
  
    // Validate first name
    if (!firstName.trim()) {
      formErrors.firstName = "First name is required";
    } else if (firstName.length < 2) {
      formErrors.firstName = "First name must be at least 2 characters";
    }
  
    // Validate address
    if (!address.trim()) formErrors.address = "Address is required";
  
    // Validate city
    if (!city.trim()) formErrors.city = "City is required";
  
    // Validate district
    if (!district.trim()) formErrors.district = "District is required";
  
    // Validate mobile number
    if (!mobile1.trim()) {
      formErrors.mobile1 = "Mobile 1 is required";
    } else if (!/^\d{11}$/.test(mobile1)) {
      formErrors.mobile1 = "Mobile 1 must be a 11-digit number";
    }
  
    // Validate NID
    if (!nid.trim()) formErrors.nid = "NID is required";
  
    // Validate gender
    if (!gender.trim()) formErrors.gender = "Gender is required";
  
    // Set the errors in state
    setErrors(formErrors);
  
    // Return whether the form is valid or not
    return Object.keys(formErrors).length === 0;
  };  
  

  const handleSubmit = (e) => {
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const formData = {
        firstName,
        lastName,
        surname,
        address,
        city,
        district,
        mobile1,
        mobile2,
        nid,
        gender,
        avatar,
        documents,
        documentType,
        reference: {
          name: referenceName,
          mobile1: referenceMobile1,
          mobile2: referenceMobile2,
          address: referenceAddress
        }
      };
      
      // Log the JSON object in the console
      console.log("Form Data Submitted:", JSON.stringify(formData, null, 2));
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Employee Details</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form onSubmit={handleSubmit}>
            {/* Basic Info Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Basic Info:
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="First Name"
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Last Name"
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="Surname"
                  variant="outlined"
                  fullWidth
                  value={surname}
                  onChange={(e) => setSurname(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="Address"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  error={!!errors.address}
                  helperText={errors.address}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="col-span-2 lg:col-span-3 mt-1"
                />
                <TextField
                  label="City"
                  variant="outlined"
                  fullWidth
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  error={!!errors.city}
                  helperText={errors.city}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />

                <TextField
                  label="District"
                  variant="outlined"
                  fullWidth
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                  error={!!errors.district}
                  helperText={errors.district}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LocationOn className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />

                <TextField
                  label="Mobile 1"
                  variant="outlined"
                  fullWidth
                  value={mobile1}
                  onChange={(e) => setMobile1(e.target.value)}
                  error={!!errors.mobile1}
                  helperText={errors.mobile1}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Phone className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                <TextField
                  label="Mobile 2"
                  variant="outlined"
                  fullWidth
                  value={mobile2}
                  onChange={(e) => setMobile2(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="NID"
                  variant="outlined"
                  fullWidth
                  value={nid}
                  onChange={(e) => setNid(e.target.value)}
                  className="mt-1"
                />
                <FormControl fullWidth variant="outlined" className="mt-1" error={!!errors.gender}>
                  <InputLabel>Gender</InputLabel>
                  <Select
                    label="Gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    className="text-customGreen"
                  >
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                  {errors.gender && <p className="text-red-500 text-sm">{errors.gender}</p>}
                </FormControl>

                <div className="mt-1 col-span-2 lg:col-span-3 mt-1">
                  <div className="border-2 border-dashed border-gray-300 rounded-md p-4 w-64 flex flex-col items-center justify-center cursor-pointer hover:border-customGreen">
                    <input
                      type="file"
                      accept=".jpeg, .jpg, .png"
                      className="hidden"
                      id="avatar-upload"
                      onChange={handleAvatarChange}
                    />
                    <label htmlFor="avatar-upload">
                      <PhotoCamera className="text-customGreen mb-2" />
                      <span className="text-customGreen text-center">Upload Avatar (JPEG, JPG, PNG)</span>
                    </label>
                    <span className="text-gray-600 mt-2 text-center">{avatar ? `Selected file: ${avatar}` : 'No file selected'}</span>
                    {avatarPreview && (
                      <img src={avatarPreview} alt="Avatar Preview" className="mt-4 w-32 h-32 rounded-full object-cover" />
                    )}
                  </div>
                </div>
              </div>

              {/* Document Upload Section */}
              <h4 className="mt-10 text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Add Documents
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>Document Type</InputLabel>
                  <Select
                    label="Document Type"
                    value={documentType}
                    onChange={(e) => setDocumentType(e.target.value)}
                    className="text-customGreen"
                  >
                    <MenuItem value="passport">Passport</MenuItem>
                    <MenuItem value="driving_license">Driving License</MenuItem>
                    <MenuItem value="nid">NID</MenuItem>
                  </Select>
                </FormControl>
                <div className="flex items-center gap-4 p-4 border-2 border-dashed border-gray-300 rounded-md">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="file"
                      accept=".pdf"
                      multiple  // Allow multiple documents
                      className="hidden"
                      id="documents-upload"
                      onChange={handleDocumentsChange}
                    />
                    <Upload className="text-customGreen mr-2" />
                    <span className="text-customGreen">Upload Docs (PDF)</span>
                  </label>
                  <div className="text-gray-600">
                    {documents.length > 0 ? (
                      <ul>
                        {documents.map((doc, index) => (
                          <li key={index}>{doc}</li>
                        ))}
                      </ul>
                    ) : (
                      <span>No files selected</span>
                    )}
                  </div>
                </div>
              </div>

              {/* Employee Reference Section */}
              <h4 className="mt-10 text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <AccountCircle className="text-customGreen mr-2" /> Employee Reference
              </h4>
              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                <TextField
                  label="Employee Reference Name"
                  variant="outlined"
                  fullWidth
                  value={referenceName}
                  onChange={(e) => setReferenceName(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="Reference Mobile 1"
                  variant="outlined"
                  fullWidth
                  value={referenceMobile1}
                  onChange={(e) => setReferenceMobile1(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="Reference Mobile 2"
                  variant="outlined"
                  fullWidth
                  value={referenceMobile2}
                  onChange={(e) => setReferenceMobile2(e.target.value)}
                  className="mt-1"
                />
                <TextField
                  label="Reference Address"
                  variant="outlined"
                  multiline
                  rows={3}
                  fullWidth
                  value={referenceAddress}
                  onChange={(e) => setReferenceAddress(e.target.value)}
                  className="col-span-2 lg:col-span-3 mt-1"
                />
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Submit
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AddEmployee;
