import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const DoctorGiftPlan = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'brandName', direction: 'asc' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const GiftPlans = [
    {
      id: 1,
      brandName: 'Brand A',
      giftName: 'Gift A1',
      qty: 50,
      used: 10,
      remaining: 40,
    },
    {
      id: 2,
      brandName: 'Brand B',
      giftName: 'Gift B1',
      qty: 30,
      used: 5,
      remaining: 25,
    },
    // Add more demo data here
  ];

  const filteredPlans = GiftPlans.filter(plan =>
    plan.brandName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.giftName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    plan.qty.toString().includes(searchQuery.toLowerCase()) ||
    plan.used.toString().includes(searchQuery.toLowerCase()) ||
    plan.remaining.toString().includes(searchQuery.toLowerCase())
  );

  const totalPages = Math.ceil(filteredPlans.length / rowsPerPage);
  const paginatedPlans = filteredPlans.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const sortedPlans = [...paginatedPlans].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'asc' ? 1 : -1;
    }
    return 0;
  });

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const handleMenuClick = (event, plan) => {
    setAnchorEl(event.currentTarget);
    setCurrentPlan(plan);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentPlan(null);
  };

  const handleView = () => {
    console.log(`Viewing plan with id: ${currentPlan.id}`);
    handleMenuClose();
    // Add your view logic here
  };

  const handleEdit = () => {
    console.log(`Editing plan with id: ${currentPlan.id}`);
    handleMenuClose();
    // Add your edit logic here
  };

  const handleDelete = () => {
    console.log(`Deleting plan with id: ${currentPlan.id}`);
    handleMenuClose();
    // Add your delete logic here
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1); // Reset to first page when rows per page changes
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-semibold text-gray-800">Doctor's Gift Plan</h2>
        <div className="flex space-x-4">
          <Link to="/new-planning">
            <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
              New Gift Plan
            </button>
          </Link>
        </div>
      </div>
      <div className="card-block">
        <div className="flex justify-between items-center mb-4">
          <div className="text-sm text-gray-500">
            <span>Showing </span>
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              className="w-15"
              size="small"
            >
              <MuiMenuItem value={5}>5</MuiMenuItem>
              <MuiMenuItem value={10}>10</MuiMenuItem>
              <MuiMenuItem value={25}>25</MuiMenuItem>
              <MuiMenuItem value={50}>50</MuiMenuItem>
            </Select>
            <span> entries</span>
          </div>
          <div className="flex items-center space-x-4">
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              className="w-3/3"
            />
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
            <thead className="bg-gray-100">
              <tr>
                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('brandName')}
                >
                  Brand Name
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'brandName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'brandName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('giftName')}
                >
                  Gift Name
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'giftName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'giftName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('qty')}
                >
                  Qty
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'qty' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'qty' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('used')}
                >
                  Used
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'used' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'used' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                  onClick={() => handleSort('remaining')}
                >
                  Remaining
                  <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'remaining' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9650;
                    </span>
                    <span
                      className={`text-gray-500 ${sortConfig.key === 'remaining' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                    >
                      &#9660;
                    </span>
                  </div>
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sortedPlans.map((plan) => (
                <tr key={plan.id}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{plan.brandName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.giftName}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.qty}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.used}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{plan.remaining}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    <IconButton onClick={(event) => handleMenuClick(event, plan)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl) && currentPlan?.id === plan.id}
                      onClose={handleMenuClose}
                    >
                      <MuiMenuItem onClick={handleView}>
                        <Visibility fontSize="small" />
                        View
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleEdit}>
                        <Edit fontSize="small" />
                        Edit
                      </MuiMenuItem>
                      <MuiMenuItem onClick={handleDelete}>
                        <Delete fontSize="small" />
                        Delete
                      </MuiMenuItem>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
        {/* Pagination */}
        <div className="mt-4 flex justify-end">
            <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                shape="rounded"
                variant="outlined"
            />
        </div>
      </div>
    </div>
  );
};

export default DoctorGiftPlan;
