import React from 'react';
import { Card, CardHeader, CardContent, Table, TableBody, TableRow, TableCell, Button, Typography, Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';

const appointments = [
  { id: 1, employeeName: 'Bernardo Galaviz', location: 'New York, USA', doctorName: 'Dr. Cristina Groves', timing: '7.00 PM' },
  { id: 2, employeeName: 'Anna Smith', location: 'Los Angeles, USA', doctorName: 'Dr. John Doe', timing: '8.00 AM' },
  { id: 3, employeeName: 'John Doe', location: 'Chicago, USA', doctorName: 'Dr. Emily Davis', timing: '9.00 AM' },
  { id: 4, employeeName: 'Sophia Brown', location: 'Houston, USA', doctorName: 'Dr. Robert Brown', timing: '10.00 AM' },
  { id: 5, employeeName: 'Liam Johnson', location: 'Phoenix, USA', doctorName: 'Dr. Linda Johnson', timing: '11.00 AM' },
  { id: 6, employeeName: 'Olivia Wilson', location: 'Philadelphia, USA', doctorName: 'Dr. James Wilson', timing: '12.00 PM' }, // Extra item for demo
];

const UpcomingAppointmentsCardView = () => {
  return (
    <Card className="w-full">
      <CardHeader
        title={<Typography variant="h6" className="font-semibold text-gray-800 mr-10">Upcoming Meeting</Typography>}
        action={<Button component={Link} to="/doctor-schedule" variant="outlined" className='mr-10 bg-customGreen' color="primary" sx={{ marginRight: '25px'}}>View all</Button>}
        className="relative"
      />
      
      <CardContent className="p-0">
        <Table>
          <TableBody>
            {appointments.slice(0, 4).map((appointment) => (
              <TableRow key={appointment.id} className="border-b border-gray-200">
                <TableCell>
                  <Box display="flex" alignItems="center">
                    <Avatar alt={appointment.employeeName} className="w-40 h-40" component={Link} to="/profile">
                      {appointment.employeeName.charAt(0)}
                    </Avatar>
                    <Box ml={2}>
                      <Typography variant="body1" component={Link} to="/profile" className="font-semibold">
                        {appointment.employeeName}
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {appointment.location}
                      </Typography>
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" className="font-semibold">Meeting With</Typography>
                  <Typography variant="body1">{appointment.doctorName}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="body2" className="font-semibold">Timing</Typography>
                  <Typography variant="body1">{appointment.timing}</Typography>
                </TableCell>
                <TableCell align="right">
                  <Button component={Link} to="/appointments" variant="outlined" color="primary">Take up</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default UpcomingAppointmentsCardView;
