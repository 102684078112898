import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { Box, Typography, TextField, IconButton, Button, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { Visibility, VisibilityOff, Person, Lock } from '@mui/icons-material';
import Logo from '../../logo-r.png';
import LoginBG from '../../assets/bg/login-bg.jpg';

const LoginPage = ({ onLogin }) => {
  const [userId, setUserId] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [userIdValid, setUserIdValid] = useState(true);
  const [passwordValid, setPasswordValid] = useState(true);
  const [loginError, setLoginError] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [openResetDialog, setOpenResetDialog] = useState(false); // For Reset Password Modal
  const [resetEmail, setResetEmail] = useState(''); // Email for Reset Password

  const navigate = useNavigate();

  const handleUserIdChange = (e) => {
    const rawValue = e.target.value;
    const sanitizedValue = rawValue.replace(/[^a-zA-Z0-9]/g, '');
    setUserId(sanitizedValue);
    setUserIdValid(sanitizedValue.length > 0 || !formSubmitted);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordValid(e.target.value.length > 0 || !formSubmitted);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const isUserIdValid = userId.length > 0;
    const isPasswordValid = password.length > 0;

    // Update validity states based on form submission
    setUserIdValid(isUserIdValid || !formSubmitted);
    setPasswordValid(isPasswordValid || !formSubmitted);

    if (isUserIdValid && isPasswordValid) {
      // Check for valid credentials
      if (
        (userId === 'ID01' && password === '123') ||
        (userId === 'demo' && password === 'demo')
      ) {
        const token = 'dummy-auth-token'; // Replace with actual token from your API
        Cookies.set('authToken', token, { expires: 1 });
        onLogin();
        navigate('/dashboard');
      } else {
        setLoginError('Incorrect User ID or Password.');
      }
    } else {
      setLoginError(''); // Clear generic error if specific field errors are present
    }
  };

  // Open reset password modal
  const handleOpenResetDialog = () => {
    setOpenResetDialog(true);
  };

  // Close reset password modal
  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
  };

  // Handle reset password submission
  const handleResetPassword = () => {
    if (resetEmail) {
      console.log(`Password reset link sent to: ${resetEmail}`);
      alert('Password reset link has been sent to your email.');
      handleCloseResetDialog();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        backgroundImage: `url(${LoginBG})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '800px',
          height: '600px',
          display: 'flex',
          flexDirection: 'row',
          bgcolor: 'rgba(255, 255, 255, 0.9)',
          borderRadius: '8px',
          boxShadow: 2,
        }}
      >
        {/* Left Area */}
        <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', bgcolor: '#9acb4eb0' }}>
          <Avatar src={Logo} sx={{ width: '100%', height: 'auto', maxWidth: '500px', padding: '20px' }} />
        </Box>

        {/* Right Area */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', p: 4 }}>
          <Typography variant="h5" gutterBottom>MDMS Console</Typography>
          <Typography variant="h6" color="textSecondary" gutterBottom>Zventure Corporation</Typography>

          {/* Error Messages */}
          {(!userIdValid && formSubmitted) || (!passwordValid && formSubmitted) || loginError ? (
            <Box sx={{ mb: 2, color: 'red' }}>
              <Typography variant="body2">
                {loginError || (!userIdValid && formSubmitted ? 'User ID is required' : '') || (!passwordValid && formSubmitted ? 'Password is required' : '')}
              </Typography>
            </Box>
          ) : null}

          <form onSubmit={handleSubmit} style={{ width: '100%', maxWidth: '400px' }}>
            {/* User ID Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="User ID"
              value={userId}
              onChange={handleUserIdChange}
              error={!userIdValid && formSubmitted}
              helperText={!userIdValid && formSubmitted ? 'User ID is required' : ''}
              InputProps={{
                startAdornment: <Person sx={{ mr: 1 }} />,
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                }
              }}
            />

            {/* Password Input */}
            <TextField
              fullWidth
              variant="outlined"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={handlePasswordChange}
              error={!passwordValid && formSubmitted}
              helperText={!passwordValid && formSubmitted ? 'Password is required' : ''}
              InputProps={{
                startAdornment: <Lock sx={{ mr: 1 }} />,
                endAdornment: (
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                ),
              }}
              sx={{
                mb: 2,
                backgroundColor: 'rgba(255, 255, 255, 0.0)',
                '& .MuiOutlinedInput-root': {
                  borderRadius: '4px',
                },
                '& .MuiInputBase-input': {
                  backgroundColor: 'transparent',
                }
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mb: 2 }}
            >
              Login
            </Button>

            {/* Reset Password Link */}
            <Typography variant="body2" color="primary" align="center" sx={{ cursor: 'pointer' }} onClick={handleOpenResetDialog}>
              Forgot Password?
            </Typography>

            <Typography variant="body2" color="textSecondary" align="center">
              &copy; {new Date().getFullYear()} Zventure Corporation. All rights reserved.
            </Typography>
          </form>
        </Box>
      </Box>

      {/* Reset Password Dialog */}
      <Dialog open={openResetDialog} onClose={handleCloseResetDialog}>
        <DialogTitle>Reset Password</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter your email address. We will send you a link to reset your password.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Email Address"
            type="email"
            fullWidth
            variant="outlined"
            value={resetEmail}
            onChange={(e) => setResetEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetDialog} color="secondary">Cancel</Button>
          <Button onClick={handleResetPassword} color="primary">Send Reset Link</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LoginPage;
