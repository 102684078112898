import React, { useState } from 'react';
import { TextField, MenuItem, FormControl, InputLabel, Select, IconButton, InputAdornment } from '@mui/material';
import { AccountCircle, Person, Search, AddCircle, RemoveCircle } from '@mui/icons-material';

const users = ['User1', 'User2', 'User3']; // Replace with actual user list
const doctors = ['Doctor1', 'Doctor2', 'Doctor3']; // Replace with actual doctor list

function DoctorAssign() {
  const [selectedUser, setSelectedUser] = useState('');
  const [assignedDoctors, setAssignedDoctors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  const handleUserChange = (event) => {
    setSelectedUser(event.target.value);
  };

  const handleDoctorSearch = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleAddDoctor = (doctor) => {
    if (!assignedDoctors.includes(doctor)) {
      setAssignedDoctors([...assignedDoctors, doctor]);
    }
    setSearchQuery(''); // Clear search box after adding
  };

  const handleRemoveDoctor = (doctor) => {
    setAssignedDoctors(assignedDoctors.filter(d => d !== doctor));
  };

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center mb-4">
        {/* Title Area */}
        <h2 className="text-2xl font-semibold text-gray-800">Assign Doctors to User</h2>
      </div>

      <div className="overflow-x-auto">
        <div className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mt-5 mb-5">
          <form>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-6 pb-4 border-b-2 border-gray-300">
              {/* Select User Section */}
              <div>
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                  <Person className="text-customGreen mr-2" /> User:
                </h4>
                <FormControl fullWidth variant="outlined" className="mt-1">
                  <InputLabel>User</InputLabel>
                  <Select
                    value={selectedUser}
                    onChange={handleUserChange}
                    label="User"
                    className="text-customGreen"
                  >
                    {users.map((user, index) => (
                      <MenuItem key={index} value={user}>
                        {user}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {/* Assign Doctors Section */}
              <div className="relative">
                <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                  <Person className="text-customGreen mr-2" /> Assign Doctors:
                </h4>
                <TextField
                  label="Search Doctor"
                  variant="outlined"
                  fullWidth
                  value={searchQuery}
                  onChange={handleDoctorSearch}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search className="text-customGreen" />
                      </InputAdornment>
                    ),
                  }}
                  className="mt-1"
                />
                {searchQuery && (
                  <div className="absolute z-10 bg-white border border-gray-300 rounded-md mt-1 w-full max-h-60 overflow-y-auto">
                    {doctors
                      .filter((doctor) =>
                        doctor.toLowerCase().includes(searchQuery.toLowerCase())
                      )
                      .map((doctor, index) => (
                        <div key={index} className="flex items-center justify-between p-2 hover:bg-gray-100">
                          <span>{doctor}</span>
                          <IconButton
                            color="primary"
                            aria-label="add"
                            component="span"
                            onClick={() => handleAddDoctor(doctor)}
                          >
                            <AddCircle className="text-green-600" />
                          </IconButton>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Assigned Doctors Section */}
            <div className="mb-6 pb-4 border-b-2 border-gray-300">
              <h4 className="text-xl font-semibold text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4 flex items-center">
                <Person className="text-customGreen mr-2" /> Assigned Doctors:
              </h4>
              <div className="space-y-4">
                {assignedDoctors.map((doctor, index) => (
                  <div key={index} className="flex items-center space-x-4">
                    <TextField
                      label="Doctor Name"
                      variant="outlined"
                      fullWidth
                      value={doctor}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AccountCircle className="text-customGreen" />
                          </InputAdornment>
                        ),
                      }}
                      className="mt-1"
                      disabled
                    />
                    <IconButton
                      color="primary"
                      aria-label="remove"
                      component="span"
                      onClick={() => handleRemoveDoctor(doctor)}
                    >
                      <RemoveCircle className="text-red-600" />
                    </IconButton>
                  </div>
                ))}
              </div>
            </div>

            {/* Form Actions */}
            <div className="flex gap-4">
              <button
                type="submit"
                className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-customGreen"
              >
                Save Changes
              </button>
              <button
                type="reset"
                className="px-4 py-2 border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-customGreen"
                onClick={() => {
                  setSelectedUser('');
                  setAssignedDoctors([]);
                  setSearchQuery('');
                }}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default DoctorAssign;
