import React, { useState } from 'react';
import { Avatar, Button, Grid, Paper, Tab, Tabs, Typography, Box } from '@mui/material';
import { 
  AccountCircle, 
  Phone, 
  Email, 
  LocationOn, 
  Info, 
  Settings,
  Assessment
} from '@mui/icons-material';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function ViewSupplier() {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  // Sample supplier data
  const supplier = {
    id: 'S12345',
    name: 'ABC Supplies',
    contactPerson: 'Jane Smith',
    mobile: '123-456-7890',
    email: 'contact@abcsupplies.com',
    address: '123 Main Street, Springfield',
    country: 'USA',
    district: 'California',
    avatar: 'https://via.placeholder.com/200',
    about: 'We provide high-quality supplies for various industries, ensuring timely delivery and excellent customer service.',
    salesTarget: {
      monthly: 50000,
      achieved: 35000,
      pending: 15000,
    },
  };

  // Bar chart data
  const chartData = {
    labels: ['Monthly Target', 'Achieved', 'Pending'],
    datasets: [
      {
        label: 'Sales Target',
        data: [supplier.salesTarget.monthly, supplier.salesTarget.achieved, supplier.salesTarget.pending],
        backgroundColor: ['#ff6347', '#4caf50', '#ffa500'],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function(context) {
            const label = context.dataset.label || '';
            const value = context.raw;
            return `${label}: $${value.toLocaleString()}`;
          }
        }
      }
    }
  };

  return (
    <div className="mt-5 px-4">
      <div className="flex justify-between items-center mb-6">
        {/* Title Area */}
        <h2 className="text-3xl font-semibold text-gray-800">Supplier Profile</h2>
        <Button variant="contained" color="primary">Edit Profile</Button>
      </div>

      <Grid container spacing={4}>
        {/* Left Side: Profile Card and About Us */}
        <Grid item xs={12} md={4}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
            <div className="flex items-center mb-4">
              <Avatar src={supplier.avatar} alt={supplier.name} className="w-48 h-48 mr-4" />
              <div>
                <Typography variant="h5" className="font-semibold">{supplier.name}</Typography>
                <Typography variant="body1" className="text-gray-600">ID: {supplier.id}</Typography>
                <Typography variant="body1" className="text-gray-600">Contact Person: {supplier.contactPerson}</Typography>
                <Typography variant="body1" className="text-gray-600">Email: {supplier.email}</Typography>
              </div>
            </div>
            <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6 mb-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">Sales Target</Typography>
              <div className="mb-4">
                <Bar data={chartData} options={chartOptions} />
              </div>
            </Paper>
            <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
              <Typography variant="h6" className="text-customGreen border-b-2 border-customGreen inline-block pb-2 mb-4">About Us</Typography>
              <Typography variant="body1" className="text-gray-700">{supplier.about}</Typography>
            </Paper>
          </Paper>
        </Grid>

        {/* Right Side: Tabs */}
        <Grid item xs={12} md={8}>
          <Paper className="border border-gray-300 border-t-6 border-customGreen rounded-md p-6">
            <Tabs value={tabIndex} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
              <Tab label="Activity" icon={<AccountCircle />} />
              <Tab label="Timeline" icon={<Assessment />} />
              <Tab label="Profile Info" icon={<Info />} />
              <Tab label="Settings" icon={<Settings />} />
            </Tabs>
            <Box className="mt-4 p-4">
              {tabIndex === 0 && <Typography>Activity Content</Typography>}
              {tabIndex === 1 && <Typography>Timeline Content</Typography>}
              {tabIndex === 2 && (
                <div className="mb-4">
                  <Typography variant="body2" className="flex items-center mb-2">
                    <AccountCircle className="text-customGreen mr-2" /> Supplier ID: {supplier.id}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Phone className="text-customGreen mr-2" /> Mobile: {supplier.mobile}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <Email className="text-customGreen mr-2" /> Email: {supplier.email}
                  </Typography>
                  <Typography variant="body2" className="flex items-center mb-2">
                    <LocationOn className="text-customGreen mr-2" /> Address: {supplier.address}
                  </Typography>
                </div>
              )}
              {tabIndex === 3 && <Typography>Settings Content</Typography>}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default ViewSupplier;
