import React, { useState, useEffect } from 'react';
import CustomerInfo from './CustomerInfo';
import ProductList from './ProductList';
import OrderSummary from './OrderSummary';
import FormActions from './FormActions';
import { Dialog, DialogContent, Button } from '@mui/material';
import OrderPreview from './OrderPreview';
import OrderSearchFilter from './OrderSearchFilter';

// Mock data imports
const customersData = [
  { id: 1, name: 'John Doe', phone: '123-456-7890', address: '123 Main St' },
  { id: 2, name: 'Jane Smith', phone: '987-654-3210', address: '456 Oak St' },
];

const productsData = [
  { id: 1, name: 'Product 1', group: 'Group A', category: 'Electronics', unitType: 'Piece' },
  { id: 2, name: 'Product 2', group: 'Group B', category: 'Apparel', unitType: 'Set' },
];

const categoriesData = ['Electronics', 'Apparel', 'Home Goods'];

function OrderPlace() {
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [customers, setCustomers] = useState(customersData);
  const [products, setProducts] = useState([{ id: '', name: '', group: '', category: '', qty: 1, unitPrice: 0, unitType: '', amount: 0 }]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [vat, setVat] = useState(0);
  const [tax, setTax] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  // Function to calculate the amount based on quantity, unit price, VAT, and tax
  const calculateAmount = (quantity, unitPrice, vat, tax) => {
    const qty = parseFloat(quantity) || 0;
    const price = parseFloat(unitPrice) || 0;
    const vatPercentage = parseFloat(vat) || 0;
    const taxPercentage = parseFloat(tax) || 0;
    
    const subtotal = qty * price;
    const total = subtotal * (1 + vatPercentage / 100 + taxPercentage / 100);
    return total.toFixed(2); // Format to two decimal places
  };

  const handleCustomerChange = (event) => {
    const selected = customersData.find((customer) => customer.id === Number(event.target.value));
    setSelectedCustomer(selected);
  };

  const handleProductChange = (index, field, value) => {
    const newProducts = [...products];
    newProducts[index] = { ...newProducts[index], [field]: value };

    if (['qty', 'unitPrice', 'vat', 'tax'].includes(field)) {
      const amount = calculateAmount(
        newProducts[index].qty,
        newProducts[index].unitPrice,
        newProducts[index].vat,
        newProducts[index].tax
      );
      newProducts[index].amount = amount;
    }
    
    setProducts(newProducts);
  };

  const addProduct = () => {
    setProducts([...products, { id: '', name: '', group: '', category: '', qty: 1, unitPrice: 0, unitType: '', amount: 0 }]);
  };

  const removeProduct = (index) => {
    setProducts(products.filter((_, i) => i !== index));
  };

  const clearForm = () => {
    setSelectedCustomer(null);
    setProducts([{ id: '', name: '', group: '', category: '', qty: 1, unitPrice: 0, unitType: '', amount: 0 }]);
    setTotalAmount(0);
    setDiscount(0);
    setVat(0);
    setTax(0);
    setGrandTotal(0);
    setIsPreviewOpen(false);
  };

  const handlePreviewOpen = () => {
    setIsPreviewOpen(true);
  };

  const handlePreviewClose = () => {
    setIsPreviewOpen(false);
  };

  const handleOrderConfirm = () => {
    // Validate customer info and order details
    if (!selectedCustomer) {
      alert('Please select a customer.');
      return;
    }

    const hasInvalidProduct = products.some(p => !p.name || !p.qty || !p.unitPrice || p.qty <= 0 || p.unitPrice <= 0);
    if (hasInvalidProduct) {
      alert('Please ensure all product details are filled out correctly.');
      return;
    }

    // Logic for confirming the order, e.g., submitting to a server or saving the order
    console.log({
      selectedCustomer,
      products,
      totalAmount,
      discount,
      vat,
      tax,
      grandTotal,
    });

    alert('Order has been confirmed!');
    clearForm(); // Optionally clear the form after confirming the order
  };

  const handleFilterChange = (filters) => {
    // Simulate filtering customers based on the provided filters
    const filteredCustomers = customersData.filter(customer => {
      const matchesName = filters.searchName ? customer.name.toLowerCase().includes(filters.searchName.toLowerCase()) : true;
      const matchesProfileId = filters.profileId ? customer.id.toString() === filters.profileId : true;
      const matchesPhone = filters.phone ? customer.phone.includes(filters.phone) : true;
      return matchesName && matchesProfileId && matchesPhone;
    });

    setCustomers(filteredCustomers);

    // Optionally select the first customer from the filtered list
    if (filteredCustomers.length > 0) {
      setSelectedCustomer(filteredCustomers[0]);
    } else {
      setSelectedCustomer(null);
    }
  };

  useEffect(() => {
    const calculatedTotal = products.reduce((sum, product) => sum + parseFloat(product.amount || 0), 0);
    setTotalAmount(calculatedTotal);
    const calculatedGrandTotal = calculatedTotal - discount + vat + tax;
    setGrandTotal(calculatedGrandTotal);
  }, [products, discount, vat, tax]);

  return (
    <div className="p-6 lg:p-8 xl:p-10">
      <OrderSearchFilter onFilterChange={handleFilterChange} />

      {/* Customer Info Component */}
      <CustomerInfo
        selectedCustomer={selectedCustomer}
        customersData={customersData}
        handleCustomerChange={handleCustomerChange}
      />

      <ProductList
        products={products}
        handleProductChange={handleProductChange}
        addProduct={addProduct}
        removeProduct={removeProduct}
        categoriesData={categoriesData}
        productsData={productsData}
      />

      <OrderSummary
        totalAmount={totalAmount}
        discount={discount}
        vat={vat}
        tax={tax}
        grandTotal={grandTotal}
        setDiscount={setDiscount}
        setVat={setVat}
        setTax={setTax}
      />

      <div style={{ marginTop: 16, display: 'flex', justifyContent: 'flex-end' }}>
        <FormActions clearForm={clearForm} handleOrderConfirm={handleOrderConfirm} />
      </div>

      <Dialog
        open={isPreviewOpen}
        onClose={handlePreviewClose}
        maxWidth="md"
        fullWidth
      >
        <div style={{ position: 'relative' }}>
          <Button
            onClick={handlePreviewClose}
            variant="text"
            color="secondary"
            style={{ position: 'absolute', top: 16, right: 16 }}
          >
            &times;
          </Button>
          <DialogContent>
            <OrderPreview
              selectedCustomer={selectedCustomer}
              products={products}
              totalAmount={totalAmount}
              discount={discount}
              vat={vat}
              tax={tax}
              grandTotal={grandTotal}
              onClose={handlePreviewClose}
              onConfirm={handleOrderConfirm} // Pass the confirm handler to OrderPreview
            />
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
}

export default OrderPlace;
