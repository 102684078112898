import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';

const ListStock = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'productName', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStock, setCurrentStock] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Updated stock data
    const stocks = [
        { id: 1, productName: 'Product A', categoryName: 'Category 1', stockIn: 150, stockOut: 50, damage: 10, forUse: 'Sales', purchasePrice: 100, salesPrice: 150 },
        { id: 2, productName: 'Product B', categoryName: 'Category 2', stockIn: 200, stockOut: 70, damage: 5, forUse: 'Sample', purchasePrice: 120, salesPrice: 180 },
        { id: 3, productName: 'Product C', categoryName: 'Category 1', stockIn: 300, stockOut: 100, damage: 20, forUse: 'Gift', purchasePrice: 130, salesPrice: 190 },
        { id: 4, productName: 'Product D', categoryName: 'Category 3', stockIn: 400, stockOut: 150, damage: 15, forUse: 'Sales', purchasePrice: 140, salesPrice: 200 },
        // Add more data here
    ];

    const filteredStocks = stocks.filter(stock =>
        stock.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.stockIn.toString().includes(searchQuery) ||
        stock.stockOut.toString().includes(searchQuery) ||
        stock.damage.toString().includes(searchQuery) ||
        stock.forUse.toLowerCase().includes(searchQuery) ||
        stock.purchasePrice.toString().includes(searchQuery) ||
        stock.salesPrice.toString().includes(searchQuery)
    );

    const totalPages = Math.ceil(filteredStocks.length / rowsPerPage);
    const paginatedStocks = filteredStocks.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedStocks = [...paginatedStocks].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, stock) => {
        setAnchorEl(event.currentTarget);
        setCurrentStock(stock);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentStock(null);
    };

    const handleView = () => {
        console.log(`Viewing stock with id: ${currentStock.id}`);
        handleMenuClose();
    };

    const handleEdit = () => {
        console.log(`Editing stock with id: ${currentStock.id}`);
        handleMenuClose();
    };

    const handleDelete = () => {
        console.log(`Deleting stock with id: ${currentStock.id}`);
        handleMenuClose();
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1);
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">Stock List</h2>
            </div>
            <div className="card-block">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('productName')}
                                >
                                    Product
                                    <span className={`ml-2 ${sortConfig.key === 'productName' ? (sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-500') : ''}`}>
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('categoryName')}
                                >
                                    Category Type
                                    <span className={`ml-2 ${sortConfig.key === 'categoryName' ? (sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-500') : ''}`}>
                                        {sortConfig.direction === 'asc' ? '▲' : '▼'}
                                    </span>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock In</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Stock Out</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Damaged</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Usage Type</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Purchase</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Selling</th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedStocks.map((stock) => (
                                <tr key={stock.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.productName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.categoryName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.stockIn}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.stockOut}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.damage}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.forUse}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.purchasePrice}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.salesPrice}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <IconButton onClick={(event) => handleMenuClick(event, stock)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MuiMenuItem onClick={handleView}>
                                                <Visibility className="mr-2" /> View
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleEdit}>
                                                <Edit className="mr-2" /> Edit
                                            </MuiMenuItem>
                                            <MuiMenuItem onClick={handleDelete}>
                                                <Delete className="mr-2" /> Delete
                                            </MuiMenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <div className="flex justify-end mt-4">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        color="primary"
                    />
                </div>
            </div>
        </div>
    );
};

export default ListStock;
