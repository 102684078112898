import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import AddEmployee from './pages/Employee/AddEmployee';
import EmployeeList from './pages/Employee/EmployeeList';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import Sidebar from './components/layout/Sidebar';
import AddRole from './pages/Employee/AddRole';
import AssignRole from './pages/Employee/AssignRole';
import EmployeeProfile from './pages/Employee/EmployeeProfile';
import AddDepartment from './pages/Employee/AddDepartment';
import AddDesignation from './pages/Employee/AddDesignation';
import AddPromoCategory from './pages/Employee/AddPromoCategory';
import LoginPage from './pages/Auth/Login';
import Dashboard from './pages/dashboard/Dashboard';
import UserList from './pages/user/UserList';
import AddNewUser from './pages/user/AddNewUser';
import CustomerTable from './pages/Customer/CustomerList';
import AddCustomer from './pages/Customer/AddCustomer';
import Categories from './pages/product/Categories';
import AddSupplier from './pages/Supplier/AddSupplier';
import ListSupplier from './pages/Supplier/ListSupplier';
import ViewSupplier from './pages/Supplier/ViewSupplier';
import AddProduct from './pages/product/AddProduct';
import ProductList from './pages/product/ProductList';
import DamageList from './pages/product/ListDamage';
import StockList from './pages/inventory/StockList';
import SalesList from './pages/Sales/SalesList';
import AddDoctor from './pages/Doctor/AddDoctor';
import DoctorAssign from './pages/Doctor/DoctorAssign';
import DoctorProfile from './pages/Doctor/DoctorProfile';
import DoctorList from './pages/Doctor/DoctorList';
import AddPrescription from './pages/Doctor/AddPrescription';
import PrescriptionList from './pages/Doctor/PrescriptionList';
import OrderPlace from './pages/Order/OrderPlace';
import OrderHistory from './pages/Order/OrderHistory';
import ViewEmployee from './pages/Employee/ViewEmployee';
import NewPurchase from './pages/Purchase/Purchase';
import ListPurchase from './pages/Purchase/PurchaseList';
import NotFound from './pages/NotFound';
import MeetingSchedule from './pages/Doctor/Meeting';
import LeaveSchedule from './pages/Doctor/Leave';
import AddMeetingSchedule from './pages/Doctor/AddMeetingSchedule';
import AddLeaveSchedule from './pages/Doctor/AddLeaveSchedule';
import DoctorGiftPlan from './pages/GiftPlan/DoctorGiftPlan';
import NewGiftPlan from './pages/GiftPlan/NewGiftPlan';
import MonthlyTourPlan from './pages/Todo/MonthlyTourPlan';
import AddTourPlan from './pages/Todo/AddTourPlan';
import HistoricalEmployeeLog from './pages/Employee/HistoricalEmployeeLog';
import OrderPreview from './pages/Order/OrderPreview';
import ProductTransactionList from './pages/Transaction/ProductTransactionList';
import GenerateSales from './pages/Sales/GenerateSales';
import UserPermissions from './pages/Settings/UserPermissions';
// ... other imports ...

const AuthRoute = ({ element }) => {
  const token = Cookies.get('authToken');
  const isAuthenticated = !!token;
  return isAuthenticated ? element : <Navigate to="/" />;
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const token = Cookies.get('authToken');
    if (token) {
      // Fetch user details from your API here
      setUser({
        avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png',
        name: 'John Doe',
        role: 'Role',
        roleTitle: 'Super Admin',
        designation: 'Software Engineer',
        address: 'Dhaka, Bangladesh',
      });
    } else {
      setUser(null);
    }
  }, []);

  const handleLogin = (token) => {
    Cookies.set('authToken', token, { expires: 1 });
    // Fetch user details after login
    setUser({
      avatar: 'https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png',
      name: 'John Doe',
      role: 'Role',
      roleTitle: 'Super Admin',
      designation: 'Software Engineer',
      address: 'Dhaka, Bangladesh',
    });
    navigate('/dashboard');
  };

  const handleLogout = () => {
    Cookies.remove('authToken');
    setUser(null);
    navigate('/');
  };

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <div className="h-screen flex flex-col">
      {user ? (
        <>
          <Header
            onLogout={handleLogout}
            onToggleSidebar={toggleSidebar}
            sidebarVisible={sidebarVisible}
            user={user}
          />
          <div className="flex flex-grow">
            {user && (
              <aside
              className={`fixed top-0 left-0 h-full bg-gray-800 text-white p-4 transition-transform duration-300 ${sidebarVisible ? 'translate-x-0' : '-translate-x-64'} mt-12`}
              style={{ width: '16rem' }}
              >
                <Sidebar />
              </aside>
            )}
            <div
              className={`flex-grow transition-all duration-300 ${sidebarVisible ? 'ml-64' : 'ml-0'} mt-12`}
            >
              <main className="p-4 overflow-y-auto bg-gray-50" style={{ height: 'calc(100vh - 64px)' }}>
                <Routes>
                  <Route path="/" element={<AuthRoute element={<Dashboard />} />} />
                  <Route path="/login" element={<LoginPage onLogin={handleLogin} />} />
                  {/* <Route path="/togin" element={<AuthRoute element={<Togin />} />} /> */}
                  <Route path="/dashboard" element={<AuthRoute element={<Dashboard />} />} />
                  <Route path="/add-employee" element={<AuthRoute element={<AddEmployee />} />} />
                  <Route path="/list-employee" element={<AuthRoute element={<EmployeeList />} />} />
                  <Route path="/role-employee" element={<AuthRoute element={<AddRole />} />} />
                  <Route path="/assign-role" element={<AuthRoute element={<AssignRole />} />} />
                  <Route path="/profile/:id" element={<AuthRoute element={<EmployeeProfile />} />} />
                  <Route path="/add-department" element={<AuthRoute element={<AddDepartment />} />} />
                  <Route path="/add-designation" element={<AuthRoute element={<AddDesignation />} />} />
                  <Route path="/add-promo-category" element={<AuthRoute element={<AddPromoCategory />} />} />
                  <Route path="/add-customer" element={<AuthRoute element={<AddCustomer />} />} />
                  <Route path="/list-customer" element={<AuthRoute element={<CustomerTable />} />} />
                  <Route path="/product-categories" element={<AuthRoute element={<Categories />} />} />
                  <Route path="/add-supplier" element={<AuthRoute element={<AddSupplier />} />} />
                  <Route path="/list-supplier" element={<AuthRoute element={<ListSupplier />} />} />
                  <Route path="/view-supplier" element={<AuthRoute element={<ViewSupplier />} />} />
                  <Route path="/add-product" element={<AuthRoute element={<AddProduct />} />} />
                  <Route path="/list-product" element={<AuthRoute element={<ProductList />} />} />
                  <Route path="/list-damage" element={<AuthRoute element={<DamageList />} />} />
                  <Route path="/new-purchase" element={<AuthRoute element={<NewPurchase />} />} />
                  <Route path="/purchase-history" element={<AuthRoute element={<ListPurchase />} />} />
                  <Route path="/transaction-history" element={<AuthRoute element={<ProductTransactionList />} />} />
                  <Route path="/stock-list" element={<AuthRoute element={<StockList />} />} />
                  <Route path="/list-sales" element={<AuthRoute element={<SalesList />} />} />
                  <Route path="/add-doctor" element={<AuthRoute element={<AddDoctor />} />} />
                  <Route path="/assign-doctor" element={<AuthRoute element={<DoctorAssign />} />} />
                  <Route path="/view-doctor" element={<AuthRoute element={<DoctorProfile />} />} />
                  <Route path="/list-doctor" element={<AuthRoute element={<DoctorList />} />} />
                  <Route path="/gift-planning" element={<AuthRoute element={<DoctorGiftPlan />} />} />
                  <Route path="/new-planning" element={<AuthRoute element={<NewGiftPlan />} />} />
                  <Route path="/meeting" element={<AuthRoute element={<MeetingSchedule />} />} />
                  <Route path="/leave" element={<AuthRoute element={<LeaveSchedule />} />} />
                  <Route path="/add-meeting" element={<AuthRoute element={<AddMeetingSchedule />} />} />
                  <Route path="/add-leave" element={<AuthRoute element={<AddLeaveSchedule />} />} />
                  <Route path="/add-prescription" element={<AuthRoute element={<AddPrescription />} />} />
                  <Route path="/list-prescription" element={<AuthRoute element={<PrescriptionList />} />} />
                  <Route path="/new-order" element={<AuthRoute element={<OrderPlace />} />} />
                  <Route path="/manage-order" element={<AuthRoute element={<OrderHistory />} />} />
                  <Route path="/order-preview" element={<AuthRoute element={<OrderPreview />} />} />
                  <Route path="/view-employee" element={<AuthRoute element={<ViewEmployee />} />} />
                  <Route path="/plan-list" element={<AuthRoute element={<MonthlyTourPlan />} />} />
                  <Route path="/new-plan" element={<AuthRoute element={<AddTourPlan />} />} />
                  <Route path="/employee-log" element={<AuthRoute element={<HistoricalEmployeeLog />} />} />
                  <Route path="/generate-sales/:orderId" element={<AuthRoute element={<GenerateSales />} />} />
                  <Route path="/user-permission" element={<AuthRoute element={<UserPermissions />} />} />
                  {/* Catch-all route for 404 */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </main>
              <Footer />
            </div>
          </div>
        </>
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </div>
  );
};

export default AppRoutes;
