import React from 'react'

function Footer() {
  return (
    <footer className="bg-white text-gray-800 p-4  items-center justify-between">
      <p className='text-center'>&copy; 2024 Zuventure Corporation. All rights reserved.</p>
    </footer>
  )
}

export default Footer