import React from 'react'
import EditBtn from '../../components/common/EditBtn';

function RoleList() {
    const roles = [
        { id: 1, name: 'Admin', description: 'Full access to all features', count: 5 },
        { id: 2, name: 'Editor', description: 'Can edit content', count: 8 },
        { id: 3, name: 'Viewer', description: 'Can view content', count: 15 },
        // Add more roles as needed
      ];
      
  return (
    <>
      <h2 className="text-xl font-bold mb-4">Role List</h2>
      <table className="min-w-full bg-white">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Name</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Description</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Count</th>
            <th className="py-2 px-4 border-b-2 border-gray-300 text-left">Action</th>
          </tr>
        </thead>
        <tbody>
          {roles.map((role) => (
            <tr key={role.id} className="hover:bg-gray-100">
              <td className="py-2 px-4 border-b border-gray-300">{role.name}</td>
              <td className="py-2 px-4 border-b border-gray-300">{role.description}</td>
              <td className="py-2 px-4 border-b border-gray-300">{role.count}</td>
              <td className="py-2 px-4 border-b border-gray-300">
                <EditBtn Name="Edit" Path="/assign-role" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default RoleList