import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';


const CustomerList = () => {
  const [sortConfig, setSortConfig] = useState({ key: 'customerName', direction: 'asc' });
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  
  
  const Customers = [
    { id: 1, customerName: 'Saidur', customerType: 'Internal', phone: '123-456-7890', email: 'saidur@example.com', city: 'New York' },
    { id: 2, customerName: 'Alice Johnson', customerType: 'External', phone: '234-567-8901', email: 'alice.johnson@example.com', city: 'Los Angeles' },
    { id: 3, customerName: 'Bob Smith', customerType: 'Internal', phone: '345-678-9012', email: 'bob.smith@example.com', city: 'Chicago' },
    { id: 4, customerName: 'Carol White', customerType: 'External', phone: '456-789-0123', email: 'carol.white@example.com', city: 'Houston' },
    { id: 5, customerName: 'David Lee', customerType: 'Internal', phone: '567-890-1234', email: 'david.lee@example.com', city: 'Phoenix' },
    { id: 6, customerName: 'Eve Brown', customerType: 'External', phone: '678-901-2345', email: 'eve.brown@example.com', city: 'Philadelphia' },
    { id: 7, customerName: 'Frank Green', customerType: 'Internal', phone: '789-012-3456', email: 'frank.green@example.com', city: 'San Antonio' },
    { id: 8, customerName: 'Grace Hall', customerType: 'External', phone: '890-123-4567', email: 'grace.hall@example.com', city: 'San Diego' },
    { id: 9, customerName: 'Hank Miller', customerType: 'Internal', phone: '901-234-5678', email: 'hank.miller@example.com', city: 'Dallas' },
    { id: 10, customerName: 'Ivy King', customerType: 'External', phone: '012-345-6789', email: 'ivy.king@example.com', city: 'San Jose' },
    { id: 11, customerName: 'Jack Wilson', customerType: 'Internal', phone: '123-456-7891', email: 'jack.wilson@example.com', city: 'Austin' },
    { id: 12, customerName: 'Kate Martin', customerType: 'External', phone: '234-567-8902', email: 'kate.martin@example.com', city: 'Jacksonville' },
    { id: 13, customerName: 'Leo Perez', customerType: 'Internal', phone: '345-678-9013', email: 'leo.perez@example.com', city: 'Fort Worth' },
    { id: 14, customerName: 'Mia Davis', customerType: 'External', phone: '456-789-0124', email: 'mia.davis@example.com', city: 'Columbus' },
    { id: 15, customerName: 'Nina Moore', customerType: 'Internal', phone: '567-890-1235', email: 'nina.moore@example.com', city: 'Charlotte' },
    // Add more demo data here
];

const filteredCustomers = Customers.filter(customer =>
  customer.customerType.toLowerCase().includes(searchQuery.toLowerCase()) ||
  customer.customerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
  customer.phone.toLowerCase().includes(searchQuery.toLowerCase()) ||
  customer.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
  customer.city.toLowerCase().includes(searchQuery.toLowerCase())
);

const totalPages = Math.ceil(filteredCustomers.length / rowsPerPage);
    const paginatedCustomers = filteredCustomers.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedCustomers = [...paginatedCustomers].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
      let direction = 'asc';
      if (sortConfig.key === key && sortConfig.direction === 'asc') {
          direction = 'desc';
      }
      setSortConfig({ key, direction });
  };

  const handleMenuClick = (event, customer) => {
      setAnchorEl(event.currentTarget);
      setCurrentCustomer(customer);
  };

  const handleMenuClose = () => {
      setAnchorEl(null);
      setCurrentCustomer(null);
  };

  const handleView = () => {
      console.log(`Viewing customer with id: ${currentCustomer.id}`);
      handleMenuClose();
      // Add your view logic here
  };

  const handleEdit = () => {
      console.log(`Editing customer with id: ${currentCustomer.id}`);
      handleMenuClose();
      // Add your edit logic here
  };

  const handleDelete = () => {
      console.log(`Deleting customer with id: ${currentCustomer.id}`);
      handleMenuClose();
      // Add your delete logic here
  };

  const handlePageChange = (event, value) => {
      setPage(value);
  };

  const handleSearchChange = (event) => {
      setSearchQuery(event.target.value);
  };

  const handleRowsPerPageChange = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(1); // Reset to first page when rows per page changes
  };


  return (
    <div className="mt-5">
        <div className="flex justify-between items-center mb-4">
            {/* Title Area */}
            <h2 className="text-2xl font-semibold text-gray-800">Customer List</h2>
            
            {/* Action Buttons */}
            <div className="flex space-x-4">
                {/* Button for Adding New Customer */}
                <Link to="/add-Customer">
                    <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                        Add Customer
                    </button>
                </Link>
            </div>
        </div>
        <div className="card-block">
            {/* Header */}
            <div className="flex justify-between items-center mb-4">
                <div className="text-sm text-gray-500">
                    <span>Showing </span>
                    <Select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        className="w-15"
                        size="small"
                    >
                        <MuiMenuItem value={5}>5</MuiMenuItem>
                        <MuiMenuItem value={10}>10</MuiMenuItem>
                        <MuiMenuItem value={25}>25</MuiMenuItem>
                        <MuiMenuItem value={50}>50</MuiMenuItem>
                    </Select>
                    <span> entries</span>
                </div>
                <div className="flex items-center space-x-4">
                    <TextField
                        label="Search"
                        variant="outlined"
                        size="small"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="w-3/3"
                    />
                </div>
            </div>

   {/* Table */}
   <div className="overflow-x-auto">
   <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
       <thead className="bg-gray-100">
           <tr>
               <th
                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                   onClick={() => handleSort('customerName')}
               >
                   Customer Name
                   <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'customerName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9650; {/* Unicode for up arrow */}
                       </span>
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'customerName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9660; {/* Unicode for down arrow */}
                       </span>
                   </div>
               </th>
               <th
                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                   onClick={() => handleSort('customerType')}
               >
                   Customer type
                   <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'customerType' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9650; {/* Unicode for up arrow */}
                       </span>
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'customerType' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9660; {/* Unicode for down arrow */}
                       </span>
                   </div>
               </th>
               <th
                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                   onClick={() => handleSort('phone')}
               >
                   Phone
                   <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'phone' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9650; {/* Unicode for up arrow */}
                       </span>
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'phone' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9660; {/* Unicode for down arrow */}
                       </span>
                   </div>
               </th>
               <th
                   className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                   onClick={() => handleSort('email')}
               >
                   Email
                   <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'email' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9650; {/* Unicode for up arrow */}
                       </span>
                       <span
                           className={`text-gray-500 ${sortConfig.key === 'email' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                       >
                           &#9660; {/* Unicode for down arrow */}
                       </span>
                   </div>
               </th>
               
               <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                   Actions
               </th>
           </tr>
       </thead>
       <tbody className="bg-white divide-y divide-gray-200">
           {sortedCustomers.map((customer) => (
               <tr key={customer.id}>
                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{customer.customerName}</td>
                   <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{customer.customerType}</td>
                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{customer.phone}</td>
                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{customer.email}</td>
                   <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                       <IconButton onClick={(event) => handleMenuClick(event, customer)}>
                           <MoreVertIcon />
                       </IconButton>
                       <Menu
                           anchorEl={anchorEl}
                           keepMounted
                           open={Boolean(anchorEl)}
                           onClose={handleMenuClose}
                       >
                           <MuiMenuItem onClick={handleView}>
                               <Visibility /> View
                           </MuiMenuItem>
                           <MuiMenuItem onClick={handleEdit}>
                               <Edit /> Edit
                           </MuiMenuItem>
                           <MuiMenuItem onClick={handleDelete}>
                               <Delete /> Delete
                           </MuiMenuItem>
                       </Menu>
                   </td>
               </tr>
           ))}
       </tbody>
   </table>
</div>


  <div className="mt-4 flex justify-end">
  <Pagination
      count={totalPages}
      page={page}
      onChange={handlePageChange}
      shape="rounded"
      variant="outlined"
  />
</div>
</div>
</div>
);
};

export default CustomerList;