import React from 'react'
const plans = [
    { name: 'Start', speed: '5 Mb/s', storage: '15 GB', price: 'Free' },
    { name: 'Pro', speed: '25 Mb/s', storage: '25 GB', price: '$24' },
    { name: 'Business', speed: '36 Mb/s', storage: '40 GB', price: '$50' },
    { name: 'Exclusive', speed: '48 Mb/s', storage: '120 GB', price: '$72' },
  ];


function AssignRole() {
  return (
    <div className="assign-role p-4">
      <h2 className='text-xl font-bold mb-4'>Assign Employee Role</h2>
      <table className="table-auto w-full text-left whitespace-no-wrap">
        <thead>
            <tr>
            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tl rounded-bl">
                Plan
            </th>
            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Speed
            </th>
            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Storage
            </th>
            <th className="px-4 py-3 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100">
                Price
            </th>
            <th className="w-10 title-font tracking-wider font-medium text-gray-900 text-sm bg-gray-100 rounded-tr rounded-br"></th>
            </tr>
        </thead>
        <tbody>
            {plans.map((plan, index) => (
            <tr key={index}>
                <td className={`px-4 py-3 ${index !== 0 && 'border-t-2 border-gray-200'}`}>{plan.name}</td>
                <td className={`px-4 py-3 ${index !== 0 && 'border-t-2 border-gray-200'}`}>{plan.speed}</td>
                <td className={`px-4 py-3 ${index !== 0 && 'border-t-2 border-gray-200'}`}>{plan.storage}</td>
                <td className={`px-4 py-3 text-lg text-gray-900 ${index !== 0 && 'border-t-2 border-gray-200'}`}>{plan.price}</td>
                <td className={`w-10 text-center ${index !== 0 && 'border-t-2 border-gray-200'} ${index === plans.length - 1 && 'border-b-2'}`}>
                <input name="plan" type="radio" />
                </td>
            </tr>
            ))}
        </tbody>
        </table>
    </div>
  )
}

export default AssignRole