import React, { useState } from 'react';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination } from '@mui/material';
import { Link } from 'react-router-dom';

const DamageList = () => {
    const [sortConfig, setSortConfig] = useState({ key: 'damageDate', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStock, setCurrentStock] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    // Example stock data (adjust with your actual data structure)
    const stocks = [
        { id: 1, productName: 'Product A', categoryName: 'Category 1', stockInQty: '100', stockOutQty: '50', damagedQty: '10', usageType: 'Sales', purchasePrice: '500', salesPrice: '600', status: 'Damaged', damageDate: '01-02-2023' },
        { id: 2, productName: 'Product B', categoryName: 'Category 2', stockInQty: '200', stockOutQty: '150', damagedQty: '5', usageType: 'Gift', purchasePrice: '700', salesPrice: '900', status: 'Damaged', damageDate: '05-03-2023' },
        { id: 3, productName: 'Product C', categoryName: 'Category 1', stockInQty: '50', stockOutQty: '25', damagedQty: '8', usageType: 'Sample', purchasePrice: '400', salesPrice: '550', status: 'Damaged', damageDate: '10-04-2023' },
        // Add more data if needed
    ];

    const filteredStocks = stocks.filter(stock =>
        stock.productName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.categoryName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.usageType.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.purchasePrice.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.salesPrice.toLowerCase().includes(searchQuery.toLowerCase()) ||
        stock.damagedQty.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(filteredStocks.length / rowsPerPage);
    const paginatedStocks = filteredStocks.slice((page - 1) * rowsPerPage, page * rowsPerPage);

    const sortedStocks = [...paginatedStocks].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, stock) => {
        setAnchorEl(event.currentTarget);
        setCurrentStock(stock);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentStock(null);
    };

    const handleView = () => {
        console.log(`Viewing stock with id: ${currentStock.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleEdit = () => {
        console.log(`Editing stock with id: ${currentStock.id}`);
        handleMenuClose();
        // Add your edit logic here
    };

    const handleDelete = () => {
        console.log(`Deleting stock with id: ${currentStock.id}`);
        handleMenuClose();
        // Add your delete logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">Damage List</h2>
            </div>
            <div className="card-block">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                {/* Table */}
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('productName')}
                                >
                                    Product
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('categoryName')}
                                >
                                    Category Type
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('stockInQty')}
                                >
                                    Stock In Quantity
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('stockOutQty')}
                                >
                                    Stock Out Quantity
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('damagedQty')}
                                >
                                    Damaged Quantity
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('usageType')}
                                >
                                    Usage Type (Sales/Sample/Gift)
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('purchasePrice')}
                                >
                                    Purchase Price
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('salesPrice')}
                                >
                                    Selling Price
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('status')}
                                >
                                    Status
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                        {sortedStocks.map((stock) => (
                                <tr key={stock.id}>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.productName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.categoryName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.stockInQty}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.stockOutQty}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.damagedQty}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.usageType}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.purchasePrice}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.salesPrice}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">{stock.status}</td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <IconButton onClick={(event) => handleMenuClick(event, stock)}>
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && currentStock && currentStock.id === stock.id}
                                                onClose={handleMenuClose}
                                            >
                                                <MuiMenuItem onClick={handleView}>
                                                    <Visibility className="mr-2" /> View
                                                </MuiMenuItem>
                                                <MuiMenuItem onClick={handleEdit}>
                                                    <Edit className="mr-2" /> Edit
                                                </MuiMenuItem>
                                                <MuiMenuItem onClick={handleDelete}>
                                                    <Delete className="mr-2" /> Delete
                                                </MuiMenuItem>
                                            </Menu>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="flex justify-between items-center mt-4">
                    <div className="text-sm text-gray-500">
                        Showing {Math.min((page - 1) * rowsPerPage + 1, filteredStocks.length)} to{' '}
                        {Math.min(page * rowsPerPage, filteredStocks.length)} of {filteredStocks.length} entries
                    </div>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} />
                </div>
            </div>
        </div>
    );
};

export default DamageList;

