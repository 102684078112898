// data.js

// Define categories with nested subcategories
export const categories = [
    { 
      id: 1, 
      name: 'Medicine', 
      count: 0 
    },
    { 
      id: 2, 
      name: 'Supplier', 
      count: 1, 
      subcategories: [
        { 
          id: 3, 
          name: 'Sub Category', 
          count: 1, 
          subcategories: [
            { 
              id: 4, 
              name: 'Brand', 
              count: 1, 
              subcategories: [
                { 
                  id: 5, 
                  name: 'Sub Brand', 
                  count: 0 
                }
              ]
            }
          ]
        }
      ]
    },
    { 
      id: 6, 
      name: 'Product Category', 
      count: 0, 
      subcategories: [
        { 
          id: 7, 
          name: 'Product Type', 
          count: 8 
        }
      ]
    }
    // Add more categories as needed
  ];
  