// src/components/Modal/ProfileCard.js
import React from 'react';

function ProfileCard({ isVisible, onClose, user }) {
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-black text-gray-800 bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-6xl relative">
        <button
          className="absolute top-4 right-4 text-gray-600 hover:text-gray-900 text-2xl"
          onClick={onClose}
        >
          &times;
        </button>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Profile Card */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-md flex flex-col items-center">
            <img
              src={user.avatar || 'pro.jpg'}
              alt="Profile Picture"
              className="w-32 h-32 rounded-full border border-gray-300 mb-4"
            />
            <h4 className="text-lg font-bold border-b border-gray-300 pb-2 mb-4">About User</h4>
            <h3 className="text-2xl font-extrabold mb-2">Name: {user.name || 'Developer'}</h3>
            <p className="text-base text-gray-800 mb-1">Designation: {user.designation || 'Sr. Product Manager'}</p>
            <p className="text-base text-gray-800 mb-4">Role: {user.role || 'superadmin'}</p>
            <div className="w-full text-center">
              <p className="text-base text-green-600 font-semibold mb-1">Status: {user.status || 'Active'}</p>
              <p className="text-base text-gray-600">Joining Date: {user.joiningDate || 'N/A'}</p>
            </div>
          </div>
          
          {/* Details Card */}
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-lg font-bold border-b border-gray-300 pb-2 mb-4">User Details</h3>
            <dl className="space-y-4">
              {[
                { label: 'Employee ID', value: user.employeeId || 'N/A' },
                { label: 'Company Name', value: user.companyName || 'Nexdecade Technology' },
                { label: 'Gender', value: user.gender || 'Male' },
                { label: 'Joining Date', value: user.joiningDate || 'N/A' },
                { label: 'Whatsapp Number', value: user.whatsappNumber || 'N/A' },
                { label: 'Email', value: user.email || 'developer@nexdecade.com' },
                { label: 'User Type', value: user.userType || 'N/A' },
                { label: 'Current Address', value: user.currentAddress || 'N/A' },
                { label: 'Permanent Address', value: user.permanentAddress || 'N/A' },
              ].map(({ label, value }) => (
                <div key={label} className="flex justify-between">
                  <dt className="font-medium text-gray-800">{label}:</dt>
                  <dd className="text-gray-700">{value}</dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;
