import React from 'react';

// Helper function to format amount as BDT
const formatBDT = (amount) => {
  return new Intl.NumberFormat('en-BD', { style: 'currency', currency: 'BDT' }).format(amount);
};

function SearchSummary({ totalBill, totalReceived, totalSettlement, totalDue }) {
  return (
    <div className="p-6 bg-white shadow-lg rounded-lg border border-gray-200">
      <h2 className="text-2xl font-semibold mb-6 text-gray-800">Search Summary</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-blue-50 p-4 rounded-lg shadow-md flex items-center space-x-4">
          <div className="text-blue-500 text-3xl">৳</div>
          <div>
            <span className="text-gray-600 text-sm">Total Bill</span>
            <div className="text-lg font-semibold text-blue-800">
              {formatBDT(totalBill)}
            </div>
          </div>
        </div>
        <div className="bg-green-50 p-4 rounded-lg shadow-md flex items-center space-x-4">
          <div className="text-green-500 text-3xl">৳</div>
          <div>
            <span className="text-gray-600 text-sm">Total Received</span>
            <div className="text-lg font-semibold text-green-800">
              {formatBDT(totalReceived)}
            </div>
          </div>
        </div>
        <div className="bg-yellow-50 p-4 rounded-lg shadow-md flex items-center space-x-4">
          <div className="text-yellow-500 text-3xl">৳</div>
          <div>
            <span className="text-gray-600 text-sm">Total Settlement</span>
            <div className="text-lg font-semibold text-yellow-800">
              {formatBDT(totalSettlement)}
            </div>
          </div>
        </div>
        <div className="bg-red-50 p-4 rounded-lg shadow-md flex items-center space-x-4">
          <div className="text-red-500 text-3xl">৳</div>
          <div>
            <span className="text-gray-600 text-sm">Total Due</span>
            <div className="text-lg font-semibold text-red-800">
              {formatBDT(totalDue)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchSummary;
