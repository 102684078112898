import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Checkbox, FormControlLabel, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SearchIcon from '@mui/icons-material/Search';
import PrintIcon from '@mui/icons-material/Print';
import SelectAllIcon from '@mui/icons-material/SelectAll';

const PermissionsSection = ({ PageTitle }) => {
  // State to manage Dashboard permissions and its subsections
  const [permissions, setPermissions] = useState({
    Dashboard: {
      expanded: false,
      subSections: {
        FOCClientList: { Add: false, Update: false, Delete: false, View: false, Search: false, Print: false, All: false },
        MismatchRecurringClient: { Add: false, Update: false, Delete: false, View: false, Search: false, Print: false, All: false },
        AvailRecurringInvoice: { Add: false, Update: false, Delete: false, View: false, Search: false, Print: false, All: false },
        NoRecurringClient: { Add: false, Update: false, Delete: false, View: false, Search: false, Print: false, All: false },
        TodayRevise: { Add: false, Update: false, Delete: false, View: false, Search: false, Print: false, All: false },
      },
    },
  });

  const toggleDashboardExpand = () => {
    setPermissions((prev) => ({
      ...prev,
      Dashboard: {
        ...prev.Dashboard,
        expanded: !prev.Dashboard.expanded,
      },
    }));
  };

  const handleCheckboxChange = (subSection, action) => {
    setPermissions((prev) => ({
      ...prev,
      Dashboard: {
        ...prev.Dashboard,
        subSections: {
          ...prev.Dashboard.subSections,
          [subSection]: {
            ...prev.Dashboard.subSections[subSection],
            [action]: !prev.Dashboard.subSections[subSection][action],
          },
        },
      },
    }));
  };

  return (
    <>
      {/* Permissions Table */}
      <Table className="w-full mb-1">
        {/* Section Title */}
        <TableHead>
          <TableRow className="bg-gray-600 text-white">
            <TableCell colSpan={9} className="w-full">
              <div className="flex items-center justify-between w-full">
                <Typography variant="h6" className="flex-grow text-white font-semibold">
                  {PageTitle}
                </Typography>
                <IconButton onClick={toggleDashboardExpand}>
                  {permissions.Dashboard.expanded ? (
                    <ExpandLessIcon className="text-white" />
                  ) : (
                    <ExpandMoreIcon className="text-white" />
                  )}
                </IconButton>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>

        {/* Permission Headers */}
        <Collapse in={permissions.Dashboard.expanded} timeout="auto" unmountOnExit>
          <TableHead>
            <TableRow className="bg-gray-200">
              <TableCell className="text-center font-bold w-10">SL</TableCell>
              <TableCell className="text-left font-bold w-4/6">Options</TableCell> {/* Page Name takes more width */}
              <TableCell className="text-center font-bold w-1/12">
                <AddIcon fontSize="small" className="text-green-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <EditIcon fontSize="small" className="text-blue-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <DeleteIcon fontSize="small" className="text-red-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <VisibilityIcon fontSize="small" className="text-purple-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <SearchIcon fontSize="small" className="text-gray-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <PrintIcon fontSize="small" className="text-orange-600" />
              </TableCell>
              <TableCell className="text-center font-bold w-1/12">
                <SelectAllIcon fontSize="small" className="text-teal-600" />
              </TableCell>
            </TableRow>
          </TableHead>

          {/* Subsections of Dashboard */}
          <TableBody>
            {Object.keys(permissions.Dashboard.subSections).map((subSection, index) => (
              <TableRow key={index} className="hover:bg-gray-100"> {/* Apply row hover effect */}
                <TableCell className="text-center">{index + 1}</TableCell>
                <TableCell className="text-left">
                  {subSection.replace(/([A-Z])/g, ' $1').trim()}
                </TableCell> {/* Make Page Name take more space */}
                {Object.keys(permissions.Dashboard.subSections[subSection]).map((action) => (
                  <TableCell key={action} className="text-center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={permissions.Dashboard.subSections[subSection][action]}
                          onChange={() => handleCheckboxChange(subSection, action)}
                          className="p-1"
                        />
                      }
                      label=""
                      className="m-0"
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Collapse>
      </Table>
    </>
  );
};

export default PermissionsSection;
