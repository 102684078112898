import React, { useState } from 'react'
import Button from '../../components/common/Button';
import PromoCategoryList from './PromoCategoryList';

function AddPromoCategory() {
    const initialPromoCategoryState = {
        categoryName : "",
        description : "",
    };
    const [promoCategory, setPromoCategory] = useState(initialPromoCategoryState);


    const handleChange = (e) => {
        const {name, value} = e.target;
        setPromoCategory({
            ...promoCategory,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Logic to add promoCategory
        console.log('promoCategory added:', promoCategory)
        // Clear the form
        setPromoCategory(initialPromoCategoryState);
    }

  return (
    <div className='add-promoCategory flex'>
        <div className="w-full lg:w-1/3 p-4">
            <h2 className="text-xl font-bold mb-4">Add New Category</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="grid grid-cols-1 gap-6">
                    <div>
                    <label className="block text-gray-700">Category Name</label>
                    <input
                        type="text"
                        name="categoryName"
                        value={promoCategory.categoryName}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter promoCategory name"
                        required
                    />
                    </div>
                    <div>
                    <label className="block text-gray-700">Description</label>
                    <textarea
                        name="description"
                        value={promoCategory.description}
                        onChange={handleChange}
                        className="w-full px-4 py-2 border rounded-lg"
                        placeholder="Enter description"
                    />
                    </div>
                </div>
                <div className="mt-4">
                    <Button Name="Add New Category" />
                </div>
            </form>
        </div>
        <div className="w-full lg:w-2/3 p-4">
            <PromoCategoryList /> 
        </div>
    </div>
  )
}

export default AddPromoCategory