import React, { useState, useEffect } from 'react';
import { getProducts } from '../../services/api'; // Import your API function
import { IconButton, Menu, MenuItem as MuiMenuItem, TextField, Select, Pagination, MenuItem } from '@mui/material';
import { Visibility, Edit, Delete, MoreVert as MoreVertIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const ProductList = () => {
    const [products, setProducts] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'title', direction: 'asc' });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [totalProducts, setTotalProducts] = useState(0);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const { products, totalProducts } = await getProducts(page, rowsPerPage);
                setProducts(products);
                setTotalProducts(totalProducts);
                console.log('Fetched Products:', products); // Debug: Log fetched products
                console.log('Total Products:', totalProducts); // Debug: Log total products
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };

        fetchProducts();
    }, [page, rowsPerPage]);

    const filteredProducts = products.filter(product =>
        product.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.stock.toString().includes(searchQuery.toLowerCase()) ||
        product.price.toString().includes(searchQuery.toLowerCase()) ||
        product.availabilityStatus.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const totalPages = Math.ceil(totalProducts / rowsPerPage);

    const sortedProducts = [...filteredProducts].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction });
    };

    const handleMenuClick = (event, product) => {
        setAnchorEl(event.currentTarget);
        setCurrentProduct(product);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentProduct(null);
    };

    const handleView = () => {
        console.log(`Viewing product with id: ${currentProduct.id}`);
        handleMenuClose();
        // Add your view logic here
    };

    const handleEdit = () => {
        console.log(`Editing product with id: ${currentProduct.id}`);
        handleMenuClose();
        // Add your edit logic here
    };

    const handleDelete = () => {
        console.log(`Deleting product with id: ${currentProduct.id}`);
        handleMenuClose();
        // Add your delete logic here
    };

    const handlePageChange = (event, value) => {
        setPage(value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(1); // Reset to first page when rows per page changes
    };

    return (
        <div className="mt-5">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold text-gray-800">Product List</h2>
                <div className="flex space-x-4">
                    <Link to="/add-product">
                        <button className="px-4 py-2 bg-customGreen text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400">
                            Add Product
                        </button>
                    </Link>
                </div>
            </div>
            <div className="card-block">
                <div className="flex justify-between items-center mb-4">
                    <div className="text-sm text-gray-500">
                        <span>Showing </span>
                        <Select
                            value={rowsPerPage}
                            onChange={handleRowsPerPageChange}
                            className="w-15"
                            size="small"
                        >
                            <MuiMenuItem value={5}>5</MuiMenuItem>
                            <MuiMenuItem value={10}>10</MuiMenuItem>
                            <MuiMenuItem value={25}>25</MuiMenuItem>
                            <MuiMenuItem value={50}>50</MuiMenuItem>
                        </Select>
                        <span> entries</span>
                    </div>
                    <div className="flex items-center space-x-4">
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            className="w-3/3"
                        />
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200 border border-gray-300">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('productName')}
                                >
                                    Product Name
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'productName' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'productName' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('category')}
                                >
                                    Category
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'category' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'category' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('groupType')}
                                >
                                    Group Type
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'groupType' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'groupType' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('stock')}
                                >
                                    Stock
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'stock' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'stock' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('price')}
                                >
                                    Price
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'price' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'price' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th
                                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer relative"
                                    onClick={() => handleSort('status')}
                                >
                                    Status
                                    <div className="absolute inset-y-0 right-0 flex items-center space-x-1 pr-3">
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'asc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9650;
                                        </span>
                                        <span
                                            className={`text-gray-500 ${sortConfig.key === 'status' && sortConfig.direction === 'desc' ? 'text-black' : 'text-gray-300'}`}
                                        >
                                            &#9660;
                                        </span>
                                    </div>
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {sortedProducts.map(product => (
                                <tr key={product.id}>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {product.id}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                                        {product.title}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.category}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.group || 'N/A'} {/* Adjust if 'group' field is available */}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.stock}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        ${product.price}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {product.availabilityStatus || 'N/A'} {/* Adjust if 'availabilityStatus' field is available */}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                                        <IconButton onClick={(event) => handleMenuClick(event, product)}>
                                            <MoreVertIcon />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={handleMenuClose}
                                        >
                                            <MenuItem onClick={handleView}>View</MenuItem>
                                            <MenuItem onClick={handleEdit}>Edit</MenuItem>
                                            <MenuItem onClick={handleDelete}>Delete</MenuItem>
                                        </Menu>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                {/* Pagination */}
                <div className="mt-4 flex justify-end">
                    <Pagination
                        count={totalPages}
                        page={page}
                        onChange={handlePageChange}
                        shape="rounded"
                        variant="outlined"
                    />
                </div>
            </div>
        </div>
    );
};

export default ProductList;
